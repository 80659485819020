import { FC, PropsWithChildren, CSSProperties } from 'react';
import styles from './ShadowBox.module.scss';

interface ShadowBoxProps {
  padding?: string;
  minHeight?: string;
  minWidth?: string;
  styles?: CSSProperties;
}

export const ShadowBox: FC<PropsWithChildren<ShadowBoxProps>> = ({
  children,
  padding,
  minHeight,
  minWidth,
  styles: customStyles
}) => {
  const boxStyle: CSSProperties = {
    padding: padding || '30px 50px',
    minHeight: minHeight || '500px',
    minWidth: minWidth || '500px',
    ...customStyles
  };

  return (
    <div className={styles.shadowBox} style={boxStyle}>
      {children}
    </div>
  );
};
