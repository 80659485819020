import { PhoneLabelInputForm } from '../../../design/components/LabelInput/PhoneLabelInput';
import { PrimaryButton } from '../../../design/components/buttons/PrimaryButton/PrimaryButton';
import { TertiaryButton } from '../../../design/components/buttons/TertiaryButtom/TertiaryButton';
import { HeartLinkLogo } from '../../../design/components/heartLinkLogo/HeartLinkLogo';
import { ShadowBox } from '../../../design/components/shadowBox/ShadowBox';
import { CenteredPageFrame } from '../../../design/layout/centeredPageFrame/CenteredPageFrame';
import styles from './ResetPassword.Init.module.scss';
import { ResetPasswordService } from '../../../features/resetPassword/ResetPassword.service';

export const ResetPasswordInit = () => {
  const { useResetPasswordInit } = ResetPasswordService;
  const { onBack, onSubmit, register, resetSwitchText, resetText, switchRole } =
    useResetPasswordInit();

  return (
    <CenteredPageFrame>
      <HeartLinkLogo />
      <div className={styles.box}>
        <ShadowBox>
          <h1 className={styles.header}>{resetText}</h1>
          <div className={styles.form}>
            <PhoneLabelInputForm label={'Numer telefonu'} register={register} name={'phone'} />
            <div className={styles.button_wrapper}>
              <TertiaryButton title={'Cofnij'} onClick={onBack} />
              <PrimaryButton title={'Resetuj hasło'} onClick={onSubmit} />
            </div>
          </div>
        </ShadowBox>
        <div className={styles.tertiary_first}>
          <TertiaryButton title={resetSwitchText} onClick={switchRole} />
        </div>
      </div>
    </CenteredPageFrame>
  );
};
