import { useNavigate, useParams } from 'react-router-dom';
import { ShadowBox } from '../../design/components/shadowBox/ShadowBox';
import { AuthroizedFrame } from '../../design/layout/authorizedFrame/AuthorizedFrame';
import { IconButton } from '../../design/components/buttons/IconButton/IconButton';
import { FaChartBar, FaRegImages, FaBell, FaPills } from 'react-icons/fa';
import { LabelValueRow } from '../../design/components/labelValueRow/LabelValueRow';
import { IMAGES, MEASUREMENTS, PATIENT, PILLS } from '../../router/routes';
import styles from './Patient.module.scss';
import { BackButton } from '../../design/components/buttons/BackButton/BackButton';
import { PATIENTS } from '../../router/routes';
import { PatientsService } from '../../features/patients/Patients.service';
import { Loader } from '../../design/components/loader/loader';
import dayjs from 'dayjs';
import { useStore } from '../../shared/hooks/useStore';

export const Patient = () => {
  const navigate = useNavigate();
  const { patientId } = useParams();

  const {
    rootStore: { patientStore }
  } = useStore();

  const { usePatientById } = PatientsService;
  const { id, implantationDate, email, phone, loading, pump_serial_num } = usePatientById(
    patientId ?? ''
  );
  patientStore.currentPatientId = id ?? '';
  const formattedDate = dayjs(implantationDate).format('DD.MM.YYYY');

  const handleIconButtonClick = (destination: string) => navigate(destination);

  return (
    <AuthroizedFrame loading={loading} right={<BackButton destination={PATIENTS} />}>
      <div className={styles.box}>
        <ShadowBox>
          <LabelValueRow label={'Numer identyfikacyjny'} value={id ?? ''} marginBottom={18} />
          <LabelValueRow label={'Numer telefonu'} value={phone ?? ''} marginBottom={18} />
          <LabelValueRow label={'Adres email'} value={email ?? ''} marginBottom={18} />
          <LabelValueRow label={'Data implantacji'} value={formattedDate ?? ''} marginBottom={18} />
          <LabelValueRow
            label={'Numer seryjny pompy'}
            value={pump_serial_num ?? ''}
            marginBottom={30}
          />
          <div className={styles.row}>
            <IconButton
              title="Pomiary"
              icon={<FaChartBar size={50} color={'white'} />}
              onClick={() => handleIconButtonClick(`${PATIENT}/${patientId}${MEASUREMENTS}`)}
            />
            <IconButton
              title="Zdjęcia"
              icon={<FaRegImages size={50} color={'white'} />}
              onClick={() => handleIconButtonClick(`${PATIENT}/${patientId}${IMAGES}`)}
            />
          </div>
          <div className={styles.row}>
            <IconButton
              title="Powiadomienia"
              icon={<FaBell size={50} color={'white'} />}
              onClick={function (): void {
                throw new Error('Function not implemented.');
              }}
              disabled
            />
            <IconButton
              title="Leki"
              icon={<FaPills size={50} color={'white'} />}
              onClick={() => handleIconButtonClick(`${PATIENT}/${patientId}${PILLS}`)}
            />
          </div>
        </ShadowBox>
      </div>
    </AuthroizedFrame>
  );
};
