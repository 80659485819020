// not auth routes
export const LOGIN = '/';
export const VERIFY = '/verify';
export const RESET_PASSWORD_INIT = '/reset-password/init';
export const RESET_PASSWORD_VERIFY = '/reset-password/verify';
export const RESET_PASSWORD_CHANGE = '/reset-password/change';
export const PRIVACY_POLICY = '/privacy-policy';
export const DELETE_ACCOUNT = '/delete-account';

// auth routes
export const PATIENTS = '/auth/patients';
export const NOTIFICATIONS = '/auth/notifications';
export const SETTINGS = '/auth/settings';
export const NEW_PATIENT = '/auth/new/patient';
export const PATIENT = '/auth/patient';
export const DRUG_MANAGEMENT = '/auth/drug_management';

//patient routes
export const MEASUREMENTS = '/measurements';
export const IMAGES = '/images';
export const PILLS = '/pills';
export const MEASUREMENT = '/measurement';
export const IMAGE = '/image';
export const NEWTREATMENT = '/new/treatment';
export const TREATMENT = '/treatment';
export const TAKEN_PILLS = '/taken_pills';

//admin routes
export const ALL_PATIENTS = '/admin/patients';
export const ALL_DOCTORS = '/admin/doctors';
export const ADMIN_PATIENT = '/admin/patient';
export const ADMIN_DOCTOR = '/admin/doctor';
export const ADMIN_CREATE_DOCTOR = '/admin/doctors/new';
export const ADMIN_CREATE_PATIENT = '/admin/patients/new';
export const ADMIN_SETTINGS = '/admin/settings';
export const UPLOAD_DRUGS = '/admin/upload_drugs';

export const routeNames: Record<string, string> = {
  [LOGIN]: 'Logowanie',
  [VERIFY]: 'Weryfikacja',
  [PATIENTS]: 'Pacjenci',
  [NOTIFICATIONS]: 'Powiadomienia',
  [SETTINGS]: 'Ustawienia',
  [NEW_PATIENT]: 'Nowy pacjent',
  [PATIENT]: 'Szczegóły pacjenta',
  [IMAGES]: 'Zdjęcia',
  [IMAGE]: 'Szczegóły zdjęcia',
  [MEASUREMENTS]: 'Pomiary',
  [MEASUREMENT]: 'Pomiar',
  [RESET_PASSWORD_INIT]: 'Reset hasła',
  [RESET_PASSWORD_VERIFY]: 'Reset hasła',
  [RESET_PASSWORD_CHANGE]: 'Reset hasła',
  [PILLS]: 'Leki',
  [NEWTREATMENT]: 'Nowa terapia',
  [TREATMENT]: 'Szczegóły terapii',
  [DRUG_MANAGEMENT]: 'Zarządzanie lekami',
  [ALL_PATIENTS]: 'Zarządzanie pacjentami',
  [ALL_DOCTORS]: 'Zarządzanie lekarzami',
  [ADMIN_CREATE_DOCTOR]: 'Nowy lekarz',
  [ADMIN_CREATE_PATIENT]: 'Nowy pacjent',
  [ADMIN_SETTINGS]: 'Ustawienia',
  [UPLOAD_DRUGS]: 'Prześlij plik z lekami'
};
