import useAxios, { RefetchFunction } from 'axios-hooks';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { LOGIN, RESET_PASSWORD_INIT, VERIFY } from '../../router/routes';
import { useToaster } from '../../shared/hooks/useToaster';
import { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from '../../shared/types/storageKeys';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { useRole } from '../../shared/hooks/useRole';
import { ApiRoutes } from '../../shared/types/api';
import { Roles } from '../../shared/types/roles';

type LoginData = {
  phone: string;
  password: string;
};

type FetchLoginResponseType = {
  twoFAToken: string;
};

type OTPData = {
  hotp_code: string;
};

type FetchVerifyResponseType = {
  accessToken: string;
};

const useLogin = () => {
  const { showErrorToast } = useToaster();
  const { changeRole, loginToTxt, switchToTxt, switchRole, role } = useRole();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginData>({
    defaultValues: {
      phone: '',
      password: ''
    }
  });

  const [, loginDoctor] = useAxios<FetchLoginResponseType, LoginData>(ApiRoutes.AuthDoctorToken, {
    manual: true
  });

  const [, loginAdmin] = useAxios<FetchLoginResponseType, LoginData>(ApiRoutes.AuthAdminToken, {
    manual: true
  });

  const handleLoginResponse = async (
    login: RefetchFunction<LoginData, FetchLoginResponseType>,
    data: LoginData,
    role: Roles
  ) => {
    try {
      const response = await login({ data });

      const { twoFAToken } = response.data;

      if (!twoFAToken) {
        showErrorToast('Logowanie nie powiodło się');
        return;
      }
      changeRole(role);
      sessionStorage.setItem(SESSION_STORAGE_KEYS.TWO_FA_TOKEN, twoFAToken);
      navigate(VERIFY);
    } catch (err) {
      throw new Error((err as AxiosError).message);
    }
  };

  const login = async ({ phone, password }: LoginData) => {
    const data = {
      phone: `+48${phone}`,
      password
    };

    try {
      role === Roles.DOCTOR
        ? await handleLoginResponse(loginDoctor, data, Roles.DOCTOR)
        : await handleLoginResponse(loginAdmin, data, Roles.ADMIN);
    } catch (err) {
      const msg = (err as AxiosError).message;
      showErrorToast(msg);
    }
  };

  const onError = (errors: any) => {
    showErrorToast('Logowanie nie powiodło się');
  };

  const onResetPassword = () => {
    navigate(RESET_PASSWORD_INIT);
  };

  return {
    register,
    onResetPassword,
    onSubmit: handleSubmit(login, onError),
    loginToTxt,
    switchToTxt,
    switchRole
  };
};

const useVerify = () => {
  const navigate = useNavigate();
  const { showErrorToast, showInfoToast } = useToaster();
  const { Auth2FAToken, AuthResentOTP, HOME_ROUTE } = useRole();
  const twoFAToken = sessionStorage.getItem(SESSION_STORAGE_KEYS.TWO_FA_TOKEN);

  const [, fetchVerify] = useAxios<FetchVerifyResponseType, OTPData>(Auth2FAToken, {
    manual: true
  });
  const [, resentOTP] = useAxios<FetchLoginResponseType, any>(AuthResentOTP, {
    manual: true
  });

  useEffect(() => {
    if (!twoFAToken) {
      showErrorToast('Brak tokenu uwierzytelniającego');
      navigate(LOGIN);
    }
  }, [twoFAToken]);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<OTPData>({
    defaultValues: {
      hotp_code: ''
    }
  });

  const onResentOTP = async () => {
    try {
      const twoFAToken = sessionStorage.getItem(SESSION_STORAGE_KEYS.TWO_FA_TOKEN);

      const response = await resentOTP({
        headers: {
          twoFAToken
        }
      });

      const { twoFAToken: token } = response.data;

      if (!token) {
        showErrorToast('Nie mogliśmy wysłać numeru ponownie');
        return;
      }

      sessionStorage.setItem(SESSION_STORAGE_KEYS.TWO_FA_TOKEN, token);
      showInfoToast('Kod weryfikacyjny wysłany ponownie');
    } catch (err) {
      showErrorToast('Nie mogliśmy wysłać tokenu !');
    }
  };

  const verify = async (data: OTPData) => {
    const twoFAToken = sessionStorage.getItem(SESSION_STORAGE_KEYS.TWO_FA_TOKEN);
    try {
      const response = await fetchVerify({
        data,
        headers: {
          twoFAToken
        }
      });

      const { accessToken } = response.data;
      if (!accessToken) {
        showErrorToast('Logowanie nie powiodło się');
        return;
      }

      localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, accessToken);

      const expiryDate = dayjs().add(1, 'day').toISOString();
      localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_EXPIRES_AT, expiryDate);

      navigate(HOME_ROUTE);
    } catch (err) {
      const msg = (err as AxiosError).message;
      showErrorToast(msg);
    }
  };

  const onError = (errors: any) => {
    showErrorToast('Logowanie nie powiodło się');
  };

  return {
    register,
    onResentOTP,
    onSubmit: handleSubmit(verify, onError)
  };
};

export const LoginService = {
  useLogin,
  useVerify
};
