import { useNavigate } from 'react-router-dom';
import { useToaster } from '../../shared/hooks/useToaster';
import { LOCAL_STORAGE_KEYS } from '../../shared/types/storageKeys';
import useAxios from 'axios-hooks';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ApiRoutes } from '../../shared/types/api';
import { FieldErrors, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { ALL_PATIENTS } from '../../router/routes';
import { CreatePatientInput } from './types';

export const useCreatePatient = () => {
  const navigate = useNavigate();
  const { showErrorToast, showSuccessToast } = useToaster();
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const [, createPatient] = useAxios<{ message: string }, CreatePatientInput>(
    {
      ...ApiRoutes.AdminPatientCreate,
      headers: {
        accessToken
      }
    },
    { manual: true }
  );

  const schema = yup.object().shape({
    phone: yup.string().required('Numer telefonu - pole wymagane'),
    email: yup.string().required('Email - pole wymagane').email('Niepoprawny adres email'),
    patient_id: yup.string().required('ID pacjenta - pole wymagane'),
    implantation_date: yup.string().required('Data implantacji - pole wymagane'),
    pump_serial_num: yup.string().required('Numer seryjny pompy - pole wymagane')
  });

  const { register, handleSubmit } = useForm<CreatePatientInput>({
    defaultValues: {
      phone: '',
      email: '',
      patient_id: '',
      implantation_date: dayjs().format('YYYY-MM-DD'),
      pump_serial_num: ''
    },
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data: CreatePatientInput) => {
    try {
      await createPatient({
        data: {
          ...data,
          phone: `+48${data.phone}`,
          implantation_date: dayjs(data.implantation_date).format('YYYY-MM-DD')
        },
        headers: {
          accessToken
        }
      });
      showSuccessToast('Pomyślnie utworzono pacjenta');
      navigate(ALL_PATIENTS);
    } catch (error) {
      showErrorToast('Nie udało się utworzyć pacjenta');
      console.error(error);
    }
  };

  const onErrors = (errors: FieldErrors<CreatePatientInput>) => {
    const msg = Object.values(errors)[0].message;
    if (msg) {
      showErrorToast(msg);
      console.error(errors);
    }
  };

  return {
    register,
    handleSubmit: handleSubmit(onSubmit, onErrors)
  };
};
