import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../design/components/buttons/PrimaryButton/PrimaryButton';
import { AuthroizedFrame } from '../../design/layout/authorizedFrame/AuthorizedFrame';
import { PatientsList } from '../../features/patients/components/PatientsList/PatientsList';
import { NEW_PATIENT } from '../../router/routes';
import styles from './Patients.module.scss';
import { PatientsService } from '../../features/patients/Patients.service';

export const Patients = () => {
  const navigate = useNavigate();
  const { useMyPatients } = PatientsService;
  const { patients, loading, nextPage, prevPage, page } = useMyPatients();

  return (
    <AuthroizedFrame>
      <div className={styles.button_wrapper}>
        <PrimaryButton title={'Dodaj nowego pacjenta'} onClick={() => navigate(NEW_PATIENT)} />
      </div>
      <div className={styles.patients_container}>
        <PatientsList
          patients={patients}
          loading={loading}
          nextPage={nextPage}
          prevPage={prevPage}
          page={page}
        />
      </div>
    </AuthroizedFrame>
  );
};
