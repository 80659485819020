import styles from './NewPatient.module.scss';
import commonStyles from '../../../design/styles/Common.module.scss';
import { ShadowBox } from '../../../design/components/shadowBox/ShadowBox';
import { LabelFormInput } from '../../../design/components/LabelInput/LabelInput';
import { PhoneLabelInputForm } from '../../../design/components/LabelInput/PhoneLabelInput';
import { useCreatePatient } from '../../../features/admin/useCreatePatient';
import { PrimaryButton } from '../../../design/components/buttons/PrimaryButton/PrimaryButton';
import { BackButton } from '../../../design/components/buttons/BackButton/BackButton';
import { ALL_PATIENTS } from '../../../router/routes';
import { AuthroizedFrame } from '../../../design/layout/authorizedFrame/AuthorizedFrame';
import { FaUser } from 'react-icons/fa';
import { blue } from '../../../design/styles/colors';

export const AdminNewPatient = () => {
  const { register, handleSubmit } = useCreatePatient();

  return (
    <AuthroizedFrame right={<BackButton destination={ALL_PATIENTS} />}>
      <div className={styles.box}>
        <ShadowBox>
          <form className={styles.form}>
            <div className={commonStyles.rowCentered}>
              <FaUser size={50} color={blue} />
              <h2 className={commonStyles.textBlue}>Tworzenie nowego pacjenta</h2>
            </div>
            <LabelFormInput
              label={'Numer identyfikacyjny'}
              type={'text'}
              register={register}
              name={'patient_id'}
            />
            <LabelFormInput
              label={'Numer seryjny pompy'}
              type={'text'}
              register={register}
              name={'pump_serial_num'}
            />
            <PhoneLabelInputForm label={'Numer telefonu'} register={register} name={'phone'} />
            <LabelFormInput
              label={'Data implantacji'}
              type={'date'}
              register={register}
              name={'implantation_date'}
            />
            <LabelFormInput
              label={'Adres email'}
              type={'email'}
              register={register}
              name={'email'}
            />
            <div>
              <PrimaryButton
                title={'Dodaj nowego pacjenta'}
                marginTop={40}
                onClick={handleSubmit}
              />
            </div>
          </form>
        </ShadowBox>
      </div>
    </AuthroizedFrame>
  );
};
