import useAxios from 'axios-hooks';
import { useToaster } from '../../shared/hooks/useToaster';
import { LOCAL_STORAGE_KEYS } from '../../shared/types/storageKeys';
import { ApiRoutes } from '../../shared/types/api';

export const useSetCoordination = () => {
  const { showErrorToast, showSuccessToast } = useToaster();
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);

  const [, setCoordination] = useAxios<any>(
    {
      headers: {
        accessToken
      }
    },
    { manual: true }
  );

  const onSubmit = async (isUpgrade: boolean, patientId: string, doctorId: string) => {
    const fetchRoute = isUpgrade
      ? ApiRoutes.AdminUpgradeRelation
      : ApiRoutes.AdminDowngradeRelation;
    try {
      await setCoordination({
        method: fetchRoute.method,
        url: fetchRoute.url,
        data: {
          patient_guid: patientId,
          doctor_guid: doctorId
        }
      });
      showSuccessToast(
        isUpgrade ? 'Pomyślnie dodano koordynację' : 'Pomyślnie usunięto koordynację'
      );
    } catch (error) {
      showErrorToast(
        isUpgrade ? 'Nie udało się dodać koordynacji' : 'Nie udało się usunąć koordynacji'
      );
      console.error(error);
    }
  };

  return {
    onSubmit
  };
};
