import styles from './NewDoctor.module.scss';
import commonStyles from '../../../design/styles/Common.module.scss';
import { ShadowBox } from '../../../design/components/shadowBox/ShadowBox';
import { LabelFormInput } from '../../../design/components/LabelInput/LabelInput';
import { PhoneLabelInputForm } from '../../../design/components/LabelInput/PhoneLabelInput';
import { PrimaryButton } from '../../../design/components/buttons/PrimaryButton/PrimaryButton';
import { BackButton } from '../../../design/components/buttons/BackButton/BackButton';
import { ALL_DOCTORS, ALL_PATIENTS } from '../../../router/routes';
import { AuthroizedFrame } from '../../../design/layout/authorizedFrame/AuthorizedFrame';
import { useCreateDoctor } from '../../../features/admin/useCreateDoctor';
import { FaUserNurse } from 'react-icons/fa';
import { blue } from '../../../design/styles/colors';

export const AdminNewDoctor = () => {
  const { register, handleSubmit } = useCreateDoctor();

  return (
    <AuthroizedFrame right={<BackButton destination={ALL_DOCTORS} />}>
      <div className={styles.box}>
        <ShadowBox>
          <form className={styles.form}>
            <div className={commonStyles.rowCentered}>
              <FaUserNurse size={50} color={blue} />
              <h2 className={commonStyles.textBlue}>Tworzenie nowego lekarza</h2>
            </div>
            <LabelFormInput label={'Imię'} type={'text'} register={register} name={'name'} />
            <LabelFormInput label={'Nazwisko'} type={'text'} register={register} name={'surname'} />
            <PhoneLabelInputForm label={'Numer telefonu'} register={register} name={'phone'} />
            <LabelFormInput
              label={'Adres email'}
              type={'email'}
              register={register}
              name={'email'}
            />
            <div>
              <PrimaryButton title={'Dodaj nowego lekarza'} marginTop={40} onClick={handleSubmit} />
            </div>
          </form>
        </ShadowBox>
      </div>
    </AuthroizedFrame>
  );
};
