import { AuthroizedFrame } from '../../design/layout/authorizedFrame/AuthorizedFrame';
import { PATIENTS } from '../../router/routes';
import styles from './NewPatient.module.scss';
import { BackButton } from '../../design/components/buttons/BackButton/BackButton';
import { PatientForm } from '../../features/patients/components/PatientForm/PatientForm';

export const NewPatient = () => {
  return (
    <AuthroizedFrame right={<BackButton destination={PATIENTS} />}>
      <div className={styles.box}>
        <PatientForm />
      </div>
    </AuthroizedFrame>
  );
};
