import { FC, PropsWithChildren } from 'react';
import styles from './ModalBackground.module.scss';

export const ModalBackground: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <>
      <div className={styles.modal_overlay}></div>
      <div className={styles.modal_wrapper}>{children}</div>
    </>
  );
};
