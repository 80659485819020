import Switch from 'react-switch';
import styles from './SwitchLabel.module.scss';

type SwitchLabelProps = {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
};

export const SwitchLabel = ({ label, checked, onChange }: SwitchLabelProps) => {
  const name = label.toLowerCase();
  return (
    <div className={styles.row}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <Switch onChange={onChange} checked={checked} />
    </div>
  );
};
