import { ChangeEvent, useEffect, useReducer, useState } from 'react';
import { AuthroizedFrame } from '../../design/layout/authorizedFrame/AuthorizedFrame';
import styles from './Measurements.module.scss';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';
import { ShadowBox } from '../../design/components/shadowBox/ShadowBox';
import { PrimaryButton } from '../../design/components/buttons/PrimaryButton/PrimaryButton';
import { useNavigate, useParams } from 'react-router-dom';
import { MEASUREMENT, PATIENT } from '../../router/routes';
import { BackButton } from '../../design/components/buttons/BackButton/BackButton';
import { LabelCheckbox } from '../../design/components/LabelCheckbox/LabelCheckbox';
import LineChart from '../../design/components/LineChart/LineChart';
import { ChartOption, MeasurementView } from '../../assets/types';
import { useMeasurement } from './Measurement.hook';
import { Measurement } from '../../assets/types';
import { Controller } from 'react-hook-form';
import { useStore } from '../../shared/hooks/useStore';
import dayjs from 'dayjs';
import { PatientsService } from '../../features/patients/Patients.service';
import { PageNavigator } from '../../design/components/PageNavigator/PageNavigator';

const columnHelper = createColumnHelper<Measurement>();

export const Measurements = () => {
  const { patientId } = useParams();
  const { usePatientMeasurements } = PatientsService;
  const { result, loading, nextPage, prevPage, page } = usePatientMeasurements(patientId ?? '');
  const navigate = useNavigate();

  const measurements: Measurement[] = result ?? [];
  useEffect(() => {
    setData(result ?? []);
  }, [result]);

  const { control, onPressCheck } = useMeasurement();

  const {
    rootStore: { chartStore }
  } = useStore();

  const checkboxDisabledList = chartStore.checkboxDisabledList;

  const [view, setView] = useState(MeasurementView.LIST);
  const [data, setData] = useState(measurements);
  const rerender = useReducer(() => ({}), {})[1];

  const handleMeasurementClick = (id: string) => {
    navigate(`${PATIENT}/${patientId}${MEASUREMENT}/${id}`);
  };

  const columnNames = [
    'NR',
    'Data',
    'Godzina',
    'Przepływ',
    'WP',
    'Masa ciała',
    'Temperatura ciała'
  ];

  const columns = [
    columnHelper.accessor('measured_at', {
      header: () => columnNames[1],
      cell: (info) => {
        return dayjs(info.getValue()).format('DD.MM.YYYY');
      }
    }),
    columnHelper.accessor('measured_at', {
      header: () => columnNames[2],
      cell: (info) => {
        return dayjs(info.getValue()).format('HH:mm');
      }
    }),
    columnHelper.accessor('flow', {
      header: () => columnNames[3],
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('wp', {
      header: () => columnNames[4],
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('body_mass', {
      header: () => columnNames[5],
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('body_temperature', {
      header: () => columnNames[6],
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('id', {
      header: () => '',
      cell: (info) => (
        <div className={styles.action_button}>
          <PrimaryButton
            title={'Zobacz pomiar'}
            onClick={() => handleMeasurementClick(info.getValue().toString())}
            height={48}
          />
        </div>
      )
    })
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  const showList = () => {
    setView(MeasurementView.LIST);
  };

  const showChart = () => {
    setView(MeasurementView.CHART);
  };

  const myData = measurements.map((measurement) => ({
    date: new Date(measurement.measured_at),
    values: {
      [ChartOption.ROTARY_SPEED]: measurement.rotary_speed,
      [ChartOption.FLOW]: measurement.flow,
      [ChartOption.WP]: measurement.wp,
      [ChartOption.POWER]: measurement.power,
      [ChartOption.INR]: measurement.inr,
      [ChartOption.BLOOD_THINNING_DRUG_DOSE]: measurement.blood_thinning_drug_dose,
      [ChartOption.BODY_TEMPERATURE]: measurement.body_temperature,
      [ChartOption.BODY_MASS]: measurement.body_mass,
      [ChartOption.SYSTOLIC_BLOOD_PRESSURE]: measurement.systolic_blood_pressure,
      [ChartOption.DIASTOLIC_BLOOD_PRESSURE]: measurement.diastolic_blood_pressure,
      [ChartOption.AVERAGE_BLOOD_PRESSURE]: measurement.average_blood_pressure,
      [ChartOption.PULSE]: measurement.pulse,
      [ChartOption.FLUIDS_TAKEN]: measurement.fluids_taken,
      [ChartOption.FLUIDS_EXCRETED]: measurement.fluids_excreted,
      [ChartOption.COMFORT]: measurement.comfort,
      [ChartOption.WALK_DISTANCE]: measurement.walk_distance
    }
  }));

  function capitalizeFirstLetter(inputString: string) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    onChange: (...event: any[]) => void
  ) => {
    onChange(event);
    onPressCheck();

    let chartOption = capitalizeFirstLetter(event.target.name) as ChartOption;

    if (event.target.name == 'inr' || event.target.name == 'wp') {
      chartOption = event.target.name.toUpperCase() as ChartOption;
    }

    if (event.currentTarget.checked) {
      chartStore.addChartOption(chartOption);
    } else {
      chartStore.removeChartOption(chartOption);
    }
  };

  return (
    <AuthroizedFrame
      loading={loading}
      right={<BackButton destination={`${PATIENT}/${patientId}`} />}>
      <div className={styles.buttons_container}>
        <a
          className={styles.button}
          style={{
            backgroundColor: view === MeasurementView.LIST ? 'black' : 'white',
            color: view === MeasurementView.LIST ? 'white' : 'black'
          }}
          onClick={() => showList()}>
          Lista
        </a>
        <a
          className={styles.button}
          style={{
            backgroundColor: view === MeasurementView.LIST ? 'white' : 'black',
            color: view === MeasurementView.LIST ? 'black' : 'white'
          }}
          onClick={() => showChart()}>
          Wykres
        </a>
      </div>
      {view === MeasurementView.LIST ? (
        <div className={styles.table_wrapper}>
          <ShadowBox padding="10px" minHeight="555px">
            <table>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    <td className={styles.table_header}>{columnNames[0]}</td>
                    {headerGroup.headers.map((header) => (
                      <th className={styles.table_header} key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    <td className={styles.table_data}>{parseInt(row.id) + 1}.</td>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className={styles.table_data}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </ShadowBox>
          <PageNavigator prevPage={prevPage} nextPage={nextPage} page={page} />
        </div>
      ) : (
        <div className={styles.box}>
          <ShadowBox padding="10px">
            <LineChart dataArray={myData} />
          </ShadowBox>
          <div className={styles.box_wrapper}>
            <ShadowBox padding="20px" minWidth="300px">
              <form className={styles.checkbox_form}>
                <h1>Parametr</h1>
                <Controller
                  name={'rotary_speed'}
                  control={control}
                  rules={{}}
                  render={({ field: { onChange, value } }) => (
                    <LabelCheckbox
                      label={'Prędkość'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event, onChange);
                      }}
                      value={value}
                      disabled={checkboxDisabledList.includes(ChartOption.ROTARY_SPEED)}
                    />
                  )}
                />
                <Controller
                  name={'flow'}
                  control={control}
                  rules={{}}
                  render={({ field: { onChange, value } }) => (
                    <LabelCheckbox
                      label={'Przepływ'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event, onChange);
                      }}
                      value={value}
                      disabled={checkboxDisabledList.includes(ChartOption.FLOW)}
                    />
                  )}
                />
                <Controller
                  name={'wp'}
                  control={control}
                  rules={{}}
                  render={({ field: { onChange, value } }) => (
                    <LabelCheckbox
                      label={'Wp'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event, onChange);
                      }}
                      value={value}
                      disabled={checkboxDisabledList.includes(ChartOption.WP)}
                    />
                  )}
                />
                <Controller
                  name={'power'}
                  control={control}
                  rules={{}}
                  render={({ field: { onChange, value } }) => (
                    <LabelCheckbox
                      label={'Moc'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event, onChange);
                      }}
                      value={value}
                      disabled={checkboxDisabledList.includes(ChartOption.POWER)}
                    />
                  )}
                />
                <Controller
                  name={'inr'}
                  control={control}
                  rules={{}}
                  render={({ field: { onChange, value } }) => (
                    <LabelCheckbox
                      label={'Inr'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event, onChange);
                      }}
                      value={value}
                      disabled={checkboxDisabledList.includes(ChartOption.INR)}
                    />
                  )}
                />
                <Controller
                  name={'blood_thinning_drug_dose'}
                  control={control}
                  rules={{}}
                  render={({ field: { onChange, value } }) => (
                    <LabelCheckbox
                      label={'Dawka leku'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event, onChange);
                      }}
                      value={value}
                      disabled={checkboxDisabledList.includes(ChartOption.BLOOD_THINNING_DRUG_DOSE)}
                    />
                  )}
                />
                <Controller
                  name={'body_temperature'}
                  control={control}
                  rules={{}}
                  render={({ field: { onChange, value } }) => (
                    <LabelCheckbox
                      label={'Temperatura ciała'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event, onChange);
                      }}
                      value={value}
                      disabled={checkboxDisabledList.includes(ChartOption.BODY_TEMPERATURE)}
                    />
                  )}
                />
                <Controller
                  name={'body_mass'}
                  control={control}
                  rules={{}}
                  render={({ field: { onChange, value } }) => (
                    <LabelCheckbox
                      label={'Masa ciała'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event, onChange);
                      }}
                      value={value}
                      disabled={checkboxDisabledList.includes(ChartOption.BODY_MASS)}
                    />
                  )}
                />
                <Controller
                  name={'systolic_blood_pressure'}
                  control={control}
                  rules={{}}
                  render={({ field: { onChange, value } }) => (
                    <LabelCheckbox
                      label={'Skurczowe ciśnienie tętnicze'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event, onChange);
                      }}
                      value={value}
                      disabled={checkboxDisabledList.includes(ChartOption.SYSTOLIC_BLOOD_PRESSURE)}
                    />
                  )}
                />
                <Controller
                  name={'diastolic_blood_pressure'}
                  control={control}
                  rules={{}}
                  render={({ field: { onChange, value } }) => (
                    <LabelCheckbox
                      label={'Rozkurczowe ciśnienie tętnicze'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event, onChange);
                      }}
                      value={value}
                      disabled={checkboxDisabledList.includes(ChartOption.DIASTOLIC_BLOOD_PRESSURE)}
                    />
                  )}
                />
                <Controller
                  name={'average_blood_pressure'}
                  control={control}
                  rules={{}}
                  render={({ field: { onChange, value } }) => (
                    <LabelCheckbox
                      label={'Średnie ciśnienie tętnicze'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event, onChange);
                      }}
                      value={value}
                      disabled={checkboxDisabledList.includes(ChartOption.AVERAGE_BLOOD_PRESSURE)}
                    />
                  )}
                />
                <Controller
                  name={'pulse'}
                  control={control}
                  rules={{}}
                  render={({ field: { onChange, value } }) => (
                    <LabelCheckbox
                      label={'Tętno'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event, onChange);
                      }}
                      value={value}
                      disabled={checkboxDisabledList.includes(ChartOption.PULSE)}
                    />
                  )}
                />
                <Controller
                  name={'fluids_taken'}
                  control={control}
                  rules={{}}
                  render={({ field: { onChange, value } }) => (
                    <LabelCheckbox
                      label={'Płyny przyjęte'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event, onChange);
                      }}
                      value={value}
                      disabled={checkboxDisabledList.includes(ChartOption.FLUIDS_TAKEN)}
                    />
                  )}
                />
                <Controller
                  name={'fluids_excreted'}
                  control={control}
                  rules={{}}
                  render={({ field: { onChange, value } }) => (
                    <LabelCheckbox
                      label={'Płyny wydalone'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event, onChange);
                      }}
                      value={value}
                      disabled={checkboxDisabledList.includes(ChartOption.FLUIDS_EXCRETED)}
                    />
                  )}
                />
                <Controller
                  name={'comfort'}
                  control={control}
                  rules={{}}
                  render={({ field: { onChange, value } }) => (
                    <LabelCheckbox
                      label={'Samopoczucie'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event, onChange);
                      }}
                      value={value}
                      disabled={checkboxDisabledList.includes(ChartOption.COMFORT)}
                    />
                  )}
                />
                <Controller
                  name={'walk_distance'}
                  control={control}
                  rules={{}}
                  render={({ field: { onChange, value } }) => (
                    <LabelCheckbox
                      label={'Dystans spacerów'}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChange(event, onChange);
                      }}
                      value={value}
                      disabled={checkboxDisabledList.includes(ChartOption.WALK_DISTANCE)}
                    />
                  )}
                />
              </form>
            </ShadowBox>
          </div>
        </div>
      )}
    </AuthroizedFrame>
  );
};
