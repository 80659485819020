import { useState } from 'react';
import { Roles } from '../types/roles';
import { LOCAL_STORAGE_KEYS } from '../types/storageKeys';
import { ApiRoutes } from '../types/api';
import { ALL_PATIENTS, PATIENTS } from '../../router/routes';

export const useRole = () => {
  const [role, setRole] = useState<Roles>(
    (localStorage.getItem(LOCAL_STORAGE_KEYS.ROLES) as Roles) ?? Roles.DOCTOR
  );

  const changeRole = (role: Roles) => {
    setRole(role);
    localStorage.setItem(LOCAL_STORAGE_KEYS.ROLES, role);
  };

  const switchRole = () => {
    if (role === Roles.DOCTOR) {
      changeRole(Roles.ADMIN);
    } else {
      changeRole(Roles.DOCTOR);
    }
  };

  const isAdmin = role === 'ADMIN';
  const isDoctor = role === 'DOCTOR';

  const loginToAdmin = 'Zaloguj się do panelu administratora';
  const loginToDoctor = 'Zaloguj się do panelu lekarza';

  const resetAdmin = 'Resetuj hasło administratora';
  const resetDoctor = 'Resetuj hasło lekarza';

  const loginToTxt = isAdmin ? loginToAdmin : loginToDoctor;
  const switchToTxt = !isAdmin ? loginToAdmin : loginToDoctor;

  const resetText = isAdmin ? resetAdmin : resetDoctor;
  const resetSwitchText = !isAdmin ? resetAdmin : resetDoctor;

  // Routes based on selected Role
  // If you are reading this, you are probably askign yourself
  // WHY ????
  // WTF IS THAT SHIT ??
  // Let me answer
  // I am sorry. I could not do it better
  // You cannot fix what is already broken

  const AuthToken = isAdmin ? ApiRoutes.AuthAdminToken : ApiRoutes.AuthDoctorToken;
  const Auth2FAToken = isAdmin ? ApiRoutes.AuthAdmin2FAToken : ApiRoutes.AuthDoctor2FAToken;
  const AuthRefresh = isAdmin ? ApiRoutes.AuthAdminRefresh : ApiRoutes.AuthDoctorRefresh;
  const AuthResentOTP = isAdmin ? ApiRoutes.AuthAdminResentOTP : ApiRoutes.AuthDoctorResentOTP;
  const AuthChangePassword = isAdmin
    ? ApiRoutes.AuthAdminChangePassword
    : ApiRoutes.AuthDoctorChangePassword;
  const AuthResetPasswordInit = isAdmin
    ? ApiRoutes.ResetPasswordAdmin.Init
    : ApiRoutes.ResetPassword.Init;
  const AuthResetPasswordVerify = isAdmin
    ? ApiRoutes.ResetPasswordAdmin.Verify
    : ApiRoutes.ResetPassword.Verify;
  const AuthResetPasswordReset = isAdmin
    ? ApiRoutes.ResetPasswordAdmin.Reset
    : ApiRoutes.ResetPassword.Reset;

  const HOME_ROUTE = isAdmin ? ALL_PATIENTS : PATIENTS;

  return {
    role,
    changeRole,
    isAdmin,
    isDoctor,
    switchRole,
    AuthToken,
    Auth2FAToken,
    AuthRefresh,
    AuthResentOTP,
    AuthChangePassword,
    AuthResetPasswordInit,
    AuthResetPasswordVerify,
    AuthResetPasswordReset,
    loginToTxt,
    switchToTxt,
    HOME_ROUTE,
    resetText,
    resetSwitchText
  };
};
