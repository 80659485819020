import { FaRegUserCircle } from 'react-icons/fa';
import { ArrowButton } from '../../../../design/components/buttons/ArrowButton/ArrowButton';
import { LabelValueRow } from '../../../../design/components/labelValueRow/LabelValueRow';
import styles from './PatientCard.module.scss';
import { blue } from '../../../../design/styles/colors';
import { useNavigate } from 'react-router-dom';
import { PATIENT } from '../../../../router/routes';
import { Patient } from '../../types';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from '../../../../shared/constants';

export const PatientCard = ({
  patient,
  doctorView = true,
  onPatientClick
}: {
  patient: Patient;
  doctorView?: boolean;
  onPatientClick?: (patient: Patient) => void;
}) => {
  const { guid, last_measurement_date, last_photo_date, patient_id } = patient;
  const navigate = useNavigate();
  const onClick = onPatientClick
    ? () => onPatientClick(patient)
    : () => navigate(`${PATIENT}/${guid}`);

  const lastMeasurementDate = last_measurement_date
    ? dayjs(last_measurement_date).format(DATE_TIME_FORMAT)
    : 'Brak';

  const lastImageDate = last_photo_date ? dayjs(last_photo_date).format(DATE_TIME_FORMAT) : 'Brak';

  return (
    <div className={styles.card}>
      <div>
        <FaRegUserCircle color={blue} size={50} />
      </div>

      <div className={styles.middle}>
        <p className={styles.username}>{patient_id}</p>
        {doctorView ? (
          <div className={styles.dates}>
            {lastMeasurementDate && (
              <LabelValueRow label="Ostatni pomiar" value={lastMeasurementDate} />
            )}
            {lastImageDate && <LabelValueRow label="Ostatnie zdjęcie" value={lastImageDate} />}
            {/* <LabelValueRow label="Ostatnie leki" value="12.12.12" /> */}
          </div>
        ) : (
          patient.guid
        )}
      </div>

      <div>
        <ArrowButton size={20} color={blue} onClick={onClick} />
      </div>
    </div>
  );
};
