import { useNavigate, useParams } from 'react-router-dom';
import styles from './PrescriptionCard.module.scss';
import { PrimaryButton } from '../buttons/PrimaryButton/PrimaryButton';
import { PATIENT, TREATMENT } from '../../../router/routes';
import { useState } from 'react';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import { PatientPrescription } from '../../../shared/types/prescriptions';
import { removeDecimal } from '../../../shared/utils/removeDecimal';
import { processPrescription } from '../../../shared/utils/getPillsInterval';
import PrescriptionsService from '../../../features/prescriptions/Prescriptions.service';

export const PrescriptionCard = ({ prescription }: { prescription: PatientPrescription }) => {
  const { drug_name, morning, afternoon, evening, dose, unit, id } = prescription;
  const parsedMorning = removeDecimal(morning);
  const parsedAfternoon = removeDecimal(afternoon);
  const parsedEvening = removeDecimal(evening);
  const interval = processPrescription(prescription);
  const navigate = useNavigate();
  const { patientId } = useParams();
  const [showModal, setShowModal] = useState(false);

  const { useDeletePrescription } = PrescriptionsService;
  const { onPress, deleted } = useDeletePrescription(patientId!, id);

  return deleted ? null : (
    <>
      <div className={styles.card_wrapper}>
        <div className={styles.card}>
          <div className={styles.columns}>
            <span className={styles.pills_name}>{drug_name}</span>
            <span className={styles.day_dosage}>
              [{parsedMorning}-{parsedAfternoon}-{parsedEvening}]
            </span>
          </div>
          <h3 className={styles.dosage}>
            {dose} {unit} - {interval}
          </h3>

          <div className={styles.buttons_container}>
            <PrimaryButton
              title={'Szczegóły terapii'}
              onClick={() => {
                navigate(`${PATIENT}/${patientId}${TREATMENT}/${id}`);
              }}
            />
          </div>
          <div className={styles.buttons_container}>
            <PrimaryButton
              title={'Usuń terapię'}
              onClick={() => {
                setShowModal(true);
              }}
              color="red"
            />
          </div>
        </div>
      </div>
      {showModal && (
        <ConfirmationModal
          text={'Czy na pewno chcesz usunąć tę terapię?'}
          setShowModal={setShowModal}
          onConfirm={onPress}
        />
      )}
    </>
  );
};
