import { useLocation, useNavigate } from 'react-router-dom';
import { blue, gray } from '../../styles/colors';
import styles from './Navbar.module.scss';
import {
  FaHeartbeat,
  FaUsers,
  FaBell,
  FaCog,
  FaPowerOff,
  FaPills,
  FaUsersCog,
  FaUserNurse,
  FaFileUpload
} from 'react-icons/fa';
import {
  LOGIN,
  PATIENTS,
  SETTINGS,
  DRUG_MANAGEMENT,
  ALL_PATIENTS,
  ALL_DOCTORS,
  ADMIN_SETTINGS,
  UPLOAD_DRUGS
} from '../../../router/routes';
import { NavigationButton } from '../../components/buttons/NavigationButton/NavigationButton';
import { useRole } from '../../../shared/hooks/useRole';

const logoSize = 70;
const iconSize = 50;
const buttonSize = 80;

export const Navbar = () => {
  const { pathname: currentRoute } = useLocation();
  const navigate = useNavigate();
  const { isAdmin, isDoctor } = useRole();

  const isActive = (route: string) => (currentRoute === route ? blue : gray);

  const onPatientsClick = () => navigate(PATIENTS);
  // const onNotificationsClick = () => navigate(NOTIFICATIONS);
  const onPillsClick = () => navigate(DRUG_MANAGEMENT);
  const onSettingsClick = () => navigate(SETTINGS);
  const onLogoutClick = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate(LOGIN);
  };

  const navigationButtons = [
    {
      icon: <FaUsers size={iconSize} color={isActive(PATIENTS)} />,
      onClick: onPatientsClick,
      visible: isDoctor
    },
    {
      icon: <FaPills size={iconSize} color={isActive(DRUG_MANAGEMENT)} />,
      onClick: onPillsClick,
      visible: isDoctor
    },
    {
      icon: <FaCog size={iconSize} color={isActive(SETTINGS)} />,
      onClick: onSettingsClick,
      visible: isDoctor
    },
    {
      icon: <FaUsersCog size={iconSize} color={isActive(ALL_PATIENTS)} />,
      onClick: () => navigate(ALL_PATIENTS),
      visible: isAdmin
    },
    {
      icon: <FaUserNurse size={iconSize} color={isActive(ALL_DOCTORS)} />,
      onClick: () => navigate(ALL_DOCTORS),
      visible: isAdmin
    },
    {
      icon: <FaFileUpload size={iconSize} color={isActive(UPLOAD_DRUGS)} />,
      onClick: () => navigate(UPLOAD_DRUGS),
      visible: isAdmin
    },
    {
      icon: <FaCog size={iconSize} color={isActive(ADMIN_SETTINGS)} />,
      onClick: () => navigate(ADMIN_SETTINGS),
      visible: isAdmin
    }
  ];

  return (
    <div className={styles.navbar}>
      <div className={styles.logo}>
        <FaHeartbeat size={logoSize} color={blue} />
      </div>

      <div className={styles.buttons}>
        <div className={styles.top}>
          {navigationButtons.map((button, index) =>
            button.visible ? (
              <NavigationButton
                key={index}
                icon={button.icon}
                size={buttonSize}
                onClick={button.onClick}
              />
            ) : null
          )}
        </div>

        <div className={styles.bottom}>
          <NavigationButton
            icon={<FaPowerOff size={iconSize} color={isActive(SETTINGS)} />}
            size={buttonSize}
            onClick={onLogoutClick}
          />
        </div>
      </div>
    </div>
  );
};
