import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import styles from './LabelInput.module.scss';

type Props = {
  label: string;
};

export const PhoneLabelInput = ({ label }: Props) => {
  const lowerLabel = label.toLowerCase();
  return (
    <div className={styles.wrapper}>
      <label className={styles.label} htmlFor={lowerLabel}>
        {label}
      </label>
      <div className={styles.phone_container}>
        <p className={styles.phone_prefix}>+48</p>
        <input className={styles.input} type="tel" placeholder={label} name={lowerLabel} />
      </div>
    </div>
  );
};

type PhoneInputFormProps<T extends FieldValues> = {
  label: string;
  register: UseFormRegister<T>;
  name: Path<T>;
};

export function PhoneLabelInputForm<T extends FieldValues>({
  label,
  register,
  name
}: PhoneInputFormProps<T>) {
  return (
    <div className={styles.wrapper}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <div className={styles.phone_container}>
        <p className={styles.phone_prefix}>+48</p>
        <input className={styles.input} type="tel" placeholder={label} {...register(name)} />
      </div>
    </div>
  );
}
