import * as yup from 'yup';

export type Doctor = {
  guid: string;
  email: string;
  full_name: string;
  name: string;
  phone: string;
  surname: string;
  coordinator_flag?: boolean;
};

export type DoctorsResponse = {
  result: Doctor[];
  pageCount: number;
  resultCount: number;
};

export type Patient = {
  guid: string;
  patient_id: string;
  phone: string;
  email: string;
  implantation_date: string;
  coordinator_flag?: boolean;
};

export type CreatePatientInput = {
  phone: string;
  email: string;
  patient_id: string;
  implantation_date: string;
  pump_serial_num: string;
};

export type CreateDoctorInput = {
  phone: string;
  email: string;
  name: string;
  surname: string;
};

export const searchUserSchema = yup.object().shape({
  searchTerm: yup.string().required('Wypełnij pole wyszukiwania').min(3, 'Wprowadź minimum 3 znaki')
});

export type UserSearchForm = { searchTerm: string };

export type SearchUsersResponse = Patient[] | Doctor[];
