import useAxios from 'axios-hooks';
import { ApiRoutes } from '../../shared/types/api';
import { CreatePatientInput, PatientResponse, PatientsResponse } from './types';
import { LOCAL_STORAGE_KEYS } from '../../shared/types/storageKeys';
import { useEffect, useState } from 'react';
import { useToaster } from '../../shared/hooks/useToaster';
import { useForm, FieldErrors } from 'react-hook-form';
import dayjs from 'dayjs';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { PATIENTS } from '../../router/routes';
import { usePagination } from '../../shared/hooks/usePagination';
import { ImagesResponse, Measurement, MeasurementsResponse } from '../../assets/types';

const useMyPatients = () => {
  const { showErrorToast } = useToaster();
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const { page, nextPage, prevPage, setTotalPages } = usePagination();
  const [{ data, loading, error }, refetch] = useAxios<PatientsResponse>(
    {
      method: ApiRoutes.Patients.Get.method,
      url: `${ApiRoutes.Patients.Get.url}?page_offset=${page}&page_size=9`,
      headers: {
        accessToken
      }
    },
    { manual: true }
  );

  useEffect(() => {
    if (data) {
      setTotalPages(data.pageCount || 1);
    }
  }, [data]);

  useEffect(() => {
    try {
      refetch();
    } catch (error) {
      showErrorToast('Nie udało się pobrać pacjentów');
    }
  }, [page]);

  useEffect(() => {
    if (error) {
      showErrorToast('Nie udało się pobrać pacjentów');
      console.error(error);
    }
  }, [error]);

  return {
    patients: data,
    loading,
    error,
    nextPage,
    prevPage,
    page
  };
};

const useCreatePatient = () => {
  const navigate = useNavigate();
  const { showErrorToast, showSuccessToast } = useToaster();
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const [, createPatient] = useAxios<{ message: string }, CreatePatientInput>({
    ...ApiRoutes.Patients.Create,
    headers: {
      accessToken
    }
  });

  const schema = yup.object().shape({
    phone: yup.string().required('Numer telefonu - pole wymagane'),
    email: yup.string().required('Email - pole wymagane').email('Niepoprawny adres email'),
    patient_id: yup.string().required('ID pacjenta - pole wymagane'),
    implantation_date: yup.string().required('Data implantacji - pole wymagane'),
    as_coordinator: yup.boolean().required('Pole wymagane'),
    pump_serial_num: yup.string().required('Numer seryjny pompy - pole wymagane')
  });

  const { register, handleSubmit, setValue, watch } = useForm<CreatePatientInput>({
    defaultValues: {
      phone: '',
      email: '',
      patient_id: '',
      pump_serial_num: '',
      implantation_date: dayjs().format('YYYY-MM-DD'),
      as_coordinator: true
    },
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data: CreatePatientInput) => {
    try {
      await createPatient({
        data: {
          ...data,
          phone: `+48${data.phone}`,
          implantation_date: dayjs(data.implantation_date).format('YYYY-MM-DD')
        },
        headers: {
          accessToken
        }
      });
      showSuccessToast('Pomyślnie utworzono pacjenta');
      navigate(PATIENTS);
    } catch (error) {
      showErrorToast('Nie udało się utworzyć pacjenta');
      console.error(error);
    }
  };

  const onErrors = (errors: FieldErrors<CreatePatientInput>) => {
    const msg = Object.values(errors)[0].message;
    if (msg) {
      showErrorToast(msg);
      console.error(errors);
    }
  };

  return {
    register,
    setValue,
    watch,
    handleSubmit: handleSubmit(onSubmit, onErrors)
  };
};

const usePatientById = (id: string) => {
  const { showErrorToast } = useToaster();
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const [{ data, loading, error }] = useAxios<PatientResponse>({
    url: ApiRoutes.Patients.Get.url + '/' + id,
    method: ApiRoutes.Patients.Get.method,
    headers: {
      accessToken
    }
  });

  useEffect(() => {
    if (error) {
      showErrorToast('Nie udało się pobrać danych pacjenta');
      console.error(error);
    }
  }, [error]);

  return {
    id: data?.patient_id,
    implantationDate: data?.implantation_date,
    email: data?.email,
    phone: data?.phone,
    pump_serial_num: data?.pump_serial_num,
    loading
  };
};

const usePatientMeasurements = (patientId: string) => {
  const { showErrorToast } = useToaster();
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const { page, nextPage, prevPage, setTotalPages } = usePagination();
  const route = `${ApiRoutes.Patients.GetOne.url}/${patientId}${ApiRoutes.Measurements.Get.url}?page_offset=${page}&page_size=10`;
  const [{ data, loading, error }] = useAxios<MeasurementsResponse>({
    url: route,
    method: ApiRoutes.Measurements.Get.method,
    headers: {
      accessToken
    }
  });

  useEffect(() => {
    if (data) {
      setTotalPages(data.pageCount || 1);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      showErrorToast('Nie udało się pobrać pomiarów pacjenta');
      console.error(error);
    }
  }, [error]);

  return {
    result: data?.result,
    loading,
    error,
    nextPage,
    prevPage,
    page
  };
};

const usePatientMeasurement = (patientId: string, measurementId: string) => {
  const { showErrorToast } = useToaster();
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const route = `${ApiRoutes.Patients.GetOne.url}/${patientId}${ApiRoutes.Measurements.Get.url}/${measurementId}`;
  const [{ data, loading, error }] = useAxios<Measurement>({
    url: route,
    method: ApiRoutes.Measurements.Get.method,
    headers: {
      accessToken
    }
  });

  useEffect(() => {
    if (error) {
      showErrorToast('Nie udało się pobrać pomiaru');
      console.error(error);
    }
  }, [error]);

  return {
    measurement: data,
    loading
  };
};

const usePatientImages = (patientId: string) => {
  const { showErrorToast } = useToaster();
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const { page, nextPage, prevPage, setTotalPages } = usePagination();
  const route = `${ApiRoutes.Patients.Get.url}/${patientId}${ApiRoutes.Images.Get.url}?page_offset=${page}&page_size=18`;
  const [{ data, loading, error }] = useAxios<ImagesResponse>({
    url: route,
    method: ApiRoutes.Images.Get.method,
    headers: {
      accessToken
    }
  });

  useEffect(() => {
    if (data) {
      setTotalPages(data.pageCount || 1);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      showErrorToast('Nie udało się pobrać zdjęć');
      console.error(error);
    }
  }, [error]);

  return {
    result: data?.result,
    loading,
    nextPage,
    prevPage,
    page
  };
};

const usePatientImage = (imageId: string) => {
  const { showErrorToast } = useToaster();
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const route = `${ApiRoutes.Patients.Get.url}${ApiRoutes.Images.Get.url}/${imageId}?thumbnail=false`;
  const [{ data, loading, error }] = useAxios<Blob>({
    url: route,
    method: ApiRoutes.Images.Get.method,
    headers: {
      accessToken
    },
    responseType: 'blob'
  });

  const [imageUri, setImageUri] = useState<string | null>(null);

  useEffect(() => {
    if (data) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUri(reader.result as string);
      };
      reader.readAsDataURL(data);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      showErrorToast('Nie udało się pobrać zdjęcia');
      console.error(error);
    }
  }, [error]);

  return {
    image: imageUri,
    loading
  };
};

export const PatientsService = {
  useMyPatients,
  useCreatePatient,
  usePatientById,
  usePatientMeasurements,
  usePatientMeasurement,
  usePatientImages,
  usePatientImage
};
