import useAxios from 'axios-hooks';
import { useToaster } from '../../shared/hooks/useToaster';
import { LOCAL_STORAGE_KEYS } from '../../shared/types/storageKeys';
import { ApiRoutes } from '../../shared/types/api';

export const useManageRelations = () => {
  const { showErrorToast, showSuccessToast } = useToaster();
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);

  const [, manageRelations] = useAxios<any>(
    {
      headers: {
        accessToken
      }
    },
    { manual: true }
  );

  const onSubmit = async (isAddAction: boolean, patientId: string, doctorId: string) => {
    const fetchRoute = isAddAction ? ApiRoutes.AdminAddRelation : ApiRoutes.AdminDeleteRelation;
    try {
      await manageRelations({
        method: fetchRoute.method,
        url: fetchRoute.url,
        data: {
          patient_guid: patientId,
          doctor_guid: doctorId,
          is_coordinator: false
        }
      });
      showSuccessToast(
        isAddAction ? 'Pomyślnie połączono użytkowników' : 'Pomyślnie odłączono użytkowników'
      );
    } catch (error) {
      showErrorToast(
        isAddAction ? 'Nie udało się połączyć użytkowników' : 'Nie udało się odłączyć użytkowników'
      );
      console.error(error);
    }
  };

  return {
    onSubmit
  };
};
