import { yupResolver } from '@hookform/resolvers/yup';
import { FieldErrors, useForm } from 'react-hook-form';
import { LOCAL_STORAGE_KEYS } from '../../shared/types/storageKeys';
import { ApiRoutes } from '../../shared/types/api';
import useAxios from 'axios-hooks';
import { useEffect } from 'react';
import { useToaster } from '../../shared/hooks/useToaster';
import { SearchUsersResponse, UserSearchForm, searchUserSchema } from './types';

export const useSearchUser = (isDoctor: boolean) => {
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const { showErrorToast } = useToaster();

  const fetchRoute = isDoctor ? ApiRoutes.AdminSearchPatients : ApiRoutes.AdminSearchDoctors;

  const { control, handleSubmit } = useForm<UserSearchForm>({
    defaultValues: { searchTerm: '' },
    resolver: yupResolver(searchUserSchema)
  });

  const [{ data, loading, error }, searchUsers] = useAxios<SearchUsersResponse>(
    {
      method: fetchRoute.method,
      headers: {
        accessToken
      }
    },
    { manual: true }
  );

  useEffect(() => {
    if (error) {
      showErrorToast('Odczyt nie udał się. Spróbuj ponownie później.');
    }
  }, [error]);

  const onSearch = ({ searchTerm }: UserSearchForm) => {
    try {
      searchUsers({
        url: fetchRoute.url(searchTerm)
      });
    } catch (err) {
      showErrorToast('Wystąpił błąd podczas wyszukiwania użytkownika.');
    }
  };

  useEffect(() => {
    if (data && data?.length === 0) {
      showErrorToast('Nie znaleziono takich użytkowników.');
    }
  }, [data]);

  const onErrors = (errors: FieldErrors<UserSearchForm>) => {
    const msg = Object.values(errors)[0].message;
    if (msg) {
      showErrorToast(msg);
      console.error(errors);
    }
  };

  const onSubmit = handleSubmit(onSearch, onErrors);

  return {
    control,
    onSubmit,
    loading,
    result: data ?? []
  };
};
