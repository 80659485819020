import { TakenDrugsResponse, TimeOfDay, TransformedTakenDrugs } from './TakenPills.types';

export function transformData(data: TakenDrugsResponse): TransformedTakenDrugs {
  const transformedData: TransformedTakenDrugs = [];

  const groupedByDate: { [date: string]: TakenDrugsResponse } = {};
  data.forEach((entry) => {
    if (!groupedByDate[entry.taken_date]) {
      groupedByDate[entry.taken_date] = [];
    }
    groupedByDate[entry.taken_date].push(entry);
  });

  for (const date in groupedByDate) {
    const transformedEntry: any = {
      id: groupedByDate[date][0].id,
      patient_id: groupedByDate[date][0].patient_id,
      taken_date: date,
      taken_by_time_of_day: {}
    };

    for (const timeOfDay of Object.values(TimeOfDay)) {
      transformedEntry.taken_by_time_of_day[timeOfDay] = [];
    }

    groupedByDate[date].forEach((entry) => {
      const { time_of_day, taken } = entry;
      transformedEntry.taken_by_time_of_day[time_of_day] = taken;
    });

    transformedData.push(transformedEntry);
  }

  return transformedData;
}
