import { useLocation } from 'react-router-dom';
import styles from './Header.module.scss';
import { routeNames } from '../../../router/routes';
import { ReactNode } from 'react';

type Props = {
  right?: ReactNode;
  headerText?: string;
};

export const Header = ({ right, headerText }: Props) => {
  const { pathname } = useLocation();
  const routeName = headerText ?? routeNames[pathname];

  return (
    <div className={styles.header}>
      <div>
        <h1 className={styles.title}>HeartLink</h1>
        <h3 className={styles.subtitle}>{routeName}</h3>
      </div>
      <div className={styles.right_wrapper}>{right}</div>
    </div>
  );
};
