import { yupResolver } from '@hookform/resolvers/yup';
import { useToaster } from '../../shared/hooks/useToaster';
import { FieldErrors, useFieldArray, useForm } from 'react-hook-form';
import { LOCAL_STORAGE_KEYS } from '../../shared/types/storageKeys';
import { ApiRoutes } from '../../shared/types/api';
import useAxios from 'axios-hooks';
import { useEffect, useState } from 'react';
import {
  CreateDrugForm,
  CreateDrugPayload,
  Drug,
  SearchDrugsResponse,
  SearchForm,
  createDrugSchema,
  searchDrugSchema
} from './types';

const useCreateDrug = () => {
  const { showErrorToast, showSuccessToast } = useToaster();

  const { control, handleSubmit, reset } = useForm<CreateDrugForm>({
    defaultValues: {
      name: '',
      power: [
        {
          value: '10 mg'
        }
      ]
    },
    resolver: yupResolver(createDrugSchema)
  });

  const onErrors = (errors: FieldErrors<CreateDrugForm>) => {
    const msg = Object.values(errors)[0].message;
    if (msg) {
      showErrorToast(msg);
      console.error(errors);
    }
  };

  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const [{ data, loading, error }, createDrug] = useAxios<string, CreateDrugPayload>(
    {
      ...ApiRoutes.Drugs.Add,
      headers: {
        accessToken
      }
    },
    { manual: true }
  );

  useEffect(() => {
    if (error) {
      showErrorToast('Zapis nie powiódł się. Spróbuj ponownie później.');
    }
  }, [error]);

  const onSubmit = async ({ name, power }: CreateDrugForm) => {
    try {
      const payload: CreateDrugPayload = {
        name,
        power: power.map(({ value }) => value)
      };

      const response = await createDrug({
        data: payload
      });

      if (response.status === 201) {
        showSuccessToast('Lek utworzony pomyślnie');
        reset();
      }
    } catch (error) {
      showErrorToast('Zapis nie powiódł się. Spróbuj ponownie później.');
    }
  };

  const onSubmitPress = handleSubmit(onSubmit, onErrors);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'power'
  });

  const onAppendPress = () => {
    append({ value: '10 mg' });
  };

  return {
    onAppendPress,
    control,
    fields,
    remove,
    onSubmitPress,
    loading
  };
};

const useSearchDrug = () => {
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const { showErrorToast } = useToaster();

  const { control, handleSubmit } = useForm<SearchForm>({
    defaultValues: { searchTerm: '' },
    resolver: yupResolver(searchDrugSchema)
  });

  const [{ data, loading, error }, searchDrugs] = useAxios<SearchDrugsResponse>(
    {
      method: ApiRoutes.Drugs.Search.method,
      headers: {
        accessToken
      }
    },
    { manual: true }
  );

  useEffect(() => {
    if (error) {
      showErrorToast('Odczyt nie udał się. Spróbuj ponownie później.');
    }
  }, [error]);

  const onSearch = ({ searchTerm }: SearchForm) => {
    try {
      searchDrugs({
        url: ApiRoutes.Drugs.Search.url(searchTerm)
      });
    } catch (err) {
      showErrorToast('Wystąpił błąd podczas wyszukiwania leku.');
    }
  };

  useEffect(() => {
    if (data?.drugs.length === 0) {
      showErrorToast('Nie znaleziono leków dla wprowadzonej nazwy.');
    }
  }, [data]);

  const onErrors = (errors: FieldErrors<SearchForm>) => {
    const msg = Object.values(errors)[0].message;
    if (msg) {
      showErrorToast(msg);
      console.error(errors);
    }
  };

  const onSubmit = handleSubmit(onSearch, onErrors);

  return {
    control,
    onSubmit,
    loading,
    result: data?.drugs ?? []
  };
};

const useDeleteDrug = () => {
  const { showErrorToast, showSuccessToast } = useToaster();
  const [removedDrugs, setRemovedDrugs] = useState<string[]>([]);
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const [{ loading, error }, deleteDrugs] = useAxios(
    {
      method: ApiRoutes.Drugs.Delete.method,
      headers: {
        accessToken
      }
    },
    { manual: true }
  );

  const onRemoveDrug = async ({ id, name }: Drug) => {
    if (confirm(`Czy na pewno chcesz usunąć lek: ${name} ?`)) {
      try {
        await deleteDrugs({
          url: ApiRoutes.Drugs.Delete.url(id)
        });
        setRemovedDrugs((prev) => [...prev, id]);
        showSuccessToast(`Lek: ${name} został usunięty`);
      } catch {
        showErrorToast('Nie udało się usunąć leku');
      }
    }
  };

  useEffect(() => {
    if (error) {
      showErrorToast('Odczyt nie udał się. Spróbuj ponownie później.');
    }
  }, [error]);

  const isRemoved = (id: string) => removedDrugs.includes(id);

  return {
    onRemoveDrug,
    loading,
    isRemoved
  };
};

export default {
  useCreateDrug,
  useSearchDrug,
  useDeleteDrug
};
