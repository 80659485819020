import { PatientPrescription } from '../types/prescriptions';

type DaysOfWeek = keyof Pick<
  PatientPrescription,
  'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'
>;

export function processPrescription(prescription: PatientPrescription): string {
  if (prescription?.everyday) {
    return 'Codziennie';
  }

  if (prescription?.odd_days) {
    return 'Dni parzyste';
  }

  if (prescription?.even_days) {
    return 'Dni nieparzyste';
  } else {
    let result = '';

    const daysOfWeek: DaysOfWeek[] = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday'
    ];
    const dayNames = ['pn', 'wt', 'śr', 'czw', 'pt', 'sob', 'niedz'];

    for (let i = 0; i < daysOfWeek.length; i++) {
      if (prescription[daysOfWeek[i]]) {
        result += dayNames[i] + ', ';
      }
    }

    return result.slice(0, -2);
  }
}
