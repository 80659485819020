import { ChartStore } from './ChartStore';
import { PatientStore } from './PatientStore';
export interface IRootStore {
  chartStore: ChartStore;
  patientStore: PatientStore;
}
export class RootStore implements IRootStore {
  chartStore: ChartStore;
  patientStore: PatientStore;
  constructor() {
    this.chartStore = new ChartStore(this);
    this.patientStore = new PatientStore(this);
  }
}
