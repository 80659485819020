import { toast, ToastOptions } from 'react-toastify';

const settings: ToastOptions = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light'
};

export const useToaster = () => {
  const showErrorToast = (message: string) => toast.error(message, settings);
  const showSuccessToast = (message: string) => toast.success(message, settings);
  const showInfoToast = (message: string) => toast.info(message, settings);

  return {
    showErrorToast,
    showSuccessToast,
    showInfoToast
  };
};
