import useAxios from 'axios-hooks';
import { useToaster } from '../../shared/hooks/useToaster';
import { LOCAL_STORAGE_KEYS } from '../../shared/types/storageKeys';
import { usePagination } from '../../shared/hooks/usePagination';
import { ApiRoutes } from '../../shared/types/api';
import { useEffect } from 'react';
import { DoctorsResponse } from './types';

export const useAllDoctors = () => {
  const { showErrorToast } = useToaster();
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const { page, nextPage, prevPage, setTotalPages } = usePagination();
  const [{ data, loading, error }, getAllDoctors] = useAxios<DoctorsResponse>(
    {
      method: ApiRoutes.AdminDoctors.method,
      url: `${ApiRoutes.AdminDoctors.url}?page_offset=${page}&page_size=10`,
      headers: {
        accessToken
      }
    },
    { manual: true }
  );

  useEffect(() => {
    if (data) {
      setTotalPages(data.pageCount || 1);
    }
  }, [data]);

  useEffect(() => {
    try {
      getAllDoctors();
    } catch (error) {
      showErrorToast('Nie udało się pobrać pacjentów');
    }
  }, [page]);

  useEffect(() => {
    if (error) {
      showErrorToast('Nie udało się pobrać pacjentów');
      console.error(error);
    }
  }, [error]);

  return {
    doctors: data,
    loading,
    error,
    nextPage,
    prevPage,
    page
  };
};
