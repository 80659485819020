import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Login } from './pages/Login/Login';
import { Verification } from './pages/Verification/Verification';
import {
  LOGIN,
  NOTIFICATIONS,
  PATIENTS,
  SETTINGS,
  VERIFY,
  NEW_PATIENT,
  PATIENT,
  IMAGES,
  IMAGE,
  MEASUREMENTS,
  RESET_PASSWORD_INIT,
  RESET_PASSWORD_VERIFY,
  RESET_PASSWORD_CHANGE,
  PILLS,
  NEWTREATMENT,
  MEASUREMENT,
  TREATMENT,
  DRUG_MANAGEMENT,
  TAKEN_PILLS,
  ALL_PATIENTS,
  ALL_DOCTORS,
  ADMIN_DOCTOR,
  ADMIN_PATIENT,
  ADMIN_CREATE_PATIENT,
  ADMIN_CREATE_DOCTOR,
  ADMIN_SETTINGS,
  PRIVACY_POLICY,
  DELETE_ACCOUNT,
  UPLOAD_DRUGS
} from './router/routes';
import { Settings } from './pages/Settings';
import { Notifications } from './pages/Notifications';
import { Patients } from './pages/Patients/Patients';
import { NewPatient } from './pages/NewPatient/NewPatient';
import { Patient } from './pages/Patient/Patient';
import { Images } from './pages/Images/Images';
import { Image } from './pages/Image/Image';
import { Measurements } from './pages/Measurements/Measurements';
import { Measurement } from './pages/Measurement/Measurement';
import { configure } from 'axios-hooks';
import Axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SettingsProvider from './context/Auth';
import { ResetPasswordInit } from './pages/ResetPassword/Init/ResetPassword.Init';
import { ResetPasswordVerification } from './pages/ResetPassword/Verification/Verification';
import { ResetPasswordChange } from './pages/ResetPassword/Change/Change';
import { Pills } from './pages/Pills/Pills';
import { NewTreatment } from './pages/NewTreatment/NewTreatment';
import { Config } from './config';
import { TreatmentScreen } from './pages/Treatment/Treatment';
import { DrugMenagement } from './pages/DrugManagement/DrugManagement';
import { TakenPills } from './pages/TakenPills/TakenPills';
import { AdminPatients } from './pages/Admin/Patients/Patients';
import { AdminDoctors } from './pages/Admin/Doctors/Doctors';
import { UserPage } from './pages/Admin/User/User';
import { AdminNewPatient } from './pages/Admin/NewPatient/NewPatient';
import { AdminNewDoctor } from './pages/Admin/NewDoctor/NewDoctor';
import { PrivacyPolicyScreen } from './pages/PrivacyPolicy/PrivacyPolicy';
import { DeleteAccount } from './pages/DeleteAccount/DeleteAccount';
import { UserConnections } from './pages/Admin/UserConnections/UserConnections';
import { UploadDrugs } from './pages/Admin/UploadDrugs/UploadDrugs';

const axios = Axios.create({
  baseURL: Config.API_URL
});

configure({ axios });

function App() {
  return (
    <SettingsProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        <Route path={LOGIN} element={<Login />} />
        <Route path={VERIFY} element={<Verification />} />
        <Route path={PATIENTS} element={<Patients />} />
        <Route path={NOTIFICATIONS} element={<Notifications />} />
        <Route path={SETTINGS} element={<Settings />} />
        <Route path={DRUG_MANAGEMENT} element={<DrugMenagement />} />
        <Route path={NEW_PATIENT} element={<NewPatient />} />
        <Route path={`${PATIENT}/:patientId`} element={<Patient />} />
        <Route path={`${PATIENT}/:patientId${MEASUREMENTS}`} element={<Measurements />} />
        <Route path={`${PATIENT}/:patientId${IMAGES}`} element={<Images />} />
        <Route path={`${PATIENT}/:patientId${PILLS}`} element={<Pills />} />
        <Route path={`${PATIENT}/:patientId${TAKEN_PILLS}`} element={<TakenPills />} />
        <Route
          path={`${PATIENT}/:patientId${MEASUREMENT}/:measurementId`}
          element={<Measurement />}
        />
        <Route path={`${PATIENT}/:patientId${IMAGE}/:imageId`} element={<Image />} />
        <Route path={`${PATIENT}/:patientId${NEWTREATMENT}`} element={<NewTreatment />} />
        <Route
          path={`${PATIENT}/:patientId${TREATMENT}/:treatmentId`}
          element={<TreatmentScreen />}
        />
        <Route path={RESET_PASSWORD_INIT} element={<ResetPasswordInit />} />
        <Route path={RESET_PASSWORD_VERIFY} element={<ResetPasswordVerification />} />
        <Route path={RESET_PASSWORD_CHANGE} element={<ResetPasswordChange />} />

        <Route path={ALL_PATIENTS} element={<AdminPatients />} />
        <Route path={ALL_DOCTORS} element={<AdminDoctors />} />
        <Route path={ADMIN_SETTINGS} element={<Settings />} />
        <Route path={UPLOAD_DRUGS} element={<UploadDrugs />} />

        <Route path={`${ALL_PATIENTS}/:patientId`} element={<UserPage user={'patient'} />} />
        <Route path={`${ALL_DOCTORS}/:doctorId`} element={<UserPage user={'doctor'} />} />
        <Route
          path={`${ADMIN_PATIENT}/:patientId`}
          element={<UserConnections user={'patient'} />}
        />
        <Route path={`${ADMIN_DOCTOR}/:doctorId`} element={<UserConnections user={'doctor'} />} />
        <Route path={ADMIN_CREATE_PATIENT} element={<AdminNewPatient />} />
        <Route path={ADMIN_CREATE_DOCTOR} element={<AdminNewDoctor />} />

        <Route path={PRIVACY_POLICY} element={<PrivacyPolicyScreen />} />
        <Route path={DELETE_ACCOUNT} element={<DeleteAccount />} />
      </Routes>
    </SettingsProvider>
  );
}

export default App;
