import { PropsWithChildren, ReactNode } from 'react';
import { Header } from '../header/Header';
import { Navbar } from '../navbar/Navbar';
import styles from './AuthorizedFrame.module.css';
import { Loader } from '../../components/loader/loader';

type Props = {
  right?: ReactNode;
  loading?: boolean;
  headerText?: string;
};

export const AuthroizedFrame = ({
  children,
  right,
  loading,
  headerText
}: PropsWithChildren<Props>) => {
  return (
    <div className={styles.frame}>
      <Navbar />
      <div className={styles.right}>
        <Header right={right} headerText={headerText} />
        {loading ? <Loader loading={loading} /> : <div className={styles.content}>{children}</div>}
      </div>
    </div>
  );
};
