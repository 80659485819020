import { FieldErrors, useForm } from 'react-hook-form';
import { ChangePasswordForm } from '../../../shared/types/changePassword';
import { useToaster } from '../../../shared/hooks/useToaster';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useAxios from 'axios-hooks';
import { LOCAL_STORAGE_KEYS } from '../../../shared/types/storageKeys';
import { useNavigate } from 'react-router-dom';
import { useRole } from '../../../shared/hooks/useRole';

export const useChangePassword = () => {
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const navigate = useNavigate();
  const { showErrorToast, showSuccessToast } = useToaster();
  const { AuthChangePassword } = useRole();

  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .required('Nowe hasło - pole wymagane')
      .min(8, 'Hasło musi mieć minimum 8 znaków')
      .max(32, 'Hasło może mieć maksymalnie 32 znaki')
      .matches(/^(?=.*[A-Z]).{8,32}/, 'Hasło musi zawierać co najmniej jedną wielką literę')
      .matches(/^(?=.*[a-z]).{8,32}$/, 'Hasło musi zawierać co najmniej jedną małą literę')
      .matches(/^(?=.*\d).{8,32}$/, 'Hasło musi zawierać co najmniej jedną cyfrę')
      .matches(/^(?=.*\W).{8,32}$/, 'Hasło musi zawierać co najmniej jeden znak specjalny'),
    repeatNewPassword: yup.string().required('Powtórz hasło - pole wymagane'),
    oldPassword: yup.string().required('Stare hasło - pole wymagane')
  });

  const [, changePassword] = useAxios<any, Omit<ChangePasswordForm, 'repeatNewPassword'>>(
    AuthChangePassword,
    {
      manual: true
    }
  );

  const { control, handleSubmit, reset } = useForm<ChangePasswordForm>({
    defaultValues: {
      newPassword: '',
      repeatNewPassword: '',
      oldPassword: ''
    },
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data: ChangePasswordForm) => {
    const { newPassword, oldPassword, repeatNewPassword } = data;
    try {
      if (newPassword !== repeatNewPassword) {
        showErrorToast('Hasła nie są takie same');
        return;
      }

      const response = await changePassword({
        data: {
          newPassword,
          oldPassword
        },
        headers: {
          accessToken
        }
      });

      const { status } = response;

      if (status === 200) {
        showSuccessToast('Hasło zostało zmienione');
        reset();
      } else {
        showErrorToast('Nie udało się zmienić hasła');
      }
    } catch (err) {
      showErrorToast('Nie udało się zmienić hasła');
    }
  };

  const onErrors = (error: FieldErrors<ChangePasswordForm>) => {
    const errorMessage = Object.values(error)[0].message;
    if (errorMessage) {
      showErrorToast(errorMessage);
    }
  };

  const onSubmitPress = handleSubmit(onSubmit, onErrors);

  return {
    onSubmitPress,
    control
  };
};
