import { useForm, FieldErrors } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  LOGIN,
  RESET_PASSWORD_CHANGE,
  RESET_PASSWORD_INIT,
  RESET_PASSWORD_VERIFY
} from '../../router/routes';
import useAxios, { RefetchFunction } from 'axios-hooks';
import { ApiRoutes } from '../../shared/types/api';
import { useToaster } from '../../shared/hooks/useToaster';
import { SESSION_STORAGE_KEYS } from '../../shared/types/storageKeys';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Roles } from '../../shared/types/roles';
import { useRole } from '../../shared/hooks/useRole';

type ResetPasswordInitForm = {
  phone: string;
};

type InitResetResponseType = {
  forgotToken: string;
};

const useResetPasswordInit = () => {
  const { changeRole, switchRole, role, resetSwitchText, resetText } = useRole();
  const { showErrorToast } = useToaster();
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm<ResetPasswordInitForm>({
    defaultValues: {
      phone: ''
    },
    resolver: yupResolver(
      yup.object().shape({
        phone: yup
          .string()
          .required('Numer telefonu - pole wymagane')
          .matches(/^\d{9}$/, 'Numer telefonu musi zawierać 9 cyfr')
      })
    )
  });

  const [, initResetDoctor] = useAxios<InitResetResponseType, ResetPasswordInitForm>(
    ApiRoutes.ResetPassword.Init,
    { manual: true }
  );

  const [, initResetAdmin] = useAxios<InitResetResponseType, ResetPasswordInitForm>(
    ApiRoutes.ResetPasswordAdmin.Init,
    { manual: true }
  );

  const handleResetPasswordResponse = async (
    login: RefetchFunction<ResetPasswordInitForm, InitResetResponseType>,
    data: ResetPasswordInitForm,
    role: Roles
  ) => {
    try {
      const response = await login({ data });

      const { forgotToken } = response.data;

      if (!forgotToken) {
        showErrorToast('Weryfikacja nie powiodła się');
        return;
      }
      changeRole(role);
      sessionStorage.setItem(SESSION_STORAGE_KEYS.PHONE, data.phone);
      sessionStorage.setItem(SESSION_STORAGE_KEYS.FORGOT_TOKEN, forgotToken);
      navigate(RESET_PASSWORD_VERIFY);
    } catch (err) {
      throw new Error((err as AxiosError).message);
    }
  };

  const onSubmit = async ({ phone }: ResetPasswordInitForm) => {
    const data = {
      phone: `+48${phone}`
    };

    try {
      role === Roles.DOCTOR
        ? await handleResetPasswordResponse(initResetDoctor, data, Roles.DOCTOR)
        : await handleResetPasswordResponse(initResetAdmin, data, Roles.ADMIN);
    } catch (err) {
      const msg = (err as AxiosError).message;
      showErrorToast(msg);
    }
  };

  const onBack = () => {
    navigate(LOGIN);
  };

  const onError = (error: FieldErrors<ResetPasswordInitForm>) => {
    const errorMessage = Object.values(error)[0].message;
    if (errorMessage) {
      showErrorToast(errorMessage);
    }
  };

  return {
    onSubmit: handleSubmit(onSubmit, onError),
    onBack,
    register,
    resetSwitchText,
    resetText,
    switchRole
  };
};

type OTPData = {
  hotp_code: string;
};

const useResetPasswordVerify = () => {
  const navigate = useNavigate();
  const { AuthResetPasswordVerify, AuthResetPasswordInit } = useRole();
  const { showErrorToast, showInfoToast } = useToaster();
  const resetToken = sessionStorage.getItem(SESSION_STORAGE_KEYS.FORGOT_TOKEN);

  const [, resetPasswordVerify] = useAxios<InitResetResponseType, OTPData>(
    AuthResetPasswordVerify,
    {
      manual: true
    }
  );

  const [, resentOTP] = useAxios<InitResetResponseType, ResetPasswordInitForm>(
    AuthResetPasswordInit,
    { manual: true }
  );

  useEffect(() => {
    if (!resetToken) {
      showErrorToast('Brak tokenu uwierzytelniającego');
      navigate(RESET_PASSWORD_INIT);
    }
  }, [resetToken]);

  const { register, handleSubmit } = useForm<OTPData>({
    defaultValues: {
      hotp_code: ''
    },
    resolver: yupResolver(
      yup.object().shape({
        hotp_code: yup
          .string()
          .required('Kod weryfikacyjny - pole wymagane')
          .matches(/^\d{6}$/, 'Kod weryfikacyjny musi zawierać 6 cyfr')
      })
    )
  });

  const onResentOTP = async () => {
    try {
      const phone = sessionStorage.getItem(SESSION_STORAGE_KEYS.PHONE);

      if (!phone) {
        // Impossible case - I hope
        showErrorToast('Brak numeru telefonu. Spróbuj ponownie');
        navigate(RESET_PASSWORD_INIT);
        return;
      }

      const response = await resentOTP({
        data: {
          phone
        }
      });

      const { forgotToken: token } = response.data;

      if (!token) {
        showErrorToast('Nie mogliśmy wysłać numeru ponownie');
        return;
      }

      sessionStorage.setItem(SESSION_STORAGE_KEYS.FORGOT_TOKEN, token);
      showInfoToast('Kod weryfikacyjny wysłany ponownie');
    } catch (err) {
      showErrorToast('Nie mogliśmy wysłać tokenu !');
    }
  };

  const verify = async (data: OTPData) => {
    const forgotToken = sessionStorage.getItem(SESSION_STORAGE_KEYS.FORGOT_TOKEN);

    try {
      const response = await resetPasswordVerify({
        data,
        headers: {
          forgotToken
        }
      });

      const { forgotToken: token } = response.data;
      if (!token) {
        showErrorToast('Logowanie nie powiodło się');
        return;
      }

      sessionStorage.setItem(SESSION_STORAGE_KEYS.FORGOT_TOKEN, token);

      navigate(RESET_PASSWORD_CHANGE);
    } catch (err) {
      const msg = (err as AxiosError).message;
      showErrorToast(msg);
    }
  };

  const onError = (error: FieldErrors<OTPData>) => {
    const errorMessage = Object.values(error)[0].message;
    if (errorMessage) {
      showErrorToast(errorMessage);
    }
  };

  return {
    register,
    onResentOTP,
    onSubmit: handleSubmit(verify, onError)
  };
};

type ResetPasswordInputType = {
  newPassword: string;
  repeatPassword: string;
};

const useResetPasswordChange = () => {
  const { AuthResetPasswordReset } = useRole();
  const { showErrorToast, showSuccessToast } = useToaster();
  const navigate = useNavigate();

  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .required('Nowe hasło - pole wymagane')
      .min(8, 'Hasło musi mieć minimum 8 znaków')
      .max(32, 'Hasło może mieć maksymalnie 32 znaki')
      .matches(/^(?=.*[A-Z]).{8,32}/, 'Hasło musi zawierać co najmniej jedną wielką literę')
      .matches(/^(?=.*[a-z]).{8,32}$/, 'Hasło musi zawierać co najmniej jedną małą literę')
      .matches(/^(?=.*\d).{8,32}$/, 'Hasło musi zawierać co najmniej jedną cyfrę')
      .matches(/^(?=.*\W).{8,32}$/, 'Hasło musi zawierać co najmniej jeden znak specjalny'),
    repeatPassword: yup.string().required('Powtórz hasło - pole wymagane')
  });

  const { register, handleSubmit } = useForm<ResetPasswordInputType>({
    defaultValues: {
      newPassword: '',
      repeatPassword: ''
    },
    resolver: yupResolver(schema)
  });

  const [, resetPassword] = useAxios<any, { newPassword: string }>(AuthResetPasswordReset, {
    manual: true
  });

  const onSubmit = async ({ newPassword, repeatPassword }: ResetPasswordInputType) => {
    try {
      if (newPassword !== repeatPassword) {
        showErrorToast('Hasła nie są takie same');
        return;
      }

      const forgotToken = sessionStorage.getItem(SESSION_STORAGE_KEYS.FORGOT_TOKEN);

      if (!forgotToken) {
        showErrorToast('Brak tokenu uwierzytelniającego');
        navigate(RESET_PASSWORD_INIT);
        return;
      }

      const response = await resetPassword({
        data: {
          newPassword
        },
        headers: {
          forgotToken
        }
      });

      const { status } = response;

      if (status === 200) {
        showSuccessToast('Hasło zostało zmienione');
        sessionStorage.clear();
        navigate(LOGIN);
      } else {
        showErrorToast('Nie udało się zmienić hasła');
      }
    } catch (err) {
      showErrorToast('Nie udało się zmienić hasła');
    }
  };

  const onError = (error: FieldErrors<ResetPasswordInputType>) => {
    const messages = error.newPassword?.message || error.repeatPassword?.message;
    if (messages) {
      showErrorToast(messages);
    }
  };

  return {
    register,
    onSubmit: handleSubmit(onSubmit, onError)
  };
};

export const ResetPasswordService = {
  useResetPasswordInit,
  useResetPasswordVerify,
  useResetPasswordChange
};
