import { Dna } from 'react-loader-spinner';

export const Loader = ({ loading = false }: { loading?: boolean }) => {
  return (
    <Dna
      visible={loading}
      height="500"
      width="500"
      ariaLabel="dna-loading"
      wrapperClass="dna-wrapper"
    />
  );
};
