import { BackButton } from '../../design/components/buttons/BackButton/BackButton';
import { AuthroizedFrame } from '../../design/layout/authorizedFrame/AuthorizedFrame';
import { NEWTREATMENT, PATIENT, PILLS } from '../../router/routes';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton } from '../../design/components/buttons/PrimaryButton/PrimaryButton';
import styles from './TakenPills.module.scss';
import dayjs from 'dayjs';
import { Table } from '../../design/components/table/Table';
import { createColumnHelper, getCoreRowModel } from '@tanstack/react-table';
import { useTakenPills } from '../../features/takenPills/useTakenPills';
import {
  Preset,
  TakenPill,
  TimeOfDay,
  TimeOfDayMap
} from '../../features/takenPills/TakenPills.types';
import { Loader } from '../../design/components/loader/loader';
import { FaList, FaPlusCircle } from 'react-icons/fa';

const columnHelper = createColumnHelper<TakenPill>();

export const TakenPills = () => {
  const navigate = useNavigate();
  const { patientId } = useParams();
  const previousPage = `${PATIENT}/${patientId}`;
  if (!patientId) navigate(previousPage);

  const {
    newData,
    handleLastDay,
    handleLastMonth,
    handleLastWeek,
    selectedPreset,
    handleLast90Days,
    loading
  } = useTakenPills(patientId!);

  const handleNewTreatmentClick = () => navigate(`${PATIENT}/${patientId}${NEWTREATMENT}`);
  const handlePillsClick = () => navigate(`${PATIENT}/${patientId}${PILLS}`);

  return (
    <AuthroizedFrame headerText="Przyjęte leki" right={<BackButton destination={previousPage} />}>
      <div>
        <h1 className={styles.label}>Nawigacja</h1>
      </div>
      <div className={styles.new_button_wrapper}>
        <PrimaryButton
          title={'Utwórz terapię lekami'}
          onClick={handleNewTreatmentClick}
          icon={<FaPlusCircle />}
        />
        <PrimaryButton title={'Istniejące terapie'} onClick={handlePillsClick} icon={<FaList />} />
      </div>
      <br />
      <div>
        <h1 className={styles.label}>Filtrowanie</h1>
      </div>
      <div className={styles.options}>
        <PrimaryButton
          title={'Ostatni dzień'}
          onClick={handleLastDay}
          unselected={selectedPreset === Preset.LastDay}
        />
        <PrimaryButton
          title={'Ostatni tydzień'}
          onClick={handleLastWeek}
          unselected={selectedPreset === Preset.LastWeek}
        />
        <PrimaryButton
          title={'Ostatni miesiąc'}
          onClick={handleLastMonth}
          unselected={selectedPreset === Preset.LastMonth}
        />
        <PrimaryButton
          title={'Ostatnie 90 dni'}
          onClick={handleLast90Days}
          unselected={selectedPreset === Preset.Last90Days}
        />
      </div>
      <h1 className={styles.label}>Przyjęte leki:</h1>
      <div className={styles.result}>
        {loading && <Loader loading={loading} />}
        {!loading &&
          newData?.map((record) => {
            return (
              <div key={record.id} className={styles.record}>
                <span>{dayjs(record.taken_date).format('DD.MM.YYYY')}</span>
                <div>
                  {Object.entries(record.taken_by_time_of_day).map(([timeOfDay, timeData], idx) => (
                    <TakenPillsTable timeOfDay={timeOfDay} timeData={timeData} key={idx} />
                  ))}
                </div>
              </div>
            );
          })}
      </div>
    </AuthroizedFrame>
  );
};

const TakenPillsTable = ({ timeOfDay, timeData }: { timeOfDay: string; timeData: TakenPill[] }) => {
  const headerRenderer = (name: string) => (
    <div
      style={{
        textAlign: 'left',
        borderBottom: '1px solid #e0e0e0'
      }}>
      {name}
    </div>
  );

  const cellRenderer = (v: string) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}>
        {v}
      </div>
    );
  };

  const columns = [
    columnHelper.accessor('name', {
      header: () => headerRenderer('Nazwa'),
      cell: (info) => cellRenderer(info.getValue())
    }),
    columnHelper.accessor('taken', {
      header: () => headerRenderer('Przyjęto'),
      cell: ({ row }) => {
        const { taken, planned } = row.original;
        const takenPlanned =
          parseFloat(taken) === parseFloat(planned) ? (
            taken
          ) : (
            <>
              [<del style={{ marginRight: 10 }}>{planned}</del> <strong>{taken}</strong>]
            </>
          );

        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start'
            }}>
            {takenPlanned}
          </div>
        );
      }
    }),
    columnHelper.accessor('dose', {
      header: () => headerRenderer('Dawka'),
      cell: (info) => {
        return cellRenderer(`x ${parseFloat(info.getValue()).toFixed(1)}`);
      }
    })
  ];

  return (
    <div key={timeOfDay}>
      {timeData.length ? (
        <>
          <h3>{TimeOfDayMap[timeOfDay as TimeOfDay]}</h3>
          <div
            style={{
              padding: '0 20px'
            }}>
            <Table data={timeData} columns={columns} getCoreRowModel={getCoreRowModel()} />
          </div>
        </>
      ) : null}
    </div>
  );
};
