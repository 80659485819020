import { useNavigate } from 'react-router-dom';
import styles from './Patients.module.scss';
import { AuthroizedFrame } from '../../../design/layout/authorizedFrame/AuthorizedFrame';
import { PatientsList } from '../../../features/patients/components/PatientsList/PatientsList';
import { useAllPatients } from '../../../features/admin/useAllPatients';
import { Patient } from '../../../features/patients/types';
import { ADMIN_CREATE_PATIENT, ALL_PATIENTS } from '../../../router/routes';
import { PrimaryButton } from '../../../design/components/buttons/PrimaryButton/PrimaryButton';

export const AdminPatients = () => {
  const navigate = useNavigate();
  const { patients, loading, nextPage, prevPage, page } = useAllPatients();

  return (
    <AuthroizedFrame>
      <div className={styles.button_wrapper}>
        <PrimaryButton
          title={'Dodaj nowego pacjenta'}
          onClick={() => navigate(ADMIN_CREATE_PATIENT)}
        />
      </div>
      <div className={styles.patients_container}>
        <PatientsList
          patients={patients}
          loading={loading}
          nextPage={nextPage}
          prevPage={prevPage}
          page={page}
          doctorView={false}
          onPatientClick={(patient: Patient) => navigate(`${ALL_PATIENTS}/${patient.guid}`)}
        />
      </div>
    </AuthroizedFrame>
  );
};
