import { useState } from 'react';
import { ConfirmationModal } from '../../design/components/ConfirmationModal/ConfirmationModal';
import { AuthroizedFrame } from '../../design/layout/authorizedFrame/AuthorizedFrame';
import styles from './Treatment.module.scss';
import { PrimaryButton } from '../../design/components/buttons/PrimaryButton/PrimaryButton';
import { BackButton } from '../../design/components/buttons/BackButton/BackButton';
import { PATIENT, PILLS } from '../../router/routes';
import { useParams } from 'react-router-dom';
import PrescriptionsService from '../../features/prescriptions/Prescriptions.service';
import { removeDecimal } from '../../shared/utils/removeDecimal';
import { processPrescription } from '../../shared/utils/getPillsInterval';

export const TreatmentScreen = () => {
  const { patientId, treatmentId } = useParams();
  const prevPage = `${PATIENT}/${patientId}${PILLS}`;
  const { usePrescription, useDeletePrescription } = PrescriptionsService;
  const { data, loading } = usePrescription(patientId!, treatmentId!);
  const { onPress } = useDeletePrescription(patientId!, treatmentId!, prevPage);
  const [showModal, setShowModal] = useState(false);

  if (!loading && !data) return null;

  const onDelete = () => {
    onPress();
  };

  const parsedMorning = removeDecimal(data?.morning!);
  const parsedAfternoon = removeDecimal(data?.afternoon!);
  const parsedEvening = removeDecimal(data?.evening!);
  const interval = data ? processPrescription(data) : 'Brak danych';

  return (
    <AuthroizedFrame loading={loading} right={<BackButton destination={prevPage} />}>
      <div className={styles.card}>
        <div className={styles.row_container}>
          <span className={styles.name_title}>Nazwa leku:</span>
          <span className={styles.name_text}>{data?.drug_name}</span>
        </div>
        <div className={styles.row_container}>
          <span className={styles.title}>Interwał:</span>
          <span className={styles.text}>{interval}</span>
        </div>
        <div className={styles.row_container}>
          <span className={styles.title}>Dawka:</span>
          <span className={styles.text}>{data?.dose}</span>
        </div>
        <div className={styles.row_container}>
          <span className={styles.title}>Ilość dawek:</span>
          <span className={styles.text}>
            [{parsedMorning}-{parsedAfternoon}-{parsedEvening}]
          </span>
        </div>
        <div className={styles.comment_wrapper}>
          <p className={styles.title}>Komentarz:</p>
          <p className={styles.text}>{data?.comment}</p>
        </div>
        <div className={styles.button_wrapper}>
          <PrimaryButton
            title={'Usuń terapię'}
            onClick={() => {
              setShowModal(true);
            }}
            color="red"
          />
        </div>
      </div>

      {showModal && (
        <ConfirmationModal
          text={'Czy na pewno chcesz usunąć tę terapię?'}
          setShowModal={setShowModal}
          onConfirm={onDelete}
        />
      )}
    </AuthroizedFrame>
  );
};
