import { PageNavigator } from '../../../../design/components/PageNavigator/PageNavigator';
import { Loader } from '../../../../design/components/loader/loader';
import { Patient, PatientsResponse } from '../../types';
import { PatientCard } from '../PatientCard/PatientCard';
import styles from './PatientsList.module.scss';

type Props = {
  patients?: PatientsResponse;
  loading: boolean;
  nextPage: () => void;
  prevPage: () => void;
  page: number;
  doctorView?: boolean;
  onPatientClick?: (patient: Patient) => void;
};

export const PatientsList = ({
  patients,
  loading,
  prevPage,
  nextPage,
  page,
  doctorView = true,
  onPatientClick
}: Props) => {
  return (
    <div>
      <div className={styles.container}>
        <Loader loading={loading} />
        <div className={styles.list}>
          {patients?.result?.map((patient, index) => (
            <div key={index} className={styles.element}>
              <PatientCard
                patient={patient}
                doctorView={doctorView}
                onPatientClick={onPatientClick}
              />
            </div>
          ))}
        </div>
      </div>
      <PageNavigator prevPage={prevPage} nextPage={nextPage} page={page} />
    </div>
  );
};
