import styles from './LabelInput.module.scss';

type Props = {
  label: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  maxLength?: number;
};

export const TextViewLabelInput = ({ label, disabled, onChange, value, maxLength }: Props) => {
  const lowerLabel = label.toLowerCase();
  const currentLength = value ? value.length : 0;
  return (
    <div className={styles.text_view_wrapper}>
      <label className={styles.label} htmlFor={lowerLabel}>
        {label}
      </label>
      <div className={styles.text_area_container}>
        <textarea
          className={styles.textarea}
          onChange={onChange}
          value={value}
          name={lowerLabel}
          placeholder={label}
          cols={10}
          rows={5}
          maxLength={maxLength}
          disabled={disabled}></textarea>
        {maxLength && (
          <div className={styles.letterCounter}>
            {currentLength}/{maxLength}
          </div>
        )}
      </div>
    </div>
  );
};
