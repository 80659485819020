import { useParams } from 'react-router-dom';
import { AuthroizedFrame } from '../../design/layout/authorizedFrame/AuthorizedFrame';
import styles from './Measurement.module.scss';
import { LabelInput } from '../../design/components/LabelInput/LabelInput';
import { ShadowBox } from '../../design/components/shadowBox/ShadowBox';
import { TextViewLabelInput } from '../../design/components/LabelInput/TextViewLabelInput';
import { MEASUREMENTS, PATIENT } from '../../router/routes';
import { BackButton } from '../../design/components/buttons/BackButton/BackButton';
import { PatientsService } from '../../features/patients/Patients.service';

export const Measurement = () => {
  const { patientId } = useParams();
  const { measurementId } = useParams();

  const { usePatientMeasurement } = PatientsService;
  const { measurement, loading } = usePatientMeasurement(patientId ?? '', measurementId ?? '');

  return (
    <AuthroizedFrame
      loading={loading}
      headerText={'Podgląd pomiaru'}
      right={<BackButton destination={`${PATIENT}/${patientId}${MEASUREMENTS}`} />}>
      <div className={styles.box}>
        <ShadowBox
          styles={{
            padding: 15
          }}>
          <form className={styles.form}>
            <div className={styles.column}>
              <LabelInput
                label={'Prędkość'}
                type={'text'}
                disabled={true}
                value={`${measurement?.rotary_speed}`}
                unit="obr/min"
              />
              <LabelInput
                label={'Przepływ'}
                type={'text'}
                disabled={true}
                value={`${measurement?.flow}`}
                unit="l/min"
              />
              <LabelInput label={'WP'} type={'text'} disabled={true} value={`${measurement?.wp}`} />
              <LabelInput
                label={'Moc'}
                type={'text'}
                disabled={true}
                value={`${measurement?.power}`}
              />
              <LabelInput
                label={'INR'}
                type={'text'}
                disabled={true}
                value={`${measurement?.inr}`}
              />
              <LabelInput
                label={'INR'}
                type={'text'}
                disabled={true}
                value={`${measurement?.inr}`}
              />
              <LabelInput
                label={'Dawka leku'}
                type={'text'}
                disabled={true}
                value={`${measurement?.blood_thinning_drug_dose}`}
                unit="mg"
              />
              <LabelInput
                label={'Temperatura ciała'}
                type={'text'}
                disabled={true}
                value={`${measurement?.body_temperature}`}
                unit="°C"
              />
              <LabelInput
                label={'Masa ciała'}
                type={'text'}
                disabled={true}
                value={`${measurement?.body_mass}`}
                unit="kg"
              />
            </div>
            <div className={styles.column}>
              <LabelInput
                label={'Ciśnienie tętnicze'}
                type={'text'}
                disabled={true}
                value={`${measurement?.systolic_blood_pressure}/${measurement?.diastolic_blood_pressure}`}
                unit="mmHg / mmHg"
              />
              <LabelInput
                label={'Średnie ciśnienie tętnicze'}
                type={'text'}
                disabled={true}
                value={`${measurement?.average_blood_pressure.toFixed(2)}`}
                unit="mmHg"
              />
              <LabelInput
                label={'Tętno'}
                type={'text'}
                disabled={true}
                value={`${measurement?.pulse}`}
                unit="ud/min"
              />
              <LabelInput
                label={'Płyny przyjęte'}
                type={'text'}
                disabled={true}
                value={`${measurement?.fluids_taken}`}
                unit="L"
              />
              <LabelInput
                label={'Płyny wydalone'}
                type={'text'}
                disabled={true}
                value={`${measurement?.fluids_excreted}`}
                unit="L"
              />
              <LabelInput
                label={'Temperatura skóry okolicy przewodu zasilającego'}
                type={'text'}
                disabled={true}
                value={`${measurement?.skin_temp_near_wire}`}
                unit="°C"
              />
              <LabelInput
                label={'Samopoczucie'}
                type={'text'}
                disabled={true}
                value={`${measurement?.comfort}`}
              />
              <LabelInput
                label={'Dzienny dystans spacerów'}
                type={'text'}
                disabled={true}
                value={`${measurement?.walk_distance}`}
                unit="m"
              />
              <TextViewLabelInput label={'Uwagi'} disabled value={`${measurement?.comments}`} />
            </div>
          </form>
        </ShadowBox>
      </div>
    </AuthroizedFrame>
  );
};
