import { ModalBackground } from '../ModalBackground/ModalBackground';
import { PrimaryButton } from '../buttons/PrimaryButton/PrimaryButton';
import styles from './ConfirmationModal.module.scss';

interface ConfirmationModalProps {
  text: string;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
}

export const ConfirmationModal = ({ text, setShowModal, onConfirm }: ConfirmationModalProps) => {
  const handleCancel = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    setShowModal(false);
    onConfirm();
  };

  return (
    <ModalBackground>
      <p className={styles.modal_text}>{text}</p>
      <div className={styles.buttons_container}>
        <PrimaryButton title={'Anuluj'} onClick={handleCancel} />
        <PrimaryButton title={'Usuń'} onClick={handleConfirm} color="red" />
      </div>
    </ModalBackground>
  );
};
