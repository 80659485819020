import * as yup from 'yup';

export type CreateDrugForm = {
  name: string;
  power: {
    value: string;
  }[];
};

export type Drug = {
  id: string;
  name: string;
  power: string[];
};

export type CreateDrugPayload = Omit<Drug, 'id'>;

export const createDrugSchema = yup.object().shape({
  name: yup.string().required('Nazwa leku - pole wymagane'),
  power: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required('Moc - pole wymagane')
      })
    )
    .min(1, 'Moc - pole wymagane')
    .required('Moc - pole ymagane')
});

export const searchDrugSchema = yup.object().shape({
  searchTerm: yup.string().required('Nazwa leku - pole wymagane').min(3, 'Wprowadź minimum 3 znaki')
});

export type SearchForm = { searchTerm: string };

export type SearchDrugsResponse = { drugs: Drug[] };
