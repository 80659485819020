import { useParams } from 'react-router-dom';
import { AuthroizedFrame } from '../../design/layout/authorizedFrame/AuthorizedFrame';
import { IMAGE, PATIENT } from '../../router/routes';
import { BackButton } from '../../design/components/buttons/BackButton/BackButton';
import styles from './Images.module.scss';
import { PatientsService } from '../../features/patients/Patients.service';
import { useEffect } from 'react';
import { ImageCard } from '../../features/images/components/ImageCard/ImageCard';
import { PageNavigator } from '../../design/components/PageNavigator/PageNavigator';
import { Loader } from '../../design/components/loader/loader';

export const Images = () => {
  const { patientId } = useParams();

  const { usePatientImages } = PatientsService;
  const { result, loading, nextPage, prevPage, page } = usePatientImages(patientId ?? '');

  let images = result;

  useEffect(() => {
    images = result;
  }, [result]);

  return (
    <AuthroizedFrame
      loading={loading}
      right={<BackButton destination={`${PATIENT}/${patientId}`} />}>
      <div className={styles.wrapper}>
        <div className={styles.image_container}>
          {result &&
            result.map((image, index) => (
              <div key={index}>{image && <ImageCard image={image} />}</div>
            ))}
        </div>
      </div>
      <PageNavigator prevPage={prevPage} nextPage={nextPage} page={page} />
    </AuthroizedFrame>
  );
};
