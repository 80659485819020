import { Controller } from 'react-hook-form';
import { ShadowBox } from '../../design/components/shadowBox/ShadowBox';
import { AuthroizedFrame } from '../../design/layout/authorizedFrame/AuthorizedFrame';
import styles from './DrugManagement.module.scss';
import { LabelInput } from '../../design/components/LabelInput/LabelInput';
import { PrimaryButton } from '../../design/components/buttons/PrimaryButton/PrimaryButton';
import DrugService from '../../features/drugManagement/Drug.service';
import { FaExclamationTriangle, FaTrashAlt } from 'react-icons/fa';

export const DrugMenagement = () => {
  const { useCreateDrug, useSearchDrug, useDeleteDrug } = DrugService;
  const { control, onAppendPress, remove, fields, onSubmitPress } = useCreateDrug();
  const { control: searchControl, onSubmit: searchDrugs, result } = useSearchDrug();
  const { onRemoveDrug, isRemoved } = useDeleteDrug();

  return (
    <AuthroizedFrame>
      <div className={styles.page}>
        <ShadowBox>
          <div className={styles.main}>
            <div>
              <h1>Tworzenie leku</h1>
              <Controller
                name={'name'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <LabelInput
                    label={'Nazwa leku'}
                    onChange={onChange}
                    value={value}
                    type={'text'}
                  />
                )}
              />
              <br />
              <div className={styles.row}>
                <h2>Warianty leku:</h2>
                <div>
                  <PrimaryButton title={'Dodaj kolejny wariant mocy'} onClick={onAppendPress} />
                </div>
              </div>
              {fields.map((field, index) => (
                <Controller
                  key={field.id}
                  name={`power.${index}.value`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div className={styles.row_field_power}>
                      <LabelInput label={''} onChange={onChange} value={value} type={'string'} />
                      <FaTrashAlt size={30} color={'#f00'} onClick={() => remove(index)} />
                    </div>
                  )}
                />
              ))}
              <div className={styles.submit}>
                <PrimaryButton title={'Zatwierdź nowy lek'} onClick={onSubmitPress} />
              </div>
            </div>
            <div>
              <FaExclamationTriangle size={50} color="#f00" />
              <p>
                <strong>Uwaga:</strong> W dodawaniu leku masz dowolność definiowania wartości mocy.
                <div>
                  <strong>Przykładowo:</strong>
                </div>
                <ul>
                  <li>50 mg</li>
                  <li>50 μg</li>
                  <li>50 mg + 10 μg</li>
                </ul>
              </p>
            </div>
          </div>
        </ShadowBox>
        <br />
        <ShadowBox>
          <h1>Wyszukaj lek</h1>
          <div className={styles.searchRow}>
            <Controller
              name={'searchTerm'}
              control={searchControl}
              render={({ field: { onChange, value } }) => (
                <LabelInput label={'Nazwa leku'} onChange={onChange} value={value} type={'text'} />
              )}
            />
            <PrimaryButton title={'Szukaj'} onClick={searchDrugs} />
          </div>
          {result?.length > 0 && (
            <>
              <h2>Leki i warianty mocy:</h2>
              <div className={styles.resultGroup}>
                <div className={styles.searchResult}>
                  {result?.map((drug) =>
                    isRemoved(drug.id) ? null : (
                      <div key={drug.id}>
                        {drug.power?.map((power) => {
                          return (
                            <div key={`${drug.id}-${power}`}>
                              <strong>{drug.name}</strong> - {power}
                            </div>
                          );
                        })}
                      </div>
                    )
                  )}
                </div>
                <div className={styles.searchResult}>
                  {result?.map((drug) =>
                    isRemoved(drug.id) ? null : (
                      <div key={drug.id} className={styles.drugRemoveRow}>
                        <span>{drug.name}</span>
                        <FaTrashAlt color="#f00" size={20} onClick={() => onRemoveDrug(drug)} />
                      </div>
                    )
                  )}
                </div>
              </div>
            </>
          )}
        </ShadowBox>
      </div>
    </AuthroizedFrame>
  );
};
