import { ReactNode } from 'react';
import styles from './NavigationButton.module.scss';

export const NavigationButton = ({
  icon,
  size,
  onClick
}: {
  icon: ReactNode;
  size: number;
  onClick: () => void;
}) => {
  return (
    <button
      className={styles.navigationButton}
      style={{
        width: `${size}px`,
        height: `${size}px`
      }}
      onClick={onClick}
    >
      {icon}
    </button>
  );
};
