import { PrimaryButton } from '../buttons/PrimaryButton/PrimaryButton';
import styles from './PageNavigator.module.scss';

type Props = {
  prevPage: () => void;
  nextPage: () => void;
  page: number;
};

export const PageNavigator = ({ prevPage, nextPage, page }: Props) => (
  <div className={styles.navigationBar}>
    <PrimaryButton title={'Wstecz'} onClick={prevPage} />
    <div className={styles.page}>Strona: {page}</div>
    <PrimaryButton title={'Dalej'} onClick={nextPage} />
  </div>
);
