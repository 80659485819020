import { FaChevronRight } from 'react-icons/fa';
import styles from './ArrowButton.module.scss';

type Props = {
  size: number;
  color: string;
  onClick: () => void;
};

export const ArrowButton = ({ size, color, onClick }: Props) => (
  <button
    className={styles.button}
    style={{
      width: size + 20,
      height: size + 20,
      border: `2px solid ${color}`
    }}
    onClick={onClick}
  >
    <FaChevronRight size={size} color={color} />
  </button>
);
