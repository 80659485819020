import { AuthroizedFrame } from '../../../design/layout/authorizedFrame/AuthorizedFrame';
import { UserCard } from '../../../features/admin/components/UserCard/UserCard';
import { useSingleUser } from '../../../features/admin/useSingleUser';
import { BackButton } from '../../../design/components/buttons/BackButton/BackButton';
import { ALL_DOCTORS, ALL_PATIENTS } from '../../../router/routes';
import { useParams } from 'react-router-dom';
import styles from './UserConnections.module.scss';
import { ShadowBox } from '../../../design/components/shadowBox/ShadowBox';
import { PrimaryButton } from '../../../design/components/buttons/PrimaryButton/PrimaryButton';
import { ModalBackground } from '../../../design/components/ModalBackground/ModalBackground';
import { UserListRecord } from '../../../features/admin/components/UserListRecord/UserListRecord';
import { Doctor, Patient } from '../../../features/admin/types';
import { useConnections } from '../../../features/admin/useConnections';
import { useSearchUser } from '../../../features/admin/useSearchUser';
import { Controller } from 'react-hook-form';
import { LabelInput } from '../../../design/components/LabelInput/LabelInput';
import { useEffect } from 'react';
import { SearchElement } from '../../../design/components/SearchElement/SearchElement';

type Props = {
  user: 'doctor' | 'patient';
};

export const UserConnections = ({ user }: Props) => {
  const { user: userData, loading } = useSingleUser(user);
  const isDoctor = user === 'doctor';
  const {
    users,
    showModal,
    setShowModal,
    handleAddRelation,
    handleDeleteRelation,
    handleSetCoordinator
  } = useConnections(isDoctor);
  const { doctorId, patientId } = useParams();
  const { control: searchControl, onSubmit: searchUsers, result } = useSearchUser(isDoctor);
  const usersName = isDoctor ? 'Identyfikator pacjenta' : 'Imię i nazwisko';

  return (
    <>
      <AuthroizedFrame
        loading={loading}
        right={
          <BackButton
            destination={`${
              isDoctor ? `${ALL_DOCTORS}/${doctorId}` : `${ALL_PATIENTS}/${patientId}`
            }`}
          />
        }>
        <UserCard
          name={userData.name}
          phone={userData.phone}
          email={userData.email}
          isDoctor={isDoctor}></UserCard>

        <div className={styles.lists_container}>
          <div className={styles.list_wrapper}>
            <h3 className={styles.header}>{isDoctor ? 'Pacjenci' : 'Lekarze'}</h3>
            <ShadowBox padding="10px 5px">
              <div className={styles.list_header}>
                <h4>{usersName}</h4>
                <div className={styles.icon_labels}>
                  <h4>Koordynator</h4>
                  <h4>Odłącz</h4>
                </div>
              </div>
              <div className={styles.list_body}>
                {!isDoctor &&
                  (users as Doctor[])?.map((user, index) => (
                    <UserListRecord
                      key={index}
                      name={user.full_name}
                      as_coordinator={user.coordinator_flag!}
                      onSwitch={(as_coordinator) => handleSetCoordinator(as_coordinator, user.guid)}
                      onUnlink={() => handleDeleteRelation(user.guid)}
                    />
                  ))}
                {isDoctor &&
                  (users as Patient[])?.map((user, index) => (
                    <UserListRecord
                      key={index}
                      name={user.patient_id}
                      as_coordinator={user.coordinator_flag!}
                      onSwitch={(as_coordinator) => handleSetCoordinator(as_coordinator, user.guid)}
                      onUnlink={() => handleDeleteRelation(user.guid)}
                    />
                  ))}
              </div>
              <div className={styles.button_wrapper}>
                <PrimaryButton
                  title={'Przypisz nowego pacjenta'}
                  onClick={() => {
                    setShowModal(true);
                  }}
                />
              </div>
            </ShadowBox>
          </div>
        </div>
      </AuthroizedFrame>
      {showModal && (
        <ModalBackground>
          <h1>Wybierz {isDoctor ? 'pacjenta' : 'lekarza'}</h1>
          <div className={styles.searchRow}>
            <Controller
              name={'searchTerm'}
              control={searchControl}
              render={({ field: { onChange, value } }) => (
                <LabelInput label={usersName} onChange={onChange} value={value} type={'text'} />
              )}
            />
            <PrimaryButton title={'Szukaj'} onClick={searchUsers} />
          </div>
          <div className={styles.searchResult}>
            {!isDoctor &&
              (result as Doctor[])?.map((user) => (
                <SearchElement
                  key={user.guid}
                  userName={user.full_name}
                  onClick={() => {
                    handleAddRelation(user.guid);
                  }}
                />
              ))}
            {isDoctor &&
              (result as Patient[])?.map((user) => (
                <SearchElement
                  key={user.guid}
                  userName={user.patient_id}
                  onClick={() => {
                    handleAddRelation(user.guid);
                  }}
                />
              ))}
          </div>
          <PrimaryButton
            title={'Anuluj'}
            onClick={() => {
              setShowModal(false);
            }}
            color="red"
            marginTop={20}
          />
        </ModalBackground>
      )}
    </>
  );
};
