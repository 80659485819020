import { blue } from '../../../styles/colors';
import styles from './PrimaryButton.module.scss';

type Props = {
  title: string;
  marginTop?: number;
  onClick: () => void;
  unselected?: boolean;
  disabled?: boolean;
  color?: string;
  height?: number;
  icon?: React.ReactNode;
};

export const PrimaryButton = ({
  title,
  marginTop,
  onClick,
  unselected,
  disabled,
  color,
  height,
  icon
}: Props) => (
  <button
    disabled={disabled}
    className={styles.primaryButton}
    style={{
      backgroundColor: color ?? (unselected ? '#fff' : blue),
      marginTop: marginTop,
      borderColor: color ?? blue,
      color: unselected ? blue : '#fff',
      height: height ?? '50px'
    }}
    onClick={onClick}>
    {icon ? (
      <div className={styles.buttonContent}>
        {icon} <p>{title}</p>
      </div>
    ) : (
      title
    )}
  </button>
);
