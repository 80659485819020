import { makeAutoObservable } from 'mobx';
import { ChartOption } from '../../assets/types';
import { IRootStore } from './RootStore';

export class ChartStore {
  rootStore: IRootStore;
  chartOptions: ChartOption[] = [];
  checkboxDisabledList: ChartOption[] = [];
  leftOptions: ChartOption[] = [];
  rightOptions: ChartOption[] = [];

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  addChartOption(chartOption: ChartOption) {
    this.chartOptions.push(chartOption);
  }

  removeChartOption(chartOption: ChartOption) {
    this.chartOptions = this.chartOptions.filter((current) => current !== chartOption);
  }
}
