import useAxios from 'axios-hooks';
import { useToaster } from '../../shared/hooks/useToaster';
import { LOCAL_STORAGE_KEYS } from '../../shared/types/storageKeys';
import { PatientsResponse } from '../patients/types';
import { usePagination } from '../../shared/hooks/usePagination';
import { ApiRoutes } from '../../shared/types/api';
import { useEffect } from 'react';

export const useAllPatients = () => {
  const { showErrorToast } = useToaster();
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const { page, nextPage, prevPage, setTotalPages } = usePagination();
  const [{ data, loading, error }, getAllPatients] = useAxios<PatientsResponse>(
    {
      method: ApiRoutes.AdminPatients.method,
      url: `${ApiRoutes.AdminPatients.url}?page_offset=${page}&page_size=10`,
      headers: {
        accessToken
      }
    },
    { manual: true }
  );

  useEffect(() => {
    if (data) {
      setTotalPages(data.pageCount || 1);
    }
  }, [data]);

  useEffect(() => {
    try {
      getAllPatients();
    } catch (error) {
      showErrorToast('Nie udało się pobrać pacjentów');
    }
  }, [page]);

  useEffect(() => {
    if (error) {
      showErrorToast('Nie udało się pobrać pacjentów');
      console.error(error);
    }
  }, [error]);

  return {
    patients: data,
    loading,
    error,
    nextPage,
    prevPage,
    page
  };
};
