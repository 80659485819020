import { VerificationInput } from '../../../design/components/VerificationInput/VerificationInput';
import { CountdownButton } from '../../../design/components/buttons/CountdownButton/CountdownButton';
import { PrimaryButton } from '../../../design/components/buttons/PrimaryButton/PrimaryButton';
import { HeartLinkLogo } from '../../../design/components/heartLinkLogo/HeartLinkLogo';
import { ShadowBox } from '../../../design/components/shadowBox/ShadowBox';
import { CenteredPageFrame } from '../../../design/layout/centeredPageFrame/CenteredPageFrame';
import { ResetPasswordService } from '../../../features/resetPassword/ResetPassword.service';
import styles from './Verification.module.scss';

export const ResetPasswordVerification = () => {
  const { useResetPasswordVerify } = ResetPasswordService;
  const { register, onSubmit, onResentOTP } = useResetPasswordVerify();

  return (
    <CenteredPageFrame>
      <HeartLinkLogo />
      <div className={styles.box}>
        <ShadowBox>
          <h1 className={styles.text}>Na twój numer telefonu został wysłany kod weryfikacyjny</h1>
          <VerificationInput register={register} name={'hotp_code'} />
          <div className={styles.button_container}>
            <div className={styles.countdown_wrapper}>
              <CountdownButton title={'Wyślij ponownie'} seconds={30} onClick={onResentOTP} />
            </div>
            <div className={styles.button_wrapper}>
              <PrimaryButton title={'Potwierdź'} marginTop={40} onClick={onSubmit} />
            </div>
          </div>
        </ShadowBox>
      </div>
    </CenteredPageFrame>
  );
};
