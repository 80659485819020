import { useParams } from 'react-router-dom';
import { ApiRoutes } from '../../shared/types/api';
import { useToaster } from '../../shared/hooks/useToaster';
import useAxios from 'axios-hooks';
import { Doctor, Patient } from './types';
import { LOCAL_STORAGE_KEYS } from '../../shared/types/storageKeys';
import { useEffect } from 'react';

export const useSingleUser = (user: 'doctor' | 'patient') => {
  const isDoctor = user === 'doctor';
  const fetchRoute = isDoctor ? ApiRoutes.AdminDoctor : ApiRoutes.AdminPatient;
  const { patientId, doctorId } = useParams();
  const id = isDoctor ? doctorId : patientId;
  if (!id) throw new Error('No id provided');

  const { showErrorToast } = useToaster();
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const [{ data, loading, error }] = useAxios<Patient | Doctor>({
    method: fetchRoute.method,
    url: fetchRoute.url(id),
    headers: {
      accessToken
    }
  });

  useEffect(() => {
    if (error) {
      showErrorToast('Nie udało się pobrać pacjentów');
      console.error(error);
    }
  }, [error]);

  return {
    user: {
      id: isDoctor ? (data as Doctor)?.guid : (data as Patient)?.guid,
      name: isDoctor ? (data as Doctor)?.full_name : (data as Patient)?.patient_id,
      email: isDoctor ? (data as Doctor)?.email : (data as Patient)?.email,
      phone: isDoctor ? (data as Doctor)?.phone : (data as Patient)?.phone
    },
    loading,
    error
  };
};
