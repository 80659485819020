import { AuthroizedFrame } from '../../design/layout/authorizedFrame/AuthorizedFrame';
import { IMAGES, PATIENT } from '../../router/routes';
import { ShadowBox } from '../../design/components/shadowBox/ShadowBox';
import styles from './Image.module.scss';
import { BackButton } from '../../design/components/buttons/BackButton/BackButton';
import { useLocation, useParams } from 'react-router-dom';
import { PatientsService } from '../../features/patients/Patients.service';
import { Loader } from '../../design/components/loader/loader';
import { useEffect, useState } from 'react';

export const Image = () => {
  const { patientId, imageId } = useParams();

  const { usePatientImage } = PatientsService;
  const { image, loading } = usePatientImage(imageId ?? '');

  const location = useLocation();

  const [dateTime, setDateTime] = useState('');

  useEffect(() => {
    const { takenDate, takenHour } = location.state || {};
    setDateTime(`${takenDate} ${takenHour}`);
  }, [location]);

  return (
    <AuthroizedFrame
      loading={loading}
      right={<BackButton destination={`${PATIENT}/${patientId}${IMAGES}`} />}>
      <div className={styles.container}>
        {image && (
          <ShadowBox>
            <img className={styles.image} src={image} alt={`Zdjęcie`} />
            <p className={styles.date}>{dateTime}</p>
          </ShadowBox>
        )}
      </div>
    </AuthroizedFrame>
  );
};
