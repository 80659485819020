import { AuthroizedFrame } from '../design/layout/authorizedFrame/AuthorizedFrame';

export const Notifications = () => {
  return (
    <AuthroizedFrame>
      <div>
        <h1>Notifications</h1>
      </div>
    </AuthroizedFrame>
  );
};
