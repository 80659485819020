import { BackButton } from '../../design/components/buttons/BackButton/BackButton';
import { AuthroizedFrame } from '../../design/layout/authorizedFrame/AuthorizedFrame';
import { NEWTREATMENT, PATIENT, TAKEN_PILLS } from '../../router/routes';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton } from '../../design/components/buttons/PrimaryButton/PrimaryButton';
import styles from './Pills.module.scss';
import { PrescriptionCard } from '../../design/components/TreatmentCard/PrescriptionCard';
import PrescriptionsService from '../../features/prescriptions/Prescriptions.service';
import { FaPills, FaPlusCircle } from 'react-icons/fa';

export const Pills = () => {
  const navigate = useNavigate();
  const { patientId } = useParams();
  const previousPage = `${PATIENT}/${patientId}`;
  if (!patientId) navigate(previousPage);

  const { usePatientPrescriptions } = PrescriptionsService;
  const { loading, data } = usePatientPrescriptions(patientId!);

  const handleNewTreatmentClick = () => navigate(`${PATIENT}/${patientId}${NEWTREATMENT}`);
  const handleTakenPillsClick = () => navigate(`${PATIENT}/${patientId}${TAKEN_PILLS}`);

  return (
    <AuthroizedFrame loading={loading} right={<BackButton destination={previousPage} />}>
      <h1 className={styles.label}>Nawigacja:</h1>
      <div className={styles.new_button_wrapper}>
        <PrimaryButton
          title={'Utwórz terapię lekami'}
          onClick={handleNewTreatmentClick}
          icon={<FaPlusCircle />}
        />
        <PrimaryButton title={'Przyjęte leki'} onClick={handleTakenPillsClick} icon={<FaPills />} />
      </div>
      <h1 className={styles.label}>Istniejące terapie:</h1>
      <div className={styles.card_container}>
        {data?.map((prescription) => (
          <PrescriptionCard key={prescription.id} prescription={prescription} />
        ))}
      </div>
    </AuthroizedFrame>
  );
};
