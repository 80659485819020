import { FaUnlink } from 'react-icons/fa';
import { ShadowBox } from '../../../../design/components/shadowBox/ShadowBox';
import styles from './UserListRecord.module.scss';
import Switch from 'react-switch';

type Props = {
  name: string;
  as_coordinator: boolean;
  onSwitch: (isUpgrade: boolean) => void;
  onUnlink: () => void;
};

export const UserListRecord = ({ name, as_coordinator, onSwitch, onUnlink }: Props) => {
  return (
    <div className={styles.record_wrapper}>
      <ShadowBox padding="10px 5px" minHeight="10px">
        <div className={styles.record}>
          {name}
          <div className={styles.icons}>
            <Switch
              checked={as_coordinator}
              onChange={() => {
                onSwitch(!as_coordinator);
              }}
            />
            <div className={styles.unlink} onClick={() => onUnlink()}>
              <FaUnlink size={25} />
            </div>
          </div>
        </div>
      </ShadowBox>
    </div>
  );
};
