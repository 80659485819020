import styles from './Tooltip.module.scss';
import dayjs from 'dayjs';

interface TooltipProps {
  chartOption: string;
  value: number;
  unit: string;
  date: Date;
  visible: boolean;
}

const Tooltip = ({ chartOption, value, unit, date, visible }: TooltipProps) => {
  if (!visible) {
    return null;
  }

  const dayjsDate = dayjs(date);
  const formattedDate = dayjsDate.format('DD.MM.YYYY');
  const formattedTime = dayjsDate.format('HH:mm');

  return (
    <div className={styles.tooltip_wrapper}>
      <div className={styles.tooltip_column}>
        <div>Parametr:</div>
        <div>Wartość:</div>
        <div>Data:</div>
        <div>Godzina:</div>
      </div>
      <div className={styles.tooltip_column}>
        <div>{chartOption}</div>
        <div>
          {value} {unit}
        </div>
        <div>{formattedDate}</div>
        <div>{formattedTime}</div>
      </div>
    </div>
  );
};

export default Tooltip;
