import useAxios from 'axios-hooks';
import { ApiRoutes } from '../../shared/types/api';
import { LOCAL_STORAGE_KEYS } from '../../shared/types/storageKeys';
import { useEffect, useState } from 'react';
import { useToaster } from '../../shared/hooks/useToaster';
import {
  PatientPrescription,
  PatientPrescriptionsResponse
} from '../../shared/types/prescriptions';
import { Prescription } from './types';
import { useNavigate } from 'react-router-dom';
import { PATIENT, PILLS } from '../../router/routes';
import { AxiosError } from 'axios';

const useDeletePrescription = (patientId: string, prescriptionId: string, destination?: string) => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const [deleted, setDeleted] = useState(false);
  const { showErrorToast, showSuccessToast } = useToaster();
  const [, deletePrescription] = useAxios<unknown>(
    {
      url: ApiRoutes.Prescriptions.Delete.url(patientId, prescriptionId),
      method: ApiRoutes.Prescriptions.Delete.method,
      headers: {
        accessToken
      }
    },
    { manual: true }
  );

  const onPress = () => {
    deletePrescription()
      .then(() => {
        showSuccessToast('Terapia lekami została usunięta.');
        setDeleted(true);
        if (destination) {
          navigate(destination);
        }
      })
      .catch(() => {
        showErrorToast('Wystąpił błąd podczas usuwania terapii lekami.');
      });
  };

  return { onPress, deleted };
};
const usePatientPrescriptions = (patientId: string) => {
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const { showErrorToast } = useToaster();
  const [{ data, loading }, getPrescriptions] = useAxios<PatientPrescriptionsResponse>(
    {
      url: ApiRoutes.Prescriptions.GetAll.url(patientId),
      method: ApiRoutes.Prescriptions.GetAll.method,
      headers: {
        accessToken
      }
    },
    { manual: true }
  );

  useEffect(() => {
    getPrescriptions().catch(() => {
      showErrorToast('Wystąpił błąd podczas pobierania terapii lekami.');
    });
  }, []);

  return {
    data,
    loading
  };
};
const usePrescription = (patientId: string, prescriptionId: string) => {
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const { showErrorToast, showSuccessToast } = useToaster();

  const [{ data, loading, error }] = useAxios<PatientPrescription>({
    url: ApiRoutes.Prescriptions.Get.url(patientId, prescriptionId),
    method: ApiRoutes.Prescriptions.Get.method,
    headers: {
      accessToken
    }
  });

  useEffect(() => {
    if (error) {
      showErrorToast('Nie udało się pobrać terapii lekami.');
    }
  }, [error]);

  return { data, loading };
};

const useCreatePrescription = (patientId: string) => {
  const navigate = useNavigate();
  const { showErrorToast, showSuccessToast } = useToaster();

  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const [{ data, loading, error }, createPrescription] = useAxios<string, Prescription>(
    {
      url: ApiRoutes.Prescriptions.Create.url(patientId),
      method: ApiRoutes.Prescriptions.Create.method,
      headers: {
        accessToken
      }
    },
    { manual: true }
  );

  let success = false;

  const onSubmit = async (prescription: Prescription) => {
    try {
      const response = await createPrescription({
        data: prescription
      });

      if (response.status === 201) {
        navigate(`${PATIENT}/${patientId}${PILLS}`);
        showSuccessToast('Terapia utworzona pomyślnie');
      }
    } catch (error) {
      const e = error as AxiosError;
      if (e.response?.status === 457) {
        showErrorToast('Jeden lek nie może być zapisany dwa razy do jednego dnia');
      } else {
        showErrorToast('Zapis nie powiódł się. Spróbuj ponownie później.');
      }
    }
  };

  return {
    loading,
    onSubmit,
    success
  };
};

export default {
  useDeletePrescription,
  usePatientPrescriptions,
  usePrescription,
  useCreatePrescription
};
