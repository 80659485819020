import useAxios from 'axios-hooks';
import { useToaster } from '../../shared/hooks/useToaster';
import { LOCAL_STORAGE_KEYS } from '../../shared/types/storageKeys';
import { ApiRoutes } from '../../shared/types/api';
import { useEffect, useState } from 'react';
import { Doctor, Patient } from './types';
import { useParams } from 'react-router-dom';
import { useManageRelations } from './useManageRelations';
import { useSetCoordination } from './useSetCoordination';

export const useConnections = (isDoctor: boolean) => {
  const { showErrorToast } = useToaster();
  const { onSubmit: manageRelations } = useManageRelations();
  const { onSubmit: setCoordination } = useSetCoordination();
  const [showModal, setShowModal] = useState(false);
  const { patientId, doctorId } = useParams();
  const id = isDoctor ? doctorId : patientId;
  if (!id) throw new Error('No id provided');
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const fetchRoute = isDoctor ? ApiRoutes.AdminDoctorsPatients : ApiRoutes.AdminPatientsDoctors;
  const [{ data, loading, error }, getRelatedUsers] = useAxios<Patient[] | Doctor[]>(
    {
      method: fetchRoute.method,
      url: fetchRoute.url(id),
      headers: {
        accessToken
      }
    },
    { manual: true }
  );

  useEffect(() => {
    try {
      getRelatedUsers();
    } catch (error) {
      showErrorToast('Nie udało się pobrać pacjentów');
    }
  }, []);

  useEffect(() => {
    if (error) {
      showErrorToast('Nie udało się pobrać pacjentów');
      console.error(error);
    }
  }, [error]);

  const handleManageRelation = async (isAddAction: boolean, userElementId: string) => {
    await manageRelations(
      isAddAction,
      isDoctor ? userElementId : patientId ?? '',
      isDoctor ? doctorId ?? '' : userElementId
    );

    await getRelatedUsers();
  };

  const handleAddRelation = async (userElementId: string) => {
    handleManageRelation(true, userElementId);
    setShowModal(false);
  };

  const handleDeleteRelation = async (userElementId: string) => {
    handleManageRelation(false, userElementId);
  };

  const handleSetCoordinator = async (isUpgrade: boolean, userElementId: string) => {
    await setCoordination(
      isUpgrade,
      isDoctor ? userElementId : patientId ?? '',
      isDoctor ? doctorId ?? '' : userElementId
    );
    await getRelatedUsers();
  };

  return {
    users: data,
    loading,
    error,
    showModal,
    setShowModal,
    handleAddRelation,
    handleDeleteRelation,
    handleSetCoordinator
  };
};
