import { PageNavigator } from '../../../../design/components/PageNavigator/PageNavigator';
import { Loader } from '../../../../design/components/loader/loader';
import { DoctorsResponse } from '../../types';
import { DoctorCard } from '../DoctorCard/DoctorCard';
import styles from './DoctorsList.module.scss';

type Props = {
  doctors?: DoctorsResponse;
  loading: boolean;
  nextPage: () => void;
  prevPage: () => void;
  page: number;
};

export const DoctorsList = ({ doctors, loading, prevPage, nextPage, page }: Props) => {
  return (
    <div>
      <div className={styles.container}>
        <Loader loading={loading} />
        <div className={styles.list}>
          {doctors?.result?.map((doctor, index) => (
            <div key={index} className={styles.element}>
              <DoctorCard doctor={doctor} />
            </div>
          ))}
        </div>
      </div>
      <PageNavigator prevPage={prevPage} nextPage={nextPage} page={page} />
    </div>
  );
};
