import styles from './HeartLinkLogo.module.scss';
import { Logo } from '../../../assets/Logo';

export const HeartLinkLogo = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <Logo height={30} color={'#60A5FA'} />
      </div>
      <h1 className={styles.label}>HeartLink</h1>
    </div>
  );
};
