import { useEffect, useState } from 'react';
import { TertiaryButton } from '../TertiaryButtom/TertiaryButton';

type CountdownButtonProps = {
  title: string;
  seconds: number;
  onClick: () => void;
};

export const CountdownButton = ({ title, seconds, onClick }: CountdownButtonProps) => {
  const [countdown, setCountdown] = useState(seconds);
  const [disabled, setDisabled] = useState(seconds > 0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (countdown === 0) {
        setDisabled(false);
        clearInterval(timer);
        return;
      }
      setCountdown(countdown - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [countdown]);

  const onButtonPress = () => {
    if (!disabled) {
      setCountdown(seconds);
      setDisabled(true);
      onClick();
    }
  };

  return (
    <TertiaryButton
      title={title}
      countdown={countdown}
      disabled={disabled}
      onClick={onButtonPress}
    />
  );
};
