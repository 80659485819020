import { useNavigate, useParams } from 'react-router-dom';
import { ApiRoutes } from '../../shared/types/api';
import { useToaster } from '../../shared/hooks/useToaster';
import useAxios from 'axios-hooks';
import { Doctor, Patient } from './types';
import { LOCAL_STORAGE_KEYS } from '../../shared/types/storageKeys';
import { useEffect } from 'react';
import { ALL_DOCTORS, ALL_PATIENTS } from '../../router/routes';

export const useDeleteUser = (user: 'doctor' | 'patient') => {
  const isDoctor = user === 'doctor';
  const { patientId, doctorId } = useParams();
  const navigate = useNavigate();
  const { showErrorToast, showSuccessToast } = useToaster();
  const id = isDoctor ? doctorId : patientId;
  if (!id) throw new Error('No id provided');
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);

  const settings = {
    url: isDoctor ? ApiRoutes.AdminDoctorDelete.url(id) : ApiRoutes.AdminPatientDelete.url(id),
    method: ApiRoutes.AdminDoctorDelete.method, // DELETE anyway
    headers: {
      accessToken
    }
  };

  const [{ loading, error }, deleteUser] = useAxios<Patient | Doctor>(settings, { manual: true });

  useEffect(() => {
    if (error) {
      showErrorToast('Nie udało się usunąć użytkownika');
      console.error(error);
    }
  }, [error]);

  const onPress = async () => {
    if (confirm('Czy na pewno chcesz usunąć tego użytkownika?')) {
      try {
        await deleteUser();
        showSuccessToast('Użytkownik został usunięty');
        navigate(isDoctor ? ALL_DOCTORS : ALL_PATIENTS);
      } catch {
        showErrorToast('Nie udało się usunąć użytkownika');
      }
    }
  };

  return {
    loading,
    error,
    onPress
  };
};
