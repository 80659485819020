import { useNavigate } from 'react-router-dom';
import styles from './BackButton.module.scss';
import { PrimaryButton } from '../PrimaryButton/PrimaryButton';

type Props = {
  destination: string;
};

export const BackButton = ({ destination }: Props) => {
  const navigate = useNavigate();
  return (
    <div className={styles.wrapper}>
      <PrimaryButton title={'Powrót'} onClick={() => navigate(destination)} />
    </div>
  );
};
