import { tertiaryButtonColor } from '../../../styles/colors';
import styles from './TertiaryButton.module.scss';

type Props = {
  title: string;
  onClick: () => void;
  countdown?: number;
  disabled?: boolean;
};

export const TertiaryButton = ({ title, onClick, countdown }: Props) => (
  <button
    className={styles.tertiaryButton}
    style={{
      color: tertiaryButtonColor
    }}
    onClick={onClick}
  >
    {countdown ? `${title} (${countdown} sek)` : title}
  </button>
);
