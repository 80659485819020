import { LabelFormInput } from '../../../design/components/LabelInput/LabelInput';
import { PrimaryButton } from '../../../design/components/buttons/PrimaryButton/PrimaryButton';
import { HeartLinkLogo } from '../../../design/components/heartLinkLogo/HeartLinkLogo';
import { ShadowBox } from '../../../design/components/shadowBox/ShadowBox';
import { CenteredPageFrame } from '../../../design/layout/centeredPageFrame/CenteredPageFrame';
import { ResetPasswordService } from '../../../features/resetPassword/ResetPassword.service';
import styles from './Change.module.scss';

export const ResetPasswordChange = () => {
  const { useResetPasswordChange } = ResetPasswordService;
  const { register, onSubmit } = useResetPasswordChange();

  return (
    <CenteredPageFrame>
      <HeartLinkLogo />
      <div className={styles.box}>
        <ShadowBox>
          <h1 className={styles.header}>Wprowadź nowe hasło</h1>
          <div className={styles.form}>
            <LabelFormInput
              label={'Nowe hasło'}
              type={'password'}
              register={register}
              name={'newPassword'}
            />
            <LabelFormInput
              label={'Powtórz hasło'}
              type={'password'}
              register={register}
              name={'repeatPassword'}
            />
            <div className={styles.button_wrapper}>
              <PrimaryButton title={'Zaloguj'} marginTop={35} onClick={onSubmit} />
            </div>
          </div>
        </ShadowBox>
      </div>
    </CenteredPageFrame>
  );
};
