import { useForm } from 'react-hook-form';
import { DrugUnit, IntervalButton, Parity, PillsParams } from '../../assets/types';
import { useToaster } from '../../shared/hooks/useToaster';
import { Prescription } from '../../features/prescriptions/types';
import PrescriptionsService from '../../features/prescriptions/Prescriptions.service';

export const useNewTreatment = (patientId: string) => {
  const { showErrorToast, showSuccessToast } = useToaster();
  const { useCreatePrescription } = PrescriptionsService;
  const { onSubmit } = useCreatePrescription(patientId!);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<PillsParams>({
    defaultValues: {
      intervalButton: IntervalButton.Daily,
      evenOdd: Parity.Even,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      drugName: '',
      unit: DrugUnit.MG,
      treatmentComments: 'Brak',
      selectedDrugId: ''
    },
    mode: 'onChange'
  });

  const onSave = (data: PillsParams) => {
    if (data.intervalButton == IntervalButton.ChosenDays) {
      const days = [
        data.monday,
        data.tuesday,
        data.wednesday,
        data.thursday,
        data.friday,
        data.saturday,
        data.sunday
      ];
      const anyDaysChosen = days.some((day) => day === true);
      if (!anyDaysChosen) {
        showErrorToast('Zaznacz co najmniej jeden dzień');
        return;
      }
    }

    if (!data.selectedDrugId) {
      showErrorToast('Wyszukaj i wybierz lek');
      return;
    }

    const dosageSum =
      (data.morningDosage ?? 0) + (data.noonDosage ?? 0) + (data.eveningDosage ?? 0);
    if (dosageSum == 0) {
      showErrorToast('Podaj co najmniej jedną porę przyjmowania leku');
      return;
    }

    const lastIndex = data.selectedDrugId.lastIndexOf('-');
    const drugId = data.selectedDrugId.substring(0, lastIndex);
    const restOfString = data.selectedDrugId.substring(lastIndex + 1);
    const dosageUnitArray = restOfString.split(' ');
    const dosage = parseInt(dosageUnitArray[0]);
    const unit = dosageUnitArray[1];

    const newPrescription: Prescription = {
      monday: data.intervalButton == IntervalButton.ChosenDays ? data.monday : false,
      tuesday: data.intervalButton == IntervalButton.ChosenDays ? data.tuesday : false,
      wednesday: data.intervalButton == IntervalButton.ChosenDays ? data.wednesday : false,
      thursday: data.intervalButton == IntervalButton.ChosenDays ? data.thursday : false,
      friday: data.intervalButton == IntervalButton.ChosenDays ? data.friday : false,
      saturday: data.intervalButton == IntervalButton.ChosenDays ? data.saturday : false,
      sunday: data.intervalButton == IntervalButton.ChosenDays ? data.sunday : false,
      unit: unit,
      everyday: data.intervalButton == IntervalButton.Daily,
      odd_days: data.intervalButton == IntervalButton.EvenOdd ? data.evenOdd == Parity.Odd : false,
      even_days:
        data.intervalButton == IntervalButton.EvenOdd ? data.evenOdd == Parity.Even : false,
      dose: dosage,
      morning: data.morningDosage,
      afternoon: data.noonDosage,
      evening: data.eveningDosage,
      comment: data.treatmentComments,
      drug_id: drugId
    };

    onSubmit(newPrescription);
  };

  return { control, onSubmit: handleSubmit(onSave), errors, isValid };
};
