export type Measurement = {
  id: string;
  patient_id: string;
  measured_at: string;
  rotary_speed: number;
  flow: number;
  wp: number;
  power: number;
  inr: number;
  blood_thinning_drug_dose: number;
  body_temperature: number;
  body_mass: number;
  systolic_blood_pressure?: number;
  diastolic_blood_pressure?: number;
  skin_temp_near_wire?: number;
  pulse?: number;
  fluids_taken?: number;
  fluids_excreted?: number;
  walk_distance?: number;
  comfort: number;
  reasons?: string;
  comments?: string;
  doctor_id: string | null;
  average_blood_pressure: number;
  added_by_doctor: boolean;
  editable?: boolean;
};

export enum ChartOption {
  ROTARY_SPEED = 'Prędkość',
  FLOW = 'Przepływ',
  WP = 'WP',
  POWER = 'Moc',
  INR = 'INR',
  BLOOD_THINNING_DRUG_DOSE = 'Dawka leku',
  BODY_TEMPERATURE = 'Temperatura ciała',
  BODY_MASS = 'Masa ciała',
  SYSTOLIC_BLOOD_PRESSURE = 'Skurczowe ciśnienie tętnicze',
  DIASTOLIC_BLOOD_PRESSURE = 'Rozkurczowe ciśnienie tętnicze',
  AVERAGE_BLOOD_PRESSURE = 'Średnie ciśnienie tętnicze',
  PULSE = 'Tętno',
  FLUIDS_TAKEN = 'Płyny przyjęte',
  FLUIDS_EXCRETED = 'Płyny wydalone',
  COMFORT = 'Samopoczucie',
  WALK_DISTANCE = 'Dystans spacerów'
}

export enum ChartUnit {
  OBR_MIN = 'obr/min',
  L_MIN = 'l/min',
  W = 'W',
  INR = '',
  MG = 'mg',
  C = '°C',
  KG = 'kg',
  MMGH = 'mmHg',
  UD_MIN = 'ud/min',
  L = 'L',
  COMFORT = 'Samopoczucie',
  KM = 'km'
}

export type ChartParams = {
  rotary_speed: boolean;
  flow: boolean;
  wp: boolean;
  power: boolean;
  inr: boolean;
  blood_thinning_drug_dose: boolean;
  body_temperature: boolean;
  body_mass: boolean;
  systolic_blood_pressure: boolean;
  diastolic_blood_pressure: boolean;
  average_blood_pressure: boolean;
  pulse: boolean;
  fluids_taken: boolean;
  fluids_excreted: boolean;
  comfort: boolean;
  walk_distance: boolean;
};

export const chartOptionUnit: { [key in ChartOption]: ChartUnit } = {
  [ChartOption.ROTARY_SPEED]: ChartUnit.OBR_MIN,
  [ChartOption.FLOW]: ChartUnit.L_MIN,
  [ChartOption.WP]: ChartUnit.W,
  [ChartOption.POWER]: ChartUnit.W,
  [ChartOption.INR]: ChartUnit.INR,
  [ChartOption.BLOOD_THINNING_DRUG_DOSE]: ChartUnit.MG,
  [ChartOption.BODY_TEMPERATURE]: ChartUnit.C,
  [ChartOption.BODY_MASS]: ChartUnit.KG,
  [ChartOption.SYSTOLIC_BLOOD_PRESSURE]: ChartUnit.MMGH,
  [ChartOption.DIASTOLIC_BLOOD_PRESSURE]: ChartUnit.MMGH,
  [ChartOption.AVERAGE_BLOOD_PRESSURE]: ChartUnit.MMGH,
  [ChartOption.PULSE]: ChartUnit.UD_MIN,
  [ChartOption.FLUIDS_TAKEN]: ChartUnit.L,
  [ChartOption.FLUIDS_EXCRETED]: ChartUnit.L,
  [ChartOption.COMFORT]: ChartUnit.COMFORT,
  [ChartOption.WALK_DISTANCE]: ChartUnit.KM
};

export const maxValues: { [key in ChartUnit]: number } = {
  [ChartUnit.OBR_MIN]: 6000,
  [ChartUnit.L_MIN]: 6,
  [ChartUnit.W]: 20,
  [ChartUnit.INR]: 10,
  [ChartUnit.MG]: 100,
  [ChartUnit.C]: 42,
  [ChartUnit.KG]: 200,
  [ChartUnit.MMGH]: 300,
  [ChartUnit.UD_MIN]: 300,
  [ChartUnit.L]: 10,
  [ChartUnit.COMFORT]: 5,
  [ChartUnit.KM]: 30
};

export type MeasurementsResponse = {
  pageCount: number;
  result: Measurement[];
  resultCount: number;
};

export enum MeasurementView {
  LIST = 'list',
  CHART = 'chart'
}
