import { useNavigate } from 'react-router-dom';
import { useToaster } from '../../shared/hooks/useToaster';
import { LOCAL_STORAGE_KEYS } from '../../shared/types/storageKeys';
import useAxios from 'axios-hooks';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ApiRoutes } from '../../shared/types/api';
import { FieldErrors, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { ALL_DOCTORS, ALL_PATIENTS } from '../../router/routes';
import { CreateDoctorInput } from './types';

export const useCreateDoctor = () => {
  const navigate = useNavigate();
  const { showErrorToast, showSuccessToast } = useToaster();
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const [, createDoctor] = useAxios<{ message: string }, CreateDoctorInput>(
    {
      ...ApiRoutes.AdminDoctorCreate,
      headers: {
        accessToken
      }
    },
    { manual: true }
  );

  const schema = yup.object().shape({
    phone: yup.string().required('Numer telefonu - pole wymagane'),
    email: yup.string().required('Email - pole wymagane').email('Niepoprawny adres email'),
    name: yup.string().required('Imię lekarza - pole wymagane'),
    surname: yup.string().required('Nazwisko lekarza - pole wymagane')
  });

  const { register, handleSubmit } = useForm<CreateDoctorInput>({
    defaultValues: {
      phone: '',
      email: '',
      name: '',
      surname: ''
    },
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data: CreateDoctorInput) => {
    try {
      await createDoctor({
        data: {
          ...data,
          phone: `+48${data.phone}`
        },
        headers: {
          accessToken
        }
      });
      showSuccessToast('Pomyślnie utworzono pacjenta');
      navigate(ALL_DOCTORS);
    } catch (error) {
      showErrorToast('Nie udało się utworzyć pacjenta');
      console.error(error);
    }
  };

  const onErrors = (errors: FieldErrors<CreateDoctorInput>) => {
    const msg = Object.values(errors)[0].message;
    if (msg) {
      showErrorToast(msg);
      console.error(errors);
    }
  };

  return {
    register,
    handleSubmit: handleSubmit(onSubmit, onErrors)
  };
};
