import { FaRegImage } from 'react-icons/fa';
import { ArrowButton } from '../../../../design/components/buttons/ArrowButton/ArrowButton';
import styles from './ImageCard.module.scss';
import { blue } from '../../../../design/styles/colors';
import { useNavigate, useParams } from 'react-router-dom';
import { IMAGE, PATIENT } from '../../../../router/routes';
import dayjs from 'dayjs';
import { Image } from '../../../../assets/types';

export const ImageCard = ({ image }: { image: Image }) => {
  const { patientId } = useParams();
  const { guid, taken_at } = image;
  const navigate = useNavigate();

  const takenDate = taken_at ? dayjs(taken_at).format('DD.MM.YYYY') : '';

  const takenHour = taken_at ? dayjs(taken_at).format('HH:mm') : '';

  return (
    <div className={styles.card}>
      <div>
        <FaRegImage color={blue} size={50} />
      </div>

      <div className={styles.middle}>
        <p className={styles.date}>
          {takenDate} {takenHour}
        </p>
      </div>

      <div className={styles.icon_button}>
        <ArrowButton
          size={20}
          color={blue}
          onClick={() => {
            navigate(`${PATIENT}/${patientId}${IMAGE}/${guid}`, {
              state: { takenDate, takenHour }
            });
          }}
        />
      </div>
    </div>
  );
};
