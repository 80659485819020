export type PillsParams = {
  intervalButton: IntervalButton;
  evenOdd: Parity;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  drugName: string;
  dosage: number;
  unit: DrugUnit;
  morningDosage: number;
  noonDosage: number;
  eveningDosage: number;
  treatmentComments: string;
  selectedDrugId: string;
};

export enum DrugUnit {
  MG = 'mg',
  G = 'g',
  ML = 'ml',
  PILLS = 'tabletki'
}

export enum IntervalButton {
  Daily,
  ChosenDays,
  EvenOdd
}

export enum Parity {
  Even = 'even',
  Odd = 'odd'
}

export type Treatment = {
  pillsName: string;
  morningDosage: number;
  noonDosage: number;
  eveningDosage: number;
  dosage: number;
  unit: string;
  interval: string;
  createdAt: Date;
  comment: string;
};
