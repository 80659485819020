import dayjs from 'dayjs';
import { useToaster } from '../../shared/hooks/useToaster';
import { FieldErrors, useForm } from 'react-hook-form';
import { LOCAL_STORAGE_KEYS } from '../../shared/types/storageKeys';
import useAxios from 'axios-hooks';
import { useEffect, useState } from 'react';
import { ApiRoutes } from '../../shared/types/api';
import {
  Preset,
  TakenDrugsResponse,
  TakenDrugsSearchForm,
  endDayPresets
} from './TakenPills.types';
import { transformData } from './TakenPills.func';

export const useTakenPills = (patientId: string) => {
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const { showErrorToast } = useToaster();
  const { handleSubmit, getValues, setValue } = useForm<TakenDrugsSearchForm>({
    defaultValues: {
      start_date: dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD')
    }
  });
  const [selectedPreset, setSelectedPreset] = useState<Preset>(Preset.LastWeek);

  const handlePreset = (preset: Preset) => {
    setSelectedPreset(preset);
    setValue('start_date', endDayPresets[preset]);
  };

  const [{ data, loading, error }, searchTakenDrugs] = useAxios<TakenDrugsResponse>({
    url: ApiRoutes.TakenDrugs.url(patientId),
    method: ApiRoutes.TakenDrugs.method,
    headers: {
      accessToken
    },
    params: {
      start_date: getValues('start_date'),
      end_date: getValues('end_date')
    }
  });

  useEffect(() => {
    if (error) {
      showErrorToast('Odczyt nie udał się. Spróbuj ponownie później.');
    }
  }, [error]);

  const onSearch = async (data: TakenDrugsSearchForm) => {
    try {
      const response = await searchTakenDrugs({
        params: data
      });
    } catch (err) {
      showErrorToast('Wystąpił błąd podczas wyszukiwania przyjętych leków.');
    }
  };

  const onErrors = (errors: FieldErrors<TakenDrugsSearchForm>) => {
    const msg = Object.values(errors)[0].message;
    if (msg) {
      showErrorToast(msg);
      console.error(errors);
    }
  };

  const onSubmit = handleSubmit(onSearch, onErrors);

  return {
    onSearch,
    loading,
    data,
    onSubmit,
    newData: data ? transformData(data) : [],
    handleLastDay: () => handlePreset(Preset.LastDay),
    handleLastWeek: () => handlePreset(Preset.LastWeek),
    handleLastMonth: () => handlePreset(Preset.LastMonth),
    handleLast90Days: () => handlePreset(Preset.Last90Days),
    selectedPreset
  };
};
