import { ReactNode } from 'react';
import styles from './IconButton.module.scss';
import { blue, gray } from '../../../styles/colors';

export const IconButton = ({
  title,
  icon,
  onClick,
  disabled,
  backgroundColor = blue
}: {
  title: string;
  icon: ReactNode;
  onClick: () => void;
  disabled?: boolean;
  backgroundColor?: string;
}) => {
  return (
    <button
      className={styles.iconButton}
      style={{
        backgroundColor: disabled ? gray : backgroundColor
      }}
      onClick={!disabled ? onClick : undefined}>
      {icon}
      <p className={styles.subtext}>{title}</p>
    </button>
  );
};
