import styles from './Doctors.module.scss';
import { AuthroizedFrame } from '../../../design/layout/authorizedFrame/AuthorizedFrame';
import { useAllDoctors } from '../../../features/admin/useAllDoctors';
import { DoctorsList } from '../../../features/admin/components/DoctorsList/DoctorsList';
import { PrimaryButton } from '../../../design/components/buttons/PrimaryButton/PrimaryButton';
import { ADMIN_CREATE_DOCTOR } from '../../../router/routes';
import { useNavigate } from 'react-router-dom';

export const AdminDoctors = () => {
  const navigate = useNavigate();
  const { doctors, loading, nextPage, page, prevPage } = useAllDoctors();
  return (
    <AuthroizedFrame loading={loading}>
      <div className={styles.button_wrapper}>
        <PrimaryButton
          title={'Dodaj nowego lekarza'}
          onClick={() => navigate(ADMIN_CREATE_DOCTOR)}
        />
      </div>
      <div className={styles.patients_container}>
        <DoctorsList
          doctors={doctors}
          loading={false}
          nextPage={nextPage}
          prevPage={prevPage}
          page={page}
        />
      </div>
    </AuthroizedFrame>
  );
};
