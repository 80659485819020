import dayjs from 'dayjs';

export enum TimeOfDay {
  Morning = 'morning',
  Evening = 'evening',
  Afternoon = 'afternoon'
}

export const TimeOfDayMap = {
  [TimeOfDay.Morning]: 'Rano',
  [TimeOfDay.Evening]: 'Popołudnie',
  [TimeOfDay.Afternoon]: 'Wieczór'
};

export type TakenDrugsSearchForm = {
  start_date: string;
  end_date: string;
};

export type TakenDrugsResponse = {
  id: string;
  patient_id: string;
  taken: {
    dose: string;
    drug_id: string;
    name: string;
    planned: string;
    taken: string;
  }[];
  taken_date: string;
  time_of_day: TimeOfDay;
}[];

export type TakenPill = {
  dose: string;
  drug_id: string;
  name: string;
  planned: string;
  taken: string;
};

export type TransformedTakenDrug = {
  id: string;
  patient_id: string;
  taken_date: string;
  taken_by_time_of_day: {
    [key in keyof typeof TimeOfDay]: TakenPill[];
  };
};

export type TransformedTakenDrugs = TransformedTakenDrug[];

export enum Preset {
  LastDay = 'last_day',
  LastWeek = 'last_week',
  LastMonth = 'last_month',
  Last90Days = 'last_90_days'
}

export const endDayPresets = {
  [Preset.LastDay]: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
  [Preset.LastWeek]: dayjs().subtract(7, 'days').format('YYYY-MM-DD'),
  [Preset.LastMonth]: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
  [Preset.Last90Days]: dayjs().subtract(89, 'days').format('YYYY-MM-DD')
};
