import { FaRegUserCircle } from 'react-icons/fa';
import { ArrowButton } from '../../../../design/components/buttons/ArrowButton/ArrowButton';
import styles from './DoctorCard.module.scss';
import { blue } from '../../../../design/styles/colors';
import { useNavigate } from 'react-router-dom';
import { Doctor } from '../../types';
import { ALL_DOCTORS } from '../../../../router/routes';

export const DoctorCard = ({ doctor }: { doctor: Doctor }) => {
  const { guid, full_name, phone } = doctor;
  const navigate = useNavigate();

  return (
    <div className={styles.card}>
      <div>
        <FaRegUserCircle color={blue} size={50} />
      </div>

      <div className={styles.middle}>
        <p className={styles.username}>{full_name}</p>
        {phone}
        <br />
        <strong>ID:</strong> {guid}
      </div>

      <div>
        <ArrowButton
          size={20}
          color={blue}
          onClick={() => {
            navigate(`${ALL_DOCTORS}/${guid}`);
          }}
        />
      </div>
    </div>
  );
};
