import styles from './LabelValueRow.module.scss';

export const LabelValueRow = ({
  label,
  value,
  marginBottom = 0
}: {
  label: string;
  value: string;
  marginBottom?: number;
}) => (
  <div className={styles.row} style={{ marginBottom }}>
    <p
      style={{
        marginRight: 10
      }}>
      {label}:
    </p>
    <p>{value}</p>
  </div>
);
