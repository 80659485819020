import { AuthroizedFrame } from '../../../design/layout/authorizedFrame/AuthorizedFrame';
import { UserCard } from '../../../features/admin/components/UserCard/UserCard';
import { IconButton } from '../../../design/components/buttons/IconButton/IconButton';
import { FaUsers, FaTrashAlt } from 'react-icons/fa';
import { useSingleUser } from '../../../features/admin/useSingleUser';
import { useDeleteUser } from '../../../features/admin/useDeleteUser';
import { BackButton } from '../../../design/components/buttons/BackButton/BackButton';
import { ADMIN_DOCTOR, ADMIN_PATIENT, ALL_DOCTORS, ALL_PATIENTS } from '../../../router/routes';
import { useNavigate } from 'react-router-dom';

type Props = {
  user: 'doctor' | 'patient';
};

export const UserPage = ({ user }: Props) => {
  const { user: userData, loading } = useSingleUser(user);
  const { onPress } = useDeleteUser(user);
  const isDoctor = user === 'doctor';
  const usersButton = isDoctor ? 'Pacjenci' : 'Lekarze';

  const navigate = useNavigate();
  const onUsersClick = () => {
    if (isDoctor) {
      navigate(`${ADMIN_DOCTOR}/${userData.id}`);
    } else {
      navigate(`${ADMIN_PATIENT}/${userData.id}`);
    }
  };

  return (
    <AuthroizedFrame
      loading={loading}
      right={<BackButton destination={isDoctor ? ALL_DOCTORS : ALL_PATIENTS} />}>
      <UserCard
        name={userData.name}
        phone={userData.phone}
        email={userData.email}
        isDoctor={isDoctor}>
        <IconButton
          title={usersButton}
          icon={<FaUsers size={50} color={'white'} />}
          onClick={onUsersClick}
        />
        <IconButton
          title="Usuń użytkownika"
          backgroundColor="#f00"
          icon={<FaTrashAlt size={50} color={'white'} />}
          onClick={onPress}
        />
      </UserCard>
    </AuthroizedFrame>
  );
};
