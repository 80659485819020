import { AuthroizedFrame } from '../../../design/layout/authorizedFrame/AuthorizedFrame';
import { ChangeEvent, useState } from 'react';
import useAxios from 'axios-hooks';
import { ApiRoutes } from '../../../shared/types/api';
import { LOCAL_STORAGE_KEYS } from '../../../shared/types/storageKeys';
import { useToaster } from '../../../shared/hooks/useToaster';
import { PrimaryButton } from '../../../design/components/buttons/PrimaryButton/PrimaryButton';
import styles from './UploadDrugs.module.scss';

export const UploadDrugs = () => {
  const [file, setFile] = useState<File>();
  const { showErrorToast, showSuccessToast } = useToaster();
  const [loading, setLoading] = useState(false);

  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const [, uploadFile] = useAxios<unknown>(
    {
      ...ApiRoutes.AdminUploadDrugs,
      headers: {
        accessToken,
        'Content-Type': 'multipart/form-data'
      }
    },
    { manual: true }
  );

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      setFile(files[0]);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (!file) {
      alert('Please select a file.');
      return;
    }

    const formData = new FormData();
    formData.append('xls_file', file);

    try {
      await uploadFile({
        data: formData
      });

      showSuccessToast('Pomyślnie dodano leki !');
    } catch (error) {
      showErrorToast('Nie udało się dodać leków! Sprawdź format pliku.');
      console.error('Error uploading file:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setFile(droppedFile);
  };

  return (
    <AuthroizedFrame loading={loading}>
      <div className={styles.drop} onDragOver={handleDragOver} onDrop={handleDrop}>
        {!file && <p>Przeciągnij tu plik Excel typu .xlsx</p>}
        {file && (
          <div>
            <p>Selected file: {file.name}</p>
          </div>
        )}
      </div>
      {!file && (
        <div className={styles.separator}>
          <p>Lub wybierz plik:</p>
          <input type="file" accept=".xlsx" onChange={handleFileChange} />
        </div>
      )}
      <PrimaryButton onClick={handleSubmit} title="Upload" marginTop={20} />
      <div className={styles.legend}>
        <p>Format pliku .xlsx :</p>
        <table border={1}>
          <tr>
            <th>Nazwa Produktu Leczniczego</th>
            <th>Rodzaj preparatu</th>
            <th>Moc</th>
          </tr>
          <tr>
            <td>Edelan</td>
            <td>Ludzki</td>
            <td>25mg</td>
          </tr>
          <tr>
            <td>Nalgesin Forte</td>
            <td>Ludzki</td>
            <td>200mg + 1g</td>
          </tr>
          <tr>
            <td>Parvoerysin</td>
            <td>Weterynaryjny</td>
            <td>5kg</td>
          </tr>
        </table>
        <i>
          *zostaną dodane tylko preparaty <b>ludzkie</b>
        </i>
      </div>
    </AuthroizedFrame>
  );
};
