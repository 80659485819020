import styles from './SearchElement.module.scss';
import { FaPlus } from 'react-icons/fa';

type Props = {
  userName: string;
  onClick: () => void;
};

export const SearchElement = ({ userName, onClick }: Props) => {
  return (
    <div className={styles.searchUserItem} onClick={onClick}>
      <strong className={styles.itemName}>{userName}</strong>
      <div className={styles.add_icon}>
        <FaPlus size={25} color={'white'} />
      </div>
    </div>
  );
};
