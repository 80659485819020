import React, { useEffect } from 'react';
import * as d3 from 'd3';
import { ChartOption, chartOptionUnit, maxValues } from '../../../assets/types';
import Tooltip from './Tooltip/Tooltip';
import { createRoot } from 'react-dom/client';
import { useStore } from '../../../shared/hooks/useStore';

interface LineChartProps {
  dataArray: Array<{ date: Date; values: { [key in ChartOption]: number | undefined } }>;
}

const LineChart: React.FC<LineChartProps> = ({ dataArray }) => {
  const {
    rootStore: { chartStore }
  } = useStore();

  const leftOptions = chartStore.leftOptions;
  const rightOptions = chartStore.rightOptions;

  const chartOptions = chartStore.chartOptions;

  const linesCount = chartOptions.length;

  const tooltip = d3
    .select('body')
    .append('div')
    .attr('class', 'tooltip')
    .style('position', 'absolute')
    .style('visibility', 'hidden');

  function drawChart(dataArray: any) {
    const svgWidth = 900;
    const svgHeight = 580;
    const margin = { top: 30, right: 70, bottom: 30, left: 70 };
    const width = svgWidth - margin.left - margin.right;
    const height = svgHeight - margin.top - margin.bottom;

    const yLeftColors = ['red', 'green', 'blue'];
    const yRightColors = ['purple', 'orange'];
    let yColors: string[] = [];

    const svg = d3.select('.line-chart').attr('width', svgWidth).attr('height', svgHeight);

    const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

    const x = d3.scaleTime().rangeRound([0, width]);

    const yScales: d3.ScaleLinear<number, number, never>[] = [];

    const lines: Array<d3.Line<[number, number]>> = [];

    // Set domains
    x.domain(d3.extent(dataArray, (d: any) => d.date) as any as [Date, Date]);

    // Add yAxis
    chartOptions.forEach((chartOption) => {
      const yAxis = d3.scaleLinear().rangeRound([height, 0]);

      lines.push(
        d3
          .line()
          .x((d: any) => x(d.date))
          .y((d: any) => yAxis(d.values[chartOption]))
      );
      yAxis.domain([0, maxValues[chartOptionUnit[chartOption]]]);
      yScales.push(yAxis);
    });

    // Add x axis ticks
    g.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x).tickFormat((d: any) => d3.timeFormat('%m-%Y')(d)))
      .select('.domain')
      .remove();

    // Match lines with yAxis (left or right)
    for (let i = 0; i < linesCount; i++) {
      if (leftOptions.includes(chartOptions[i])) {
        g.append('g')
          .call(d3.axisLeft(yScales[i]))
          .append('text')
          .attr('fill', yLeftColors[0])
          .attr('font-size', '14px')
          .style('text-shadow', '-2px 0 white, 0 2px white, 2px 0 white, 0 -2px white')
          .attr('font-weight', 'bold')
          .attr('y', -15)
          .attr('x', -35)
          .attr('text-anchor', 'start')
          .text('(' + chartOptionUnit[leftOptions[0]] + ')');
        yColors.push(yLeftColors.shift()!);
      } else {
        g.append('g')
          .call(d3.axisRight(yScales[i]))
          .attr('transform', `translate(${width}, 0)`)
          .append('text')
          .attr('fill', yRightColors[0])
          .attr('font-size', '14px')
          .style('text-shadow', '-2px 0 white, 0 2px white, 2px 0 white, 0 -2px white')
          .attr('font-weight', 'bold')
          .attr('y', -15)
          .attr('x', 35)
          .attr('text-anchor', 'end')
          .text('(' + chartOptionUnit[rightOptions[0]] + ')');
        yColors.push(yRightColors.shift()!);
      }

      for (let i = 0; i < linesCount; i++) {
        const line = g
          .append('path')
          .datum(dataArray)
          .attr('fill', 'none')
          .attr('stroke', yColors[i])
          .attr('stroke-width', 1.5)
          .attr('d', lines[i]);
        addCircles(i);
      }
    }

    function addCircles(i: number) {
      const circleContainer = g.append('g').attr('class', 'circle-container');
      circleContainer
        .selectAll(`.point-${i}`)
        .data(dataArray)
        .enter()
        .append('circle')
        .attr('class', `point point-${i}`)
        .attr('r', 5)
        .attr('cx', (d: any) => x(d.date))
        .attr('cy', (d: any) => yScales[i](d.values[chartOptions[i]]))
        .attr('fill', yColors[i])
        .on('mouseover', (event) => {
          const [xValue, yValue] = d3.pointer(event);

          // Find the closest data point to the mouse pointer
          let closestDataPoint = null;
          let minDistance = Infinity;

          findClosestDataPoint();

          if (closestDataPoint) {
            const { date, value } = closestDataPoint;

            const chartOption = chartOptions[i];
            const unit = chartOptionUnit[chartOption];

            tooltip.style('visibility', 'visible');

            const tooltipElement = tooltip.node();
            if (tooltipElement) {
              const root = createRoot(tooltipElement);
              root.render(
                <Tooltip
                  chartOption={chartOption.toString()}
                  value={value}
                  unit={unit.toString()}
                  date={date}
                  visible={true}
                />
              );
            }

            tooltip
              .style('visibility', 'visible')
              .style('top', `${event.pageY - 145}px`)
              .style('left', `${event.pageX - 100}px`);
          }
          function findClosestDataPoint() {
            dataArray.forEach(
              (dataPoint: { date: Date; values: { [key in ChartOption]: number } }) => {
                const xData = x(dataPoint.date);
                const yData = yScales[i](dataPoint.values[chartOptions[i]]);

                const distance = Math.sqrt((xData - xValue) ** 2 + (yData - yValue) ** 2);

                if (distance < minDistance) {
                  closestDataPoint = {
                    date: dataPoint.date,
                    value: dataPoint.values[chartOptions[i]]
                  };
                  minDistance = distance;
                }
              }
            );
          }
        })

        .on('mouseout', () => {
          tooltip.style('visibility', 'hidden');
        });
    }
  }

  useEffect(() => {
    d3.select('.line-chart').selectAll('*').remove();
    drawChart(dataArray);
  }, [chartStore.leftOptions, chartStore.rightOptions]);

  return (
    <div>
      <svg className="line-chart" />
    </div>
  );
};

export default LineChart;
