import { PropsWithChildren } from 'react';
import { ShadowBox } from '../../../../design/components/shadowBox/ShadowBox';
import { LabelValueRow } from '../../../../design/components/labelValueRow/LabelValueRow';
import styles from './UserCard.module.scss';

type Props = PropsWithChildren<{
  name: string;
  phone: string;
  email: string;
  isDoctor: boolean;
}>;

export const UserCard = ({ name, phone, email, isDoctor, children }: Props) => {
  return (
    <div className={styles.box}>
      <ShadowBox minHeight="150px">
        <LabelValueRow
          label={isDoctor ? 'Imię i nazwisko' : 'Identyfikator'}
          value={name}
          marginBottom={18}
        />
        <LabelValueRow label={'Telefon'} value={phone} marginBottom={18} />
        <LabelValueRow label={'Email'} value={email} />
        {children && <div className={styles.row}>{children}</div>}
      </ShadowBox>
    </div>
  );
};
