import { ChangeEvent } from 'react';
import styles from './Switch.module.scss';

interface SwitchProps {
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: boolean;
  disabled: boolean;
}

const Switch = ({ label, onChange, value, disabled }: SwitchProps) => {
  return (
    <input
      className={styles.checkbox}
      onChange={onChange}
      checked={value}
      type="checkbox"
      name={label}
      disabled={disabled}
    />
  );
};

export default Switch;
