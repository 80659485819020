import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import styles from './LabelInput.module.scss';

type Props = {
  label: string;
  type: React.HTMLInputTypeAttribute;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  disabled?: boolean;
  unit?: string;
};

export const LabelInput = ({ label, type, onChange, value, disabled, unit }: Props) => {
  const lowerLabel = label.toLowerCase();
  return (
    <div className={styles.wrapper}>
      <label className={styles.label} htmlFor={lowerLabel}>
        {label}
      </label>
      <input
        className={styles.input}
        type={type}
        placeholder={label}
        name={lowerLabel}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      {unit ? <div className={styles.unit}>{unit}</div> : null}
    </div>
  );
};

// This component support react-hook-form
// You can use it easily and develop new functionalities with it

type FormInputProps<T extends FieldValues> = {
  label: string;
  type: React.HTMLInputTypeAttribute;
  register: UseFormRegister<T>;
  name: Path<T>;
  disabled?: boolean;
};

export function LabelFormInput<T extends FieldValues>({
  label,
  type,
  disabled,
  register,
  name
}: FormInputProps<T>) {
  return (
    <div className={styles.wrapper}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <input
        className={styles.input}
        type={type}
        placeholder={label}
        disabled={disabled}
        {...register(name)}
      />
    </div>
  );
}
