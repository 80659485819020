import { ShadowBox } from '../../design/components/shadowBox/ShadowBox';
import { CenteredPageFrame } from '../../design/layout/centeredPageFrame/CenteredPageFrame';
import { HeartLinkLogo } from '../../design/components/heartLinkLogo/HeartLinkLogo';
import { LabelFormInput } from '../../design/components/LabelInput/LabelInput';
import { PrimaryButton } from '../../design/components/buttons/PrimaryButton/PrimaryButton';
import { TertiaryButton } from '../../design/components/buttons/TertiaryButtom/TertiaryButton';
import styles from './Login.module.scss';
import { LoginService } from '../../features/login/Login.service';
import { PhoneLabelInputForm } from '../../design/components/LabelInput/PhoneLabelInput';

export const Login = () => {
  const { useLogin } = LoginService;
  const { register, onSubmit, onResetPassword, loginToTxt, switchToTxt, switchRole } = useLogin();

  return (
    <CenteredPageFrame>
      <HeartLinkLogo />
      <div className={styles.box}>
        <ShadowBox>
          <h1 className={styles.header}>{loginToTxt}</h1>
          <form className={styles.form}>
            <PhoneLabelInputForm label={'Numer telefonu'} register={register} name={'phone'} />
            <LabelFormInput
              label={'Hasło'}
              type={'password'}
              register={register}
              name={'password'}
            />
            <div className={styles.button_wrapper}>
              <PrimaryButton title={'Zaloguj'} marginTop={35} onClick={onSubmit} />
            </div>
            <div className={styles.tertiary_first}>
              <TertiaryButton title={'Zapomniałem hasła'} onClick={onResetPassword} />
            </div>
          </form>
        </ShadowBox>
        <div className={styles.tertiary_first}>
          <TertiaryButton title={switchToTxt} onClick={switchRole} />
        </div>
      </div>
    </CenteredPageFrame>
  );
};
