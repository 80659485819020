export const SESSION_STORAGE_KEYS = {
  TWO_FA_TOKEN: '2faToken',
  FORGOT_TOKEN: 'forgotToken',
  PHONE: 'phone'
};

export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: 'accessToken',
  ACCESS_TOKEN_EXPIRES_AT: 'accessTokenExpiresAt',
  ROLES: 'roles'
};
