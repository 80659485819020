import { LabelFormInput } from '../../../../design/components/LabelInput/LabelInput';
import { PhoneLabelInputForm } from '../../../../design/components/LabelInput/PhoneLabelInput';
import { PrimaryButton } from '../../../../design/components/buttons/PrimaryButton/PrimaryButton';
import { ShadowBox } from '../../../../design/components/shadowBox/ShadowBox';
import styles from './PatientForm.module.scss';
import { SwitchLabel } from '../../../../design/components/switchLabel/SwitchLabel';
import { PatientsService } from '../../Patients.service';

export const PatientForm = () => {
  const { useCreatePatient } = PatientsService;
  const { register, setValue, watch, handleSubmit } = useCreatePatient();

  return (
    <ShadowBox>
      <form className={styles.form}>
        <LabelFormInput
          label={'Numer identyfikacyjny'}
          type={'text'}
          register={register}
          name={'patient_id'}
        />
        <LabelFormInput
          label={'Numer seryjny pompy'}
          type={'text'}
          register={register}
          name={'pump_serial_num'}
        />
        <PhoneLabelInputForm label={'Numer telefonu'} register={register} name={'phone'} />
        <LabelFormInput
          label={'Data implantacji'}
          type={'date'}
          register={register}
          name={'implantation_date'}
        />
        <LabelFormInput label={'Adres email'} type={'email'} register={register} name={'email'} />
        <SwitchLabel
          label={'Koordynator'}
          checked={watch('as_coordinator')}
          onChange={(c) => setValue('as_coordinator', c)}
        />
        <div>
          <PrimaryButton title={'Dodaj nowego pacjenta'} marginTop={40} onClick={handleSubmit} />
        </div>
      </form>
    </ShadowBox>
  );
};
