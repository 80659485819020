import { createContext, useEffect, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LOGIN } from '../router/routes';
import { LOCAL_STORAGE_KEYS } from '../shared/types/storageKeys';
import useAxios from 'axios-hooks';
import { ApiRoutes } from '../shared/types/api';
import { useToaster } from '../shared/hooks/useToaster';
import dayjs from 'dayjs';

type SettingsContextType = {
  loading: boolean;
};

export const SettingsContext = createContext<SettingsContextType>({
  loading: false
});

const SettingsProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const { pathname } = location;

  const navigate = useNavigate();
  const { showInfoToast } = useToaster();

  const [{ loading }, refreshToken] = useAxios<{ accessToken: string }, undefined>(
    ApiRoutes.AuthDoctorRefresh,
    { manual: true }
  );

  const logout = () => {
    showInfoToast('Twoja sesja wygasła. Zaloguj się ponownie');
    navigate(LOGIN);
    localStorage.clear();
    sessionStorage.clear();
  };

  useEffect(() => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    if (pathname.startsWith('/auth') && !accessToken) {
      navigate(LOGIN);
    }
  }, [pathname]);

  const roleRouteVerification = () => {
    // TODO: BETTER IMPLEMENTATION FOR ROLE VERIFICATION
    // if (pathname.startsWith('/admin') && !isAdmin) {
    //   logout();
    // }
    // if (pathname.startsWith('/auth') && !isDoctor) {
    //   logout();
    // }
  };

  const refreshTokenHandler = () => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    const expirtyDate = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_EXPIRES_AT);

    if (!accessToken || !expirtyDate) return;

    const now = dayjs();
    const expiresAt = dayjs(expirtyDate);

    if (now.isBefore(expiresAt)) {
      return;
    }

    refreshToken({
      headers: {
        accessToken
      }
    })
      .then((res) => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, res.data.accessToken);
      })
      .catch(logout);
  };

  useLayoutEffect(() => {
    refreshTokenHandler();
    roleRouteVerification();
  }, [location]);

  return <SettingsContext.Provider value={{ loading }}>{children}</SettingsContext.Provider>;
};

export default SettingsProvider;
