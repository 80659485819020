import { ShadowBox } from '../../design/components/shadowBox/ShadowBox';
import { CenteredPageFrame } from '../../design/layout/centeredPageFrame/CenteredPageFrame';
import { HeartLinkLogo } from '../../design/components/heartLinkLogo/HeartLinkLogo';
import { LabelFormInput, LabelInput } from '../../design/components/LabelInput/LabelInput';
import { PrimaryButton } from '../../design/components/buttons/PrimaryButton/PrimaryButton';
import styles from './DeleteAccount.module.scss';
import {
  PhoneLabelInput,
  PhoneLabelInputForm
} from '../../design/components/LabelInput/PhoneLabelInput';
import { Controller, FieldErrors, useForm } from 'react-hook-form';
import useAxios from 'axios-hooks';
import { useToaster } from '../../shared/hooks/useToaster';
import { ApiRoutes } from '../../shared/types/api';
import { useEffect } from 'react';

type DeleteAccountForm = {
  phone_number: string;
  email: string;
  request_type: 'delete_account';
};

export const DeleteAccount = () => {
  const { showErrorToast, showSuccessToast } = useToaster();
  const { handleSubmit, control, register } = useForm<DeleteAccountForm>({
    defaultValues: {
      phone_number: '',
      email: ''
    }
  });

  // hotfix
  const [, sendRequest] = useAxios<DeleteAccountForm, any>(
    {
      method: ApiRoutes.DeleteAccountRequest.method,
      url: ApiRoutes.DeleteAccountRequest.url
    },
    {
      manual: true
    }
  );

  const onSubmit = async (data: DeleteAccountForm) => {
    const payload = {
      phone_number: `+48${data.phone_number}`,
      email: data.email,
      request_type: 'delete_account'
    };

    try {
      const res = await sendRequest({
        data: payload
      });

      showSuccessToast('Pomyślnie wysłano zapytanie o usunięcie konta !');
    } catch (error) {
      showErrorToast(
        'Nie udało się wysłać zapytania o usunięcie konta ! Sprawdź poprawność danych i połączenie internetowe.'
      );
      console.error(error);
    }
  };

  const onErrors = (errors: FieldErrors<DeleteAccountForm>) => {
    const msg = Object.values(errors)[0].message;
    if (msg) {
      showErrorToast(msg);
      console.error(errors);
    }
  };

  const click = handleSubmit(onSubmit, onErrors);

  return (
    <CenteredPageFrame>
      <HeartLinkLogo />
      <div className={styles.box}>
        <ShadowBox>
          <h1 className={styles.header}>Prośba o usunięcie danych</h1>
          <form className={styles.form}>
            <PhoneLabelInputForm
              label={'Numer telefonu'}
              register={register}
              name={'phone_number'}
            />
            <Controller
              name={'email'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <LabelInput type={'email'} label={'Email'} onChange={onChange} value={value} />
              )}
            />
            <div className={styles.button_wrapper}></div>
          </form>

          <PrimaryButton title={'Usuń moje konto'} marginTop={35} onClick={click} />
        </ShadowBox>
      </div>
    </CenteredPageFrame>
  );
};
