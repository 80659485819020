import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import styles from './VerificationInput.module.scss';

type VerificationInputFormProps<T extends FieldValues> = {
  register: UseFormRegister<T>;
  name: Path<T>;
};

export function VerificationInput<T extends FieldValues>({
  register,
  name
}: VerificationInputFormProps<T>) {
  return (
    <div className={styles.wrapper}>
      <label className={styles.label} htmlFor="Kod">
        Kod weryfikacyjny
      </label>
      <input
        className={styles.input}
        type="text"
        placeholder="Kod"
        maxLength={6}
        {...register(name)}
      />
    </div>
  );
}
