import { AuthroizedFrame } from '../design/layout/authorizedFrame/AuthorizedFrame';
import { ChangePassword } from '../features/settings/components/ChangePasswordCard/ChangePassword';

export const Settings = () => {
  return (
    <AuthroizedFrame>
      <div>
        <ChangePassword />
      </div>
    </AuthroizedFrame>
  );
};
