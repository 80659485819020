import { Controller, useForm } from 'react-hook-form';
import { ShadowBox } from '../../../../design/components/shadowBox/ShadowBox';
import { LabelInput } from '../../../../design/components/LabelInput/LabelInput';
import { PrimaryButton } from '../../../../design/components/buttons/PrimaryButton/PrimaryButton';
import styles from './ChangePassword.module.scss';
import { useChangePassword } from '../../hooks/useChangePassword';

export const ChangePassword = () => {
  const { control, onSubmitPress } = useChangePassword();
  return (
    <div className={styles.container}>
      <ShadowBox>
        <h1>Zmiana hasła</h1>
        <Controller
          name={'oldPassword'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <LabelInput label={'Stare hasło'} onChange={onChange} value={value} type={'password'} />
          )}
        />
        <Controller
          name={'newPassword'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <LabelInput label={'Nowe hasło'} onChange={onChange} value={value} type={'password'} />
          )}
        />
        <Controller
          name={'repeatNewPassword'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <LabelInput
              label={'Powtórz nowe hasło'}
              onChange={onChange}
              value={value}
              type={'password'}
            />
          )}
        />
        <div className={styles.buttonWrapper}>
          <PrimaryButton title={'Zmień hasło'} onClick={onSubmitPress} />
        </div>
      </ShadowBox>
    </div>
  );
};
