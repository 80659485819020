import { ChangeEvent } from 'react';
import Switch from '../switch/Switch';
import styles from './LabelCheckbox.module.scss';

type Props = {
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: boolean;
  disabled?: boolean;
};

export const LabelCheckbox = ({ label, onChange, value, disabled = false }: Props) => {
  const lowerLabel = label.toLowerCase();
  return (
    <div className={styles.container}>
      <Switch label={lowerLabel} onChange={onChange} value={value} disabled={disabled} />
      <label className={styles.label} htmlFor={lowerLabel}>
        {label}
      </label>
    </div>
  );
};
