import { ShadowBox } from '../../design/components/shadowBox/ShadowBox';
import { CenteredPageFrame } from '../../design/layout/centeredPageFrame/CenteredPageFrame';
import { HeartLinkLogo } from '../../design/components/heartLinkLogo/HeartLinkLogo';
import { VerificationInput } from '../../design/components/VerificationInput/VerificationInput';
import { PrimaryButton } from '../../design/components/buttons/PrimaryButton/PrimaryButton';
import { CountdownButton } from '../../design/components/buttons/CountdownButton/CountdownButton';
import styles from './Verification.module.scss';
import { LoginService } from '../../features/login/Login.service';

export const Verification = () => {
  const { useVerify } = LoginService;
  const { register, onSubmit, onResentOTP } = useVerify();

  return (
    <CenteredPageFrame>
      <HeartLinkLogo />
      <div className={styles.box}>
        <ShadowBox>
          <h1 className={styles.text}>Na twój numer telefonu został wysłany kod weryfikacyjny</h1>
          <VerificationInput register={register} name={'hotp_code'} />
          <div className={styles.button_container}>
            <div className={styles.countdown_wrapper}>
              <CountdownButton title={'Wyślij ponownie'} seconds={30} onClick={onResentOTP} />
            </div>
            <div className={styles.button_wrapper}>
              <PrimaryButton title={'Potwierdź'} marginTop={40} onClick={onSubmit} />
            </div>
          </div>
        </ShadowBox>
      </div>
    </CenteredPageFrame>
  );
};
