import { useForm } from 'react-hook-form';
import { ChartOption, ChartParams, ChartUnit } from '../../assets/types';
import { useStore } from '../../shared/hooks/useStore';

const chartOptionUnit: { [key in ChartOption]: ChartUnit } = {
  [ChartOption.ROTARY_SPEED]: ChartUnit.OBR_MIN,
  [ChartOption.FLOW]: ChartUnit.L_MIN,
  [ChartOption.WP]: ChartUnit.W,
  [ChartOption.POWER]: ChartUnit.W,
  [ChartOption.INR]: ChartUnit.INR,
  [ChartOption.BLOOD_THINNING_DRUG_DOSE]: ChartUnit.MG,
  [ChartOption.BODY_TEMPERATURE]: ChartUnit.C,
  [ChartOption.BODY_MASS]: ChartUnit.KG,
  [ChartOption.SYSTOLIC_BLOOD_PRESSURE]: ChartUnit.MMGH,
  [ChartOption.DIASTOLIC_BLOOD_PRESSURE]: ChartUnit.MMGH,
  [ChartOption.AVERAGE_BLOOD_PRESSURE]: ChartUnit.MMGH,
  [ChartOption.PULSE]: ChartUnit.UD_MIN,
  [ChartOption.FLUIDS_TAKEN]: ChartUnit.L,
  [ChartOption.FLUIDS_EXCRETED]: ChartUnit.L,
  [ChartOption.COMFORT]: ChartUnit.COMFORT,
  [ChartOption.WALK_DISTANCE]: ChartUnit.KM
};

export const useMeasurement = () => {
  const {
    rootStore: { chartStore }
  } = useStore();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<ChartParams>({
    defaultValues: {
      rotary_speed: chartStore.chartOptions.includes(ChartOption.ROTARY_SPEED),
      flow: chartStore.chartOptions.includes(ChartOption.FLOW),
      wp: chartStore.chartOptions.includes(ChartOption.WP),
      power: chartStore.chartOptions.includes(ChartOption.POWER),
      inr: chartStore.chartOptions.includes(ChartOption.INR),
      blood_thinning_drug_dose: chartStore.chartOptions.includes(
        ChartOption.BLOOD_THINNING_DRUG_DOSE
      ),
      body_temperature: chartStore.chartOptions.includes(ChartOption.BODY_TEMPERATURE),
      body_mass: chartStore.chartOptions.includes(ChartOption.BODY_MASS),
      systolic_blood_pressure: chartStore.chartOptions.includes(
        ChartOption.SYSTOLIC_BLOOD_PRESSURE
      ),
      diastolic_blood_pressure: chartStore.chartOptions.includes(
        ChartOption.DIASTOLIC_BLOOD_PRESSURE
      ),
      average_blood_pressure: chartStore.chartOptions.includes(ChartOption.AVERAGE_BLOOD_PRESSURE),
      pulse: chartStore.chartOptions.includes(ChartOption.PULSE),
      fluids_taken: chartStore.chartOptions.includes(ChartOption.FLUIDS_TAKEN),
      fluids_excreted: chartStore.chartOptions.includes(ChartOption.FLUIDS_EXCRETED),
      comfort: chartStore.chartOptions.includes(ChartOption.COMFORT),
      walk_distance: chartStore.chartOptions.includes(ChartOption.WALK_DISTANCE)
    }
  });

  const onCheck = () => {
    const chartOptions = chartStore.chartOptions;
    const optionsCount = chartOptions.length;
    const leftArray: ChartOption[] = [];
    const rightArray: ChartOption[] = [];

    if (optionsCount === 0) {
      makeAllEditable();
    } else if (optionsCount === 1) {
      makeAllEditable();
      leftArray.push(chartOptions[0]);
    } else {
      if (optionsCount > 0) {
        leftArray.push(chartOptions[0]);
      }

      if (optionsCount == 3) {
        makeOthersDisabled(chartOptions);
      }

      const firstUnit = chartOptionUnit[chartOptions[0]];
      const differentUnits: ChartUnit[] = [];
      differentUnits.push(firstUnit);

      if (firstUnit != chartOptionUnit[chartOptions[1]]) {
        differentUnits.push(chartOptionUnit[chartOptions[1]]);
        rightArray.push(chartOptions[1]);
      } else {
        leftArray.push(chartOptions[1]);
      }

      if (optionsCount == 3) {
        if (firstUnit == chartOptionUnit[chartOptions[2]]) {
          leftArray.push(chartOptions[2]);
        } else {
          rightArray.push(chartOptions[2]);
        }
      }

      if (optionsCount == 2 && differentUnits.length == 1) {
        makeAllEditable();
        chartStore.leftOptions = leftArray;
        chartStore.rightOptions = rightArray;
        return;
      }

      if (differentUnits.length == 2 && optionsCount == 2) {
        const unitEnabledOptions = chartOptions.concat(getSameUnitOptions(differentUnits));
        makeOthersDisabled(unitEnabledOptions);
      }
    }

    chartStore.leftOptions = leftArray;
    chartStore.rightOptions = rightArray;
  };

  function makeOthersDisabled(chartOptions: ChartOption[]) {
    chartStore.checkboxDisabledList = Object.values(ChartOption).filter(
      (option) => !chartOptions.includes(option)
    );
  }

  function makeAllEditable() {
    chartStore.checkboxDisabledList = [];
  }

  function getSameUnitOptions(chartUnits: ChartUnit[]): ChartOption[] {
    let chartOptions: ChartOption[] = [];

    for (let i = 0; i < chartUnits.length; i++) {
      const optionsToAdd: ChartOption[] = [];

      switch (chartUnits[i]) {
        case ChartUnit.W: {
          optionsToAdd.push(ChartOption.WP, ChartOption.POWER);
          break;
        }
        case ChartUnit.MMGH: {
          optionsToAdd.push(
            ChartOption.SYSTOLIC_BLOOD_PRESSURE,
            ChartOption.DIASTOLIC_BLOOD_PRESSURE,
            ChartOption.AVERAGE_BLOOD_PRESSURE
          );
          break;
        }
        case ChartUnit.L: {
          optionsToAdd.push(ChartOption.FLUIDS_TAKEN, ChartOption.FLUIDS_EXCRETED);
          break;
        }
      }

      optionsToAdd.forEach((option) => {
        if (!chartOptions.includes(option)) {
          chartOptions.push(option);
        }
      });
    }

    return chartOptions;
  }

  return { control, onPressCheck: handleSubmit(onCheck), errors, isValid };
};
