export const ApiRoutes = {
  AuthDoctorToken: {
    url: '/auth/doctor/token',
    method: 'POST'
  },
  AuthDoctor2FAToken: {
    url: '/auth/doctor/2fa/token',
    method: 'POST'
  },
  AuthDoctorRefresh: {
    url: '/auth/doctor/refresh',
    method: 'POST'
  },
  AuthDoctorResentOTP: {
    url: '/auth/doctor/2fa/refresh',
    method: 'POST'
  },
  AuthDoctorChangePassword: {
    url: '/auth/doctor/change_password',
    method: 'PUT'
  },
  AuthAdminToken: {
    url: '/auth/admin/token',
    method: 'POST'
  },
  AuthAdmin2FAToken: {
    url: '/auth/admin/2fa/token',
    method: 'POST'
  },
  AuthAdminRefresh: {
    url: '/auth/admin/refresh',
    method: 'POST'
  },
  AuthAdminResentOTP: {
    url: '/auth/admin/2fa/refresh',
    method: 'POST'
  },
  AuthAdminChangePassword: {
    url: '/auth/admin/change_password',
    method: 'PUT'
  },
  AdminPatients: {
    url: '/admin/patients',
    method: 'GET'
  },
  AdminDoctors: {
    url: '/admin/doctors',
    method: 'GET'
  },
  AdminPatient: {
    url: (patientId: string) => `/admin/patients/${patientId}`,
    method: 'GET'
  },
  AdminDoctor: {
    url: (doctorId: string) => `/admin/doctors/${doctorId}`,
    method: 'GET'
  },
  AdminPatientDelete: {
    url: (patientId: string) => `/admin/patients/${patientId}`,
    method: 'DELETE'
  },
  AdminDoctorDelete: {
    url: (doctorId: string) => `/admin/doctors/${doctorId}`,
    method: 'DELETE'
  },
  AdminPatientCreate: {
    url: '/admin/patients',
    method: 'POST'
  },
  AdminDoctorCreate: {
    url: '/admin/doctors',
    method: 'POST'
  },
  AdminDoctorsPatients: {
    url: (doctorId: string) => `/admin/doctors/${doctorId}/patients`,
    method: 'GET'
  },
  AdminPatientsDoctors: {
    url: (patientId: string) => `/admin/patients/${patientId}/doctors`,
    method: 'GET'
  },
  AdminSearchDoctors: {
    url: (searchTerm: string) => `/admin/doctors/name/${searchTerm}`,
    method: 'GET'
  },
  AdminSearchPatients: {
    url: (searchTerm: string) => `/admin/patients/name/${searchTerm}`,
    method: 'GET'
  },
  AdminAddRelation: {
    url: '/admin/relations',
    method: 'POST'
  },
  AdminDeleteRelation: {
    url: '/admin/relations',
    method: 'DELETE'
  },
  AdminUpgradeRelation: {
    url: '/admin/relations/upgrade',
    method: 'POST'
  },
  AdminDowngradeRelation: {
    url: '/admin/relations/downgrade',
    method: 'POST'
  },
  AdminUploadDrugs: {
    url: '/admin/drugs',
    method: 'POST'
  },
  ResetPassword: {
    Init: {
      url: '/auth/doctor/initiate_forgotten_password_change',
      method: 'POST'
    },
    Verify: {
      url: '/auth/doctor/2fa/forgot',
      method: 'POST'
    },
    Reset: {
      url: '/auth/doctor/forgot_password',
      method: 'POST'
    }
  },
  ResetPasswordAdmin: {
    Init: {
      url: '/auth/admin/initiate_forgotten_password_change',
      method: 'POST'
    },
    Verify: {
      url: '/auth/admin/2fa/forgot',
      method: 'POST'
    },
    Reset: {
      url: '/auth/admin/forgot_password',
      method: 'POST'
    }
  },
  Patients: {
    Get: {
      url: '/doctor/patients',
      method: 'GET'
    },
    Create: {
      url: '/doctor/patient',
      method: 'POST'
    },
    GetOne: {
      url: '/doctor/patient',
      method: 'GET'
    }
  },
  Measurements: {
    Get: {
      url: '/measurements',
      method: 'GET'
    }
  },
  Prescriptions: {
    GetAll: {
      url: (patientId: string) => `/doctor/patients/${patientId}/prescriptions`,
      method: 'GET'
    },
    Delete: {
      url: (patientId: string, prescriptionId: string) =>
        `/doctor/patients/${patientId}/prescriptions/${prescriptionId}`,
      method: 'DELETE'
    },
    Get: {
      url: (patientId: string, prescriptionId: string) =>
        `/doctor/patients/${patientId}/prescriptions/${prescriptionId}`,
      method: 'GET'
    },
    Create: {
      url: (patientId: string) => `/doctor/patients/${patientId}/prescriptions`,
      method: 'POST'
    }
  },
  Images: {
    Get: {
      url: '/images',
      method: 'GET'
    }
  },
  Drugs: {
    Add: {
      url: '/doctor/drugs',
      method: 'POST'
    },
    Search: {
      url: (searchTerm: string) => `/doctor/drugs/${searchTerm}`,
      method: 'GET'
    },
    Delete: {
      url: (drugId: string) => `/doctor/drugs/${drugId}`,
      method: 'DELETE'
    }
  },
  TakenDrugs: {
    url: (patientId: string) => `/doctor/patients/${patientId}/drugs_taken/dates`,
    method: 'GET'
  },
  DeleteAccountRequest: {
    url: '/requests/delete-account',
    method: 'POST'
  }
};
