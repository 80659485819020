import { makeAutoObservable } from 'mobx';
import { IRootStore } from './RootStore';

export class PatientStore {
  rootStore: IRootStore;
  currentPatientId: string = '';

  constructor(rootStore: IRootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }
}
