import { useState } from 'react';
import { BackButton } from '../../design/components/buttons/BackButton/BackButton';
import { PrimaryButton } from '../../design/components/buttons/PrimaryButton/PrimaryButton';
import { AuthroizedFrame } from '../../design/layout/authorizedFrame/AuthorizedFrame';
import { PATIENT, PILLS } from '../../router/routes';
import styles from './NewTreatment.module.scss';
import { LabelCheckbox } from '../../design/components/LabelCheckbox/LabelCheckbox';
import { LabelInput } from '../../design/components/LabelInput/LabelInput';
import { TextViewLabelInput } from '../../design/components/LabelInput/TextViewLabelInput';
import { useNewTreatment } from './NewTreatment.hook';
import { Controller } from 'react-hook-form';
import { IntervalButton, Parity } from '../../assets/types';
import { blue } from '../../design/styles/colors';
import { useParams } from 'react-router-dom';
import DrugService from '../../features/drugManagement/Drug.service';
import { useStore } from '../../shared/hooks/useStore';

export const NewTreatment = () => {
  const {
    rootStore: { patientStore }
  } = useStore();

  const patientName = patientStore.currentPatientId;
  const { patientId } = useParams();
  const [selectedId, setSelectedId] = useState('');

  const [intervalButton, setIntervalButton] = useState(IntervalButton.Daily);

  const { control, onSubmit, errors, isValid } = useNewTreatment(patientId ?? '');
  const { useSearchDrug } = DrugService;
  const { control: searchControl, onSubmit: searchDrugs, result } = useSearchDrug();

  const handleIntervalChange = (
    newValue: IntervalButton,
    onChange: (value: IntervalButton) => void
  ) => {
    setIntervalButton(newValue);
    onChange(newValue);
  };

  const handleSelectChange = (newValue: string, onChange: (value: string) => void) => {
    setSelectedId(newValue);
    onChange(newValue);
  };

  let submitDisabled = !isValid;

  return (
    <AuthroizedFrame right={<BackButton destination={`${PATIENT}/${patientId}${PILLS}`} />}>
      <h1 className={styles.header} style={{ color: blue }}>
        Tworzenie terapii dla pacjenta {patientName}
      </h1>
      <div className={styles.section}>
        <h2 className={styles.label}>Interwał przyjmowania leków</h2>
        <Controller
          name={'intervalButton'}
          control={control}
          render={({ field: { onChange } }) => (
            <div className={styles.intervalButtons}>
              <div className={styles.intervalButton}>
                <PrimaryButton
                  title={'Codziennie'}
                  onClick={() => handleIntervalChange(IntervalButton.Daily, onChange)}
                  unselected={intervalButton !== IntervalButton.Daily}
                />
              </div>
              <div className={styles.intervalButton}>
                <PrimaryButton
                  title={'Wybrane dni'}
                  onClick={() => handleIntervalChange(IntervalButton.ChosenDays, onChange)}
                  unselected={intervalButton !== IntervalButton.ChosenDays}
                />
              </div>
              <div className={styles.intervalButton}>
                <PrimaryButton
                  title={'Parzyste / nieparzyste'}
                  onClick={() => handleIntervalChange(IntervalButton.EvenOdd, onChange)}
                  unselected={intervalButton !== IntervalButton.EvenOdd}
                />
              </div>
            </div>
          )}
        />
        <div className={styles.days_container}>
          {intervalButton === IntervalButton.ChosenDays && (
            <div className={styles.radio_buttons}>
              <Controller
                name={'monday'}
                control={control}
                rules={{}}
                render={({ field: { onChange, value } }) => (
                  <LabelCheckbox label={'Poniedziałek'} onChange={onChange} value={value} />
                )}
              />
              <Controller
                name={'tuesday'}
                control={control}
                rules={{}}
                render={({ field: { onChange, value } }) => (
                  <LabelCheckbox label={'Wtorek'} onChange={onChange} value={value} />
                )}
              />
              <Controller
                name={'wednesday'}
                control={control}
                rules={{}}
                render={({ field: { onChange, value } }) => (
                  <LabelCheckbox label={'Środa'} onChange={onChange} value={value} />
                )}
              />
              <Controller
                name={'thursday'}
                control={control}
                rules={{}}
                render={({ field: { onChange, value } }) => (
                  <LabelCheckbox label={'Czwartek'} onChange={onChange} value={value} />
                )}
              />
              <Controller
                name={'friday'}
                control={control}
                rules={{}}
                render={({ field: { onChange, value } }) => (
                  <LabelCheckbox label={'Piątek'} onChange={onChange} value={value} />
                )}
              />
              <Controller
                name={'saturday'}
                control={control}
                rules={{}}
                render={({ field: { onChange, value } }) => (
                  <LabelCheckbox label={'Sobota'} onChange={onChange} value={value} />
                )}
              />
              <Controller
                name={'sunday'}
                control={control}
                rules={{}}
                render={({ field: { onChange, value } }) => (
                  <LabelCheckbox label={'Niedziela'} onChange={onChange} value={value} />
                )}
              />
            </div>
          )}
        </div>
        <div className={styles.parity_container}>
          {intervalButton === IntervalButton.EvenOdd && (
            <div className={styles.radio_buttons}>
              <div>
                <Controller
                  name="evenOdd"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <input
                      type="radio"
                      name="even_odd"
                      value={Parity.Even}
                      onChange={onChange}
                      checked={value === Parity.Even}
                    />
                  )}
                />
                <label htmlFor="even">Parzyste</label>
              </div>
              <div>
                <Controller
                  name="evenOdd"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <input
                      type="radio"
                      name="even_odd"
                      value={Parity.Odd}
                      onChange={onChange}
                      checked={value === Parity.Odd}
                    />
                  )}
                />
                <label htmlFor="odd">Nieparzyste</label>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.searchRow}>
          <Controller
            name={'searchTerm'}
            control={searchControl}
            render={({ field: { onChange, value } }) => (
              <LabelInput label={'Nazwa leku'} onChange={onChange} value={value} type={'text'} />
            )}
          />
          <PrimaryButton title={'Szukaj'} onClick={searchDrugs} />
        </div>
        <div className={styles.searchResult}>
          {result?.map((drug) => {
            return (
              <div key={drug.id} className={styles.drugItem}>
                {drug.power?.map((power) => {
                  const itemId = `${drug.id}-${power}`;
                  const isSelected = itemId === selectedId;
                  const itemStyles = isSelected ? styles.powerItemSelected : styles.powerItem;

                  return (
                    <Controller
                      key={itemId}
                      name={'selectedDrugId'}
                      control={control}
                      render={({ field: { onChange } }) => (
                        <div
                          key={itemId}
                          className={itemStyles}
                          onClick={() => handleSelectChange(`${drug.id}-${power}`, onChange)}>
                          <strong>{drug.name}</strong> - {power}
                        </div>
                      )}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.section}>
        <h2 className={styles.dosage_header}>Liczba dawek:</h2>
        <div className={styles.dosage}>
          <Controller
            name="morningDosage"
            control={control}
            rules={{ min: 0 }}
            render={({ field: { onChange, value } }) => (
              <LabelInput
                label={'Rano'}
                type={'number'}
                onChange={onChange}
                value={String(value)}></LabelInput>
            )}
          />
          <span>-</span>
          <Controller
            name="noonDosage"
            control={control}
            rules={{ min: 0 }}
            render={({ field: { onChange, value } }) => (
              <LabelInput
                label={'Południe'}
                type={'number'}
                onChange={onChange}
                value={String(value)}></LabelInput>
            )}
          />
          <span>-</span>
          <Controller
            name="eveningDosage"
            control={control}
            rules={{ min: 0 }}
            render={({ field: { onChange, value } }) => (
              <LabelInput
                label={'Wieczór'}
                type={'number'}
                onChange={onChange}
                value={String(value)}></LabelInput>
            )}
          />
        </div>
        {(errors.morningDosage || errors.noonDosage || errors.eveningDosage) && (
          <span className={styles.errors}>Wartości muszą być większe od 0</span>
        )}
      </div>
      <div className={styles.section}>
        <div className={styles.full_wrapper}>
          <Controller
            name="treatmentComments"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TextViewLabelInput
                label={'Komentarz do terapii'}
                onChange={onChange}
                value={value}
                maxLength={250}
              />
            )}
          />
          {errors.treatmentComments && errors.treatmentComments.type === 'required' && (
            <span className={styles.errors}>To pole jest wymagane</span>
          )}
        </div>
      </div>
      <div className={styles.full_wrapper}>
        <PrimaryButton
          title={'Uruchom terapię'}
          onClick={onSubmit}
          unselected={submitDisabled}
          disabled={submitDisabled}
        />
      </div>
    </AuthroizedFrame>
  );
};
